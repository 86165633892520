/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import SITE_CONFIG from '../SiteController/SiteController';
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Loader from '../resources/loader/loader.gif';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../resources/css/search_engine.css';
import '../resources/css/bootstrap.min.css';
import '../resources/css/jquery-ui.css';
import '../resources/css/fontawesome.all.min.css';
import '../resources/images/favicon.png';
import '../resources/font/bootstrap-icons.css';
import Footer from '../components/Footer/footer'
//import '../resources/jc/owl.carousel.min.css';
//import '../resources/js/jquery.meanmenu.js';
//import '../resources/js/custom.js';
//import '../resources/js/jquery.js';
import '../resources/jc/wow.min.js';
//import '../resources/jc/owl.carousel.min.js';
//import '../resources/jc/main.js';
import swap from '../resources/images/icons/swap-icon.svg';
import maps from '../resources/images/icons/maps.png';
import sale from '../resources/images/icons/sale.png';
import customerService from '../resources/images/icons/customer-service.png';

import Searchbar from '../Shared/Searchbar';
import MetaTitle from './metaTitle.jsx';


export default function Home() {
    const { apiIP, siteName, siteID } = SITE_CONFIG;
    const [isLoading, setIsLoading] = useState(false);
    const [airlines, setAirlines] = useState({
        pageType: "Airline",
        pageValue: "",
        siteId: siteID,
        url: ""
      });

      const [deals, setDeals] = useState({
        froCity:"",
        toCity:"",
        airline: airlines.pageValue,
        cabinClass:"Y",
        tripType:"2",
        page:"",
        currency:"GBP",
        siteId: siteID,
        departDate:"",
        countryName:"UK",
        countryCode:"UK",
        cityName:""
    });
      const [responseData, setResponseData] = useState(null);
      const navigate = useNavigate();
      useEffect(() => {
        // Use a useEffect to make the API call whenever airlines state changes
        if (airlines.pageValue && airlines.url) {
          handleAirlineClicked();
        }
      }, [airlines]);
 
const handleAirlineClicked = async () => {

    const navigationPath = airlines.pageValue.length === 2
      ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
      : `/flights-to/${airlines.url}-${airlines.pageValue}`;

    const newTab = window.open(navigationPath, '_blank');
    if (newTab) {
        //console.log('Received data in new tab:');
      newTab.onload = function() {
        newTab.postMessage(window.location.origin);
      };
    } else {
      //console.error('Failed to open new tab. Please check your browser settings or extensions.');
    }
  };
      
      
    
      const handleCardClick = (pageType, pageValue, url) => {
        // Set the new values for airlines based on the card clicked
        setAirlines({
          ...airlines,
          pageType,
          pageValue,
          url,
        });
      };

      const openLink = (url) => {
		window.open(url, '_blank');
	  };
  return (
    <div className='HomeBody'>
        {isLoading && (
        <div className="loader-overlay">
          <img src={Loader} alt="Loader" />
        </div>
      )}
      {/* <Helmet>
        <title>Cheap Flights | Airline Tickets | Airfare & Discount Deals | Rawfares</title>
        <meta name="description" content="Find Cheap Flights, book discount airfares and air tickets, direct & last-minute deals available on exclusive discount offer." />
        {/* Other meta tags */}
        <link rel="canonical" href={`${apiIP}/`} />
        {/* Additional meta tags for homepage 
      </Helmet> */}
       <MetaTitle pageTitle="Great Flight & Hotel Offers | RawFares " pageDescription="RawFares is your go-to for excellent flight deals. Benefit from our Cheapest Prices, 24/7 support, and bespoke travel options." url={`${apiIP}/`} />
    < Searchbar backgroundImage="https://imgfolders.com/rawfares/rawfaresUS/search_banner/1600.webp" />

        {/* <!-- mobile Search popup Fly From Start here --> */}
        {/* <div className="mobile_search_popup" id="flyfrom" style={{display:"none"}}>
            <a href="javascript" className="searchwindowclose"><i className="bi bi-x"></i></a>
            <div className="popup_head blockshadow"><span className="icons bi bi-geo-alt-fill"></span> Select Loaction </div>


            <div className="inner_block">
                <div className="destination_field">
                    <span className="line"></span>
                    <div className="input_block from_input relative">
                        <input type="text" placeholder="From" id="fromCityMob" className="inputField mobile_input"/>
                        <span className="label_text change_label">From</span>

                        {/* </input><!--<div className="error_text">Please select an origin</div>--> 
                    </div>
                    <div className="row_margin relative">
                        <a href="javascript" className="swap_icon">
                            <img src={swap} alt="" />
                        </a>
                        <div className="input_block to_input">
                            <input type="text" placeholder="To" id="toCityMob" className="inputField mobile_input"/>
                            <span className="label_text change_label">To</span>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        {/* <!--mobile Search popup Start here -->
        <!-- mobile Search popup Fly To Start here --> 
        <div className="mobile_search_popup" id="flyto" style={{display:"none"}}>
            <a href="javascript;" className="searchwindowclose"><i className="bi bi-x"></i></a>
            <div className="input_block">
                <div className="blockshadow from_inputmob">
                    <input type="text" placeholder="To" id="toCityMob" className="inputField"/>
                    {/* <!--<span className="label_text change_label">Flying from</span>--> 
                    <span className="icons bi bi-geo-alt-fill"></span>
                </div>
            </div>
        </div>
        {/* <!--mobile Search popup Start here -->
        <!-- mobile calendar popup Start here --> 
        <div className="mobile_search_popup" id="mobileCal" style={{display:"none"}}>
            <a href="javascript" className="searchwindowclose"><i className="bi bi-x"></i></a>
            <div className="row row_margin" id="mobCalDiv">
                <div className="col-12">
                    <div className="dates">
                        <div className="mobile_header text-center" id="mobileDateLabel">Select Depart Date</div>
                        <div className="input_block col-50" id="mobileCalDept">
                            <input type="text" id="datepickerMob" className="inputField cursor" readOnly/>
                            <span className="icons bi bi-calendar3"></span>
                        </div>
                        <div className="input_block col-50" id="mobileCalRet">
                            <input type="text" id="datepickerMob2" className="inputField cursor" readOnly/>
                            <span className="icons bi bi-calendar3"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="full_calender">
                <div className="date_cal"></div>
            </div>
        </div> */}
        {/* <!--mobile Search popup Start here --> */}

        {/* <script type="text/javascript" src="resources/js/searchengine.js?5768"></script> */}

        {/* <!--container-content--> */}
        <div className="relative">

            {/* <!-- ServicesBox Start--> */}
           
            {/* <!-- ServicesBox End--> */}

            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="dealBox_image">
                            <h2 className="page_title">Top Picks for Domestic Flights</h2>
                            <p>From the iconic skyscrapers of London to the historic charm of Edinburgh, our selected destinations promise captivating adventures. Immerse yourself in the cultural tapestry of Manchester, explore the picturesque landscapes of the Lake District, or unwind in the coastal beauty of Cornwall. Whether you crave bustling cityscapes or tranquil escapes, our carefully curated picks ensure unforgettable experiences, inviting you to explore the richness of the UK.</p>
                        </div>
                    </div>
                </div>
                <div className="row g-3 destination" style={{marginBottom: "20px"}}>
                    <div className="col-lg-4 col-md-12 wow zoomIn animated animated" onClick={(e) => {handleCardClick("City", "gla", "glasgow"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}}>
                        <div className="position-relative d-block overflow-hidden" onClick={(e) => {handleCardClick("gla", "glasgow"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                            <img className="img-fluid" src={`https://imgfolders.com/rawfares/rawfaresUK/home-destination/GlasGow_1.webp`} alt="destination" style={{height: '237px'}} onClick={() => handleCardClick("gla", "glasgow")} />
                            {/* <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">25% OFF</div> */}
                            <div class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">£150.<sup>99</sup></div>
                            <div class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">GlasGow</div>
                        </div>
                        
                    </div>       
                    

                    <div className="col-lg-4 col-md-12 wow zoomIn animated animated">
                        <div className="position-relative d-block overflow-hidden" onClick={(e) => {handleCardClick("City", "lon", "london"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                            <img className="img-fluid" src={`https://imgfolders.com/rawfares/rawfaresUK/home-destination/London.webp`} alt="destination" style={{height: '237px'}}/>
                            {/* <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">25% OFF</div> */}
                            <div class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">£230.<sup>89</sup></div>
                            <div class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">London</div>
                        </div>
                        
                    </div>

                    <div className="col-lg-4 col-md-12 wow zoomIn animated animated">
                        <div className="position-relative d-block overflow-hidden" onClick={(e) => {handleCardClick("City", "man", "manchester"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                            <img className="img-fluid" src={`https://imgfolders.com/rawfares/rawfaresUK/home-destination/Manchester.webp`} alt="destination" style={{height: '237px'}}/>
                            {/* <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">25% OFF</div> */}
                            <div class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">£90.<sup>67</sup></div>
                            <div class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">Manchester</div>
                        </div>
                        
                    </div>
                <p style={{fontSize: '11px', color: '#959595', fontFamily: 'sans-serif'}}><b>Note:</b> All fares are quoted in GBP.
                    Last updated on Thursday 08/17/2023 at 05:00 AM, the fares mentioned above are for Round Trip flight tickets and inclusive of fuel surcharges, service fee and taxes . Based on historical data, these fares are subject to change without prior notice and cannot be guaranteed at the time of booking. Kindly go through our terms and conditions before booking
                </p>
                </div>




            </div>

            <div className="box-text grayBg" style={{marginBottom: '2px'}}>
                <div className="container">
                    <div className="row">
                        <div className="holder">
                            <div className="heading">
                            <h2>Unbeatable Airfare Deals</h2>
                                <p>Partnering with leading airlines such as British Airways, EasyJet, and Ryanair, we offer more than just flights—we at RawFares provide gateways to incredible journeys. Whether you&#39;re chasing sunsets on a sandy beach, discovering the history of charming cities, or exploring hidden gems, our curated airline deals guarantee you an incredible experience. Discover more, pay less, and let your next adventure unfold with RawFares.co.uk.</p>
                            </div>
                            <OwlCarousel className='owl-theme' loop margin={10} >
                                <div className="project-item mb-1">
                                <div onClick={(e) => {handleCardClick("Airline", "ba", "british-airways"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={`https://imgfolders.com/rawfares/rawfaresUK/home-airline/british-Airways.webp`} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>British Airlines</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-item mb-1">
                                    <div onClick={(e) => {handleCardClick("Airline", "vs", "virgin-atlantic"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={`https://imgfolders.com/rawfares/rawfaresUK/home-airline/virgin-atlantic.webp`} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>Virgin Atlantic</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="project-item mb-1">
                                    <div onClick={(e) => {handleCardClick("Airline", "u2", "easyjet"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={`https://imgfolders.com/rawfares/rawfaresUK/home-airline/easyjet.webp`} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>EasyJet Airlines</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="project-item mb-1">
                                    <div onClick={(e) => {handleCardClick("Airline", "ei", "aer-lingus"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={`https://imgfolders.com/rawfares/rawfaresUK/home-airline/Aer-Lingus.webp`} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>Aer Lingus</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="project-item mb-1">
                                    <div onClick={(e) => {handleCardClick("Airline", "lm", "loganair"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={`https://imgfolders.com/rawfares/rawfaresUK/home-airline/Loganair_1.webp`} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>Loganair</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="project-item mb-1">
                                    <div onClick={(e) => {handleCardClick("Airline", "no", "norse-atlantic-airways"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={`https://imgfolders.com/rawfares/rawfaresUK/home-airline/Norse-Atlantic.webp`} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>Norse Atlantic</p>

                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                            
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
            
            <div className="clearfix"></div>
            {/* <!-- Text Box Start here --> */}
            <div className="box-text">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Affordable Flights, Anywhere to Everywhere</h3>
                            <p>Welcome to RawFares, your trusted travel companion committed to making flights affordable for every adventurer. No matter your destination, our dedicated team ensures that your travel dreams meet reality without breaking the bank. Day or night, we collaborate with airlines and top travel platforms to secure the best deals for your journey, making RawFares.co.uk your go-to hub for accessible and stress-free travel planning.</p><br/>
                            <h3>Your Journey with Us!</h3>
                            <p>Your journey begins with RawFares, where affordability meets exceptional service. Our team of travel enthusiasts is with you every step of the way, ensuring that your travel experience exceeds expectations. Day or night, we collaborate with various airlines and top travel platforms, making flight cost worries a thing of the past. Whether you&#39;re planning a romantic escape or embarking on a spontaneous business trip, RawFares is not just facilitating journeys; we&#39;re unlocking a world where your travel dreams seamlessly align with budget-friendly reality. Trust us to turn your travel aspirations into memorable adventures.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Text Box End here --> */}
            <div className="clearfix"></div>
        </div>
        <Footer />
        </div>
  )
}
