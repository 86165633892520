// This is the controller file where one change reflects to everywhere.

const SITE_CONFIG = {
    apiIP: 'https://www.rawfares.co.uk',
    //apiIP: 'http://localhost',
    siteName: 'rawfares',
    siteID: '17',
  };
  
export default SITE_CONFIG;
  