/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import SITE_CONFIG from '../SiteController/SiteController';
import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
//import { useRef } from 'react';
import axios from "axios";

const CARD_OPTION = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#c4f0ff',
            // color: '#c4f0ff',
            color: 'blue',
            fontWeight: 500,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-sarif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': { color: '#fco883' },
            '::placeholder': { color: '#87bbfd' },
        },
        invalid: {
            iconColor: '#ffc7ee',
            // color: '#ffc7ee',
            color: 'red',
        },
    },
};

export default function StripeCard() {
    const [bid, setBid] = useState('');
  const [bookingRequest, setBookingRequest] = useState({ bookingID: '' });
  const [cardPayType, setCardPayType] = useState('')
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  const [responseViewBooking, setResponseViewBooking] = useState('');
  const [bookingId, setBookingId] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  useEffect(() => {
    const fetchData = async () => {
      try {
        const path = window.location.pathname;
        const bidFromPath = path.split('/').pop();
        setBid(bidFromPath);
  
        let decryptedTID = '';
        let fulValue = bidFromPath;
        let cardPayType = bidFromPath;
        const lengths = fulValue.length;
        cardPayType = fulValue.substring(lengths-1);
        fulValue = fulValue.substring(0, lengths - 15);
        fulValue = fulValue.substring(7);
        decryptedTID = fulValue;
  
        const requestPayload = {
          bookingId: parseInt(decryptedTID)
        };
        console.log(cardPayType)
        console.log(requestPayload)
        const response = await axios.post(`${apiIP}:8585/api/viewbooking`, requestPayload);
        console.log(response.data)
        if (
          response.data &&
          response.data.response &&
          response.data.response.length > 0 &&
          response.data.response[0].FareDetail &&
          response.data.response[0].FareDetail.length > 0
      ) {
          setResponseViewBooking(response.data.response[0].FareDetail[0]);
          //console.log(responseViewBooking);
          setBookingId(response.data.response[0].invoiceNum);
          //console.log(bookingId)
      } else {
          console.error("FareDetail is undefined or empty in the response");
      }

        //console.log(responseViewBooking.data)
        console.log(decryptedTID);
        setCardPayType(cardPayType)
        setBookingRequest({ ...bookingRequest, bookingID: decryptedTID });
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, []);


  const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [postalCode, setPostalCode] = useState('')
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);
        //console.log("Card Number:", CardNumberElement);
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
                address: {
                    postal_code: postalCode
                }
            }
        });
    
        if(!error) {
            try {
                const {id} = paymentMethod;
                const response = await axios.post("http://localhost:4000/payment", {        //http://localhost:4000/payment
                    amount: responseViewBooking.grandTotal*100,
                    id,
                    booking_id: bookingId
                });
                console.log(response.data);
                console.log(response);
               
                const { error: authError, paymentIntent } = await stripe.confirmCardPayment(
                           response.data.payment_intent_client_secret
                          );
           
                          if (authError) {
                              console.log('Error handling card action:', authError);
                              // Handle error
                          } else if (paymentIntent.status === 'requires_confirmation') {
                              // After handling 3D Secure authentication, confirm the payment
                              const confirmedPaymentIntent = await stripe.confirmCardPayment(
                                  response.data.payment_intent_client_secret
                              );
                              console.log(confirmedPaymentIntent)
           
                            //   if (confirmedPaymentIntent.error) {
                            //       console.log('Error confirming payment after 3D Secure authentication:', confirmedPaymentIntent.error);
                            //          // Handle error
                              } 
                              else {
                                     // Payment confirmed
                                     console.log('Successful Payment');
                                     setSuccess(true);
                                 }
                    //                 } else if (response.data.success) {
                                 // Regular successful payment
                                 //console.log("Successful Payment");
                    //                     setSuccess(true);
                                
                // Handle payment confirmation or other actions here
                setSuccess(true);
            } catch (error) {
                console.log("Error", error);
                setErrorMessage("Payment failed. Please try again.");
            }
        } else {
            console.log(error.message);
            setErrorMessage(error.message);
        }
    };
    useEffect(() => {
        console.log("Card Number:", cardNumber);
    }, [cardNumber]); 


  return (
     <>
            {!success ? (
                <form onSubmit={handleSubmit}>
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-md-6" style={{marginTop: '89px'}}>
                                <div className="form-group">
                                    <label>Card number</label>
                                    <CardNumberElement
                                        className="form-control"
                                        options={CARD_OPTION}
                                        onChange={(e) => { setCardNumber(e.complete ? e.value : ''); }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3" style={{marginTop: '89px'}}>
                                <div className="form-group">
                                    <label>Expiry date</label>
                                    <CardExpiryElement
                                        className="form-control"
                                        options={CARD_OPTION}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3" style={{marginTop: '89px'}}>
                                <div className="form-group">
                                    <label>CVC</label>
                                    <CardCvcElement
                                        className="form-control"
                                        options={CARD_OPTION}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Postal code</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={postalCode}
                                        onChange={(e) => setPostalCode(e.target.value)}
                                        style={CARD_OPTION}
                                        placeholder="12345"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {errorMessage && <div className="text-danger">{errorMessage}</div>}
                    <button type="submit" className="btn btn-primary" disabled={!stripe}>
                        Pay
                    </button>
                </form>
            ) : (
                <div>
                    <h2>Payment successful</h2>
                </div>
            )}
        </>
  )
}
