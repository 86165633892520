import React from 'react'

export default function footer2() {
  return (
    <div className="copyright-block">
        <div className="container">
        <p style={{textAlign: "center ", color: 'white', fontSize: '13px'}}>Rawfares.co.uk is represented by Holiay Breakz Group LLC which is registered in Woodberry House, 2 Woodberry Grove Finchley, London N120DR (United Kingdom)<br />Rawfares.co.uk is an independent travel portal with no third party association. By using Rawfares.co.uk, you agree that Rawfares is not accountable for any loss - direct or indirect, arising of offers, materials or links to other sites found on this website. In case of queries, reach us directly at our Contact Number 020-3627-9333 or, simply email at support@rawfares.co.uk</p>
            <div className="copyright"> Copyrights &copy; 2022-2024 rawfares.co.uk, All Rights Reserved. </div>
        </div>
    </div>
  )
}
