/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SITE_CONFIG from '../SiteController/SiteController';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import airlinesName from '../controller/Controller';
import '../resources/css/confirm-style.css';
import Footer2 from '../components/Footer/footer2';
import userIcon from '../resources/images/icons/user-icon.png';
import seatWhite from '../resources/images/icons/seat_icon_white.png';
import background1 from '../resources/images/web-banner-350-2.jpg';
import background2 from '../resources/images/Hotelbnr2.jpg';
import yesMark from '../resources/images/yesmark.png';
import MetaTitle from './metaTitle';


function formatDateAndTime(isoDate) {
    const dateOptions = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    };
  
    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
  
    const formattedDate = new Date(isoDate);
    const formattedDateString = new Intl.DateTimeFormat('en-US', dateOptions).format(formattedDate);
    const formattedTimeString = new Intl.DateTimeFormat('en-US', timeOptions).format(formattedDate);
  
    return { formattedDateString, formattedTimeString };
  }
  
  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} hrs ${remainingMinutes} min`;
  }

export default function Confirmation() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
    const location = useLocation();
  const { data } = location.state || {};
  const { searchID } = useParams();
  // const [fromAirportName, setFromAirportName] = useState('Unknown'); // Initialize with a default value
  // const [toAirportName, setToAirportName] = useState('Unknown');
//console.log(data)
  //const siteID = 2;
  const fetchData = async () => {
    const reloadData = {
      referenceNum: searchID,
      siteId: siteID,
    }
    //console.log(reloadData)
    try {
      //const requestData = searchID && siteID === 2;
      console.log(reloadData)
      const response = await axios.post(`${apiIP}:8383/api/getConfirmation`, reloadData);
      //data: response.data;
      console.log(response);
    } catch (error) {
      //console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // Call fetchData when the component mounts (page reloads)
    fetchData();
  }, [searchID]);

  const [airportNames, setAirportNames] = useState({});

  useEffect(() => {
    async function fetchAirportName(airportCode) {
      try {
        // const response = await axios.get(`http://54.70.41.205/flights/CrossAirportList?data=${airportCode}&authcode=farehuts`);
        const response = await axios.get(`https://www.tgsapi.com/flights/CrossAirportList?data=${airportCode}&authcode=farehuts`);
        const matchingAirport = response.data.find((item) => item.airportCode === airportCode);

    // Conditionally set the name based on the match
    const name = matchingAirport ? matchingAirport.airportName : 'Unknown';
        return name;
      } catch (error) {
        //console.error('Error fetching airport information:', error);
        return 'Unknown';
      }
    }

    if (data?.flightResut) {
      const outboundItems = data.flightResut.outBound || [];
      const inboundItems = data.flightResut.inBound || [];

      const airportCodes = [...outboundItems, ...inboundItems].reduce((codes, item) => {
        if (item.fromAirport) codes.push(item.fromAirport);
        if (item.toAirport) codes.push(item.toAirport);
        return codes;
      }, []);

      const airportNamePromises = airportCodes.map((code) => fetchAirportName(code));

      Promise.all(airportNamePromises).then((names) => {
        const updatedNames = {};
        airportCodes.forEach((code, index) => {
          updatedNames[code] = names[index];
        });
        setAirportNames(updatedNames);
      });
    }
  }, [data]);

  if (!data?.flightResut) {
    return <div>No data available</div>;
  }

  const totalPassengers = (data?.adults && data?.flightResut.adults ? parseInt(data?.flightResut.adults) : 0) + (data?.child && data?.flightResut.child ? parseInt(data?.flightResut.child) : 0) + (data?.infants && data?.flightResut.infants ? parseInt(data?.flightResut.infants) : 0) + (data?.infantsWs && data?.flightResut.infantsWs ? parseInt(data?.flightResut.infantsWs) : 0);

  const totalPriceAdults = (data?.adults * data?.flightResut.fare.adultFare).toFixed(2);
  const totalPriceChild = (data?.child * data?.flightResut.fare.childFare).toFixed(2);
  const totalPriceInfant = ((data?.infants && data?.flightResut.fare.infantFare ? parseInt(data?.infants) : 0) * data?.flightResut.fare.infantFare).toFixed(2);
  const totalPriceInfantWs = ((data?.infantsWs && data?.flightResut.fare.infantWsFare ? parseInt(data?.infantsWs) : 0) * data?.flightResut.fare.infantWsFare).toFixed(2);
  // const totalPriceSeat = (totalPassengers * 7.99);
  // const totalWebCheckInPrice = (totalPassengers * 7.99);

  let totalPrice = data?.flightResut.fare.grandTotal;
  if (data?.ancillaryProduct && data?.ancillaryProduct.length > 0) {
    // Use reduce to sum up the prices of all ancillary products
    totalPrice += data?.ancillaryProduct.reduce((sum, product) => sum + product.price, 0);
  }
  
const roundedPrice = totalPrice; // Rounds to 2 decimal places

const totalTax = data?.flightResut.fare.totalTax;
const roundedTax = totalTax?.toFixed(2);
  return (
    <>
    <MetaTitle url={`${apiIP}/confirmation`} />
    <div class="container-fluid">
			
			<div class="row">
			{data? (	
			<div class="col-md-12">
				<br/>				
				  <div class="confirm-detail-container">				  			  
				  <div class="row wrapper-confirmation">
				      <div class="col-md-9">
							<div class="comfirm-summry" style={{marginTop: '65px'}}>
                {data.bookingStatus !== 'Failure' ? (
                  <div>
							<h4><img src={yesMark} width="40" height="40" alt=""/> Thanks You, Your booking is In Progress. </h4>
              <p>Our associate will reach you shortly. You may also contact us at +44-020-3627-9333. Your booking ID is mentioned below.</p>
              </div>
              ) : (
                <div>
							<h4><img src={yesMark} width="40" height="40" alt=""/> Thanks You, Your booking is Confirmed. </h4>
              <p>We are handling your reservation and will send you confirmation once this has been finished and a ticket has been issued. It would be idea if you take a note that your purchase will be completed only when the ticket has been issued. Fares are not guaranteed until ticketed. In the rare event fares increase, you may opt to cancel your booking by contacting our customer support help desk. Your booking ID is mentioned below.</p>
              </div>
              )}
							<p class="confirm-thanks">Booking Status: In Progress{/*{data.bookingStatus === 'Failure' ? 'In Progress' : 'Success'}*/}</p>	
							<p class="confirm-booked">Booking ID: <span>{data.bookingID}</span></p>
							{/* <p class="confirm-booked">Booked On: <span>Friday, Sept 15, 2023</span></p> */}
							<div style={{clear: 'both'}}></div>	
							</div>
						<div class="cnfr-marb15"> 
							<div class="confirm-title">
							<h5><img src={userIcon} alt=""/> Flight Details</h5>
							</div>
							{/* <!--next-section--> */}
					     	<div class="confirm-boxsadow">
								
							<div class="confirm-detail-head">
								<div class="confirm-head-depar">Departure</div>
								<div class="confirm-head-Baggage">
              {/*<strong>Baggage:</strong> 2pc | */}<strong>Cabin</strong> {data.flightResut.cabinClass === 1 ? 'Economy' : data.flightResut.cabinClass === 2 ? 'Premium Economy' : data.flightResut.cabinClass === 3 ? 'Business' : data.flightResut.cabinClass === 4 ? 'First' : ''}
								{/* <p><strong>Brand Name:</strong> Basic Economy</p> */}
								</div>
							</div>
								
							{data?.flightResut?.outBound.map((outBoundItem, index) => (
  <div key={index} className="confirm-detail-row">
    <div className="confirm-mobl-respons">
      <div className="confirm-detail-airline">
        <img
          src={`https://imgfolders.com/farehutz/${outBoundItem.airlineName}.png`}
          alt=""
        />
        <div className="confirm-airline-name-fno">
          <div className="confirm-airlineName">
            {airlinesName[outBoundItem.airlineName]}
          </div>
          <div className="confirm-flightno">
            Flight No. {outBoundItem.flightNo} | EQP-{outBoundItem.equipmentType}
          </div>
          {outBoundItem.airlineName !== outBoundItem.opratingAirline ? (
          <div className="confirm-flightno">
            Operated by {airlinesName[outBoundItem.opratingAirline]}
          </div>
           ): null}
        </div>
      </div>
    </div>

    <div className="confirm-mobl-respons-next">
      <div className="confirm-detail-from-to">
        <div className="confirm-detail-from">
          <div className="confirm-from-city">
            <strong>{outBoundItem.fromAirport}</strong> - {airportNames[outBoundItem.fromAirport]}
          </div>
          <div className="confirm-from-time">
            <strong>
              {formatDateAndTime(outBoundItem.depDate).formattedTimeString}
            </strong>{' '}
            {formatDateAndTime(outBoundItem.depDate).formattedDateString}
          </div>
          {/* {
  outBoundItem.fromAirport &&
  outBoundItem.find(a => a.airportCode === outBoundItem.fromAirport)?.airportName !==
    (selectedFlight.outBound[0]?.toAirport &&
      airport.find(a => a.airportCode === selectedFlight.outBound[0]?.toAirport)?.airportName) && (
        <p className="cnfrm-arrivnext">Airport Changes</p>
                              )
} */}
          
        </div>
        <div className="confirm-ddinfo-durastop">
          <div className="confirm-duration">
            {`${convertMinutesToHoursAndMinutes(outBoundItem.eft)}`}
          </div>
          <div className="confirm-Detailsstop-graph"></div>
        </div>
        <div className="confirm-detail-to">
          <div className="confirm-from-city">
            <strong>{outBoundItem.toAirport}</strong> - {airportNames[outBoundItem.toAirport]}
          </div>
          <div className="confirm-from-time">
            <strong>
              {formatDateAndTime(outBoundItem.reachDate).formattedTimeString}
            </strong>{' '}
            {formatDateAndTime(outBoundItem.depDate).formattedDateString !==
          formatDateAndTime(outBoundItem.reachDate).formattedDateString ? (
            <sup>+1</sup>
          ) : null}{' '}
            {/* <sup>+1</sup>{' '} */}
            {formatDateAndTime(outBoundItem.reachDate).formattedDateString}
          </div>
          {formatDateAndTime(outBoundItem.depDate).formattedDateString !==
          formatDateAndTime(outBoundItem.reachDate).formattedDateString ? (
            <p className="cnfrm-arrivnext">Arrival next day</p>
          ) : null}
        </div>
      </div>
      {outBoundItem.layOverTime > 0 ? (
        <h2 className="confirm-layover-divider">
          <span>
            <i className="fa fa-clock"></i>(
            {convertMinutesToHoursAndMinutes(outBoundItem.layOverTime)}) At{' '}
            {airportNames[outBoundItem.toAirport]} ({outBoundItem.toAirport})
          </span>
        </h2>
      ) : null}
    </div>
  </div>
))}
								
							<div class="payment-detail-footer">
								<div class="confirm-head-Baggage">
									<p>Total Trip Duration: {`${convertMinutesToHoursAndMinutes(data.flightResut.outBound[0].estimateTime)}`}</p>
								</div>
							</div>
								
                            </div>
							{/* <!--next-section close-here-->							
							<!--next itnery--> */}
              {data?.flightResut?.inBound ? (
							<div class="confirm-boxsadow">
							<div class="confirm-detail-head">
								<div class="confirm-head-depar">Return</div>
								<div class="confirm-head-Baggage">
								{/*<strong>Baggage:</strong> 2pc | */}<strong>Cabin</strong> {data.flightResut.cabinClass === 1 ? 'Economy' : data.flightResut.cabinClass === 2 ? 'Premium Economy' : data.flightResut.cabinClass === 3 ? 'Business' : data.flightResut.cabinClass === 4 ? 'First' : ''}
								{/* <p><strong>Brand Name:</strong> Basic Economy</p> */}
								</div>
							</div>
								
                            
							
															
							
							{data?.flightResut?.inBound.map((inBoundItem, index) => (
                
  <div key={index} className="confirm-detail-row">
    <div className="confirm-mobl-respons">
      <div className="confirm-detail-airline">
        <img
          src={`https://imgfolders.com/farehutz/${inBoundItem.airlineName}.png`}
          alt=""
        />
        <div className="confirm-airline-name-fno">
          <div className="confirm-airlineName">
            {airlinesName[inBoundItem.airlineName]}
          </div>
          <div className="confirm-flightno">
            Flight No. {inBoundItem.flightNo} | EQP-{inBoundItem.equipmentType}
          </div>
          {inBoundItem.airlineName !== inBoundItem.opratingAirline ? (
          <div className="confirm-flightno">
            Operated by {airlinesName[inBoundItem.opratingAirline]}
          </div>
          ): null}
        </div>
      </div>
    </div>

    <div className="confirm-mobl-respons-next">
      <div className="confirm-detail-from-to">
        <div className="confirm-detail-from">
          <div className="confirm-from-city">
            <strong>{inBoundItem.fromAirport}</strong> - {airportNames[inBoundItem.fromAirport]}
          </div>
          <div className="confirm-from-time">
            <strong>
              {formatDateAndTime(inBoundItem.depDate).formattedTimeString}
            </strong>{' '}
            {formatDateAndTime(inBoundItem.depDate).formattedDateString}
          </div>
          {/* <p className="cnfrm-arrivnext">Airport Changes</p> */}
        </div>
        <div className="confirm-ddinfo-durastop">
          <div className="confirm-duration">
            {`${convertMinutesToHoursAndMinutes(inBoundItem.eft)}`}
          </div>
          <div className="confirm-Detailsstop-graph"></div>
        </div>
        <div className="confirm-detail-to">
          <div className="confirm-from-city">
            <strong>{inBoundItem.toAirport}</strong> - {airportNames[inBoundItem.toAirport]}
          </div>
          <div className="confirm-from-time">
            <strong>
              {formatDateAndTime(inBoundItem.reachDate).formattedTimeString}
            </strong>{' '}
            {formatDateAndTime(inBoundItem.depDate).formattedDateString !==
          formatDateAndTime(inBoundItem.reachDate).formattedDateString ? (
            <sup>+1</sup>
          ) : null}{' '}
            {/* <sup>+1</sup>{' '} */}
            {formatDateAndTime(inBoundItem.reachDate).formattedDateString}
          </div>
          {formatDateAndTime(inBoundItem.depDate).formattedDateString !==
          formatDateAndTime(inBoundItem.reachDate).formattedDateString ? (
            <p className="cnfrm-arrivnext">Arrival next day</p>
          ) : null}
        </div>
      </div>
      {inBoundItem.layOverTime > 0 ? (
        <h2 className="confirm-layover-divider">
          <span>
            <i className="fa fa-clock"></i>(
            {convertMinutesToHoursAndMinutes(inBoundItem.layOverTime)}) At{' '}
            {airportNames[inBoundItem.toAirport]} ({inBoundItem.toAirport})
          </span>
        </h2>
      ) : null}
    </div>
  </div>
))}
							
								
								
							
								
								
							<div class="payment-detail-footer">								
								<div class="confirm-head-Baggage">
									<p>Total Trip Duration: {`${convertMinutesToHoursAndMinutes(data.flightResut.inBound[0].estimateTime)}`}</p>
								</div>
							</div>							
						  	
							
						   
						    <div class="disclaimerbg">	
							<div class="row">								
							<div class="col-md-1 confirm-discseat">Disclaimer&nbsp;:</div>
							<div class="col-md-11 confirm-seat-disc"> Most airlines charge baggage fees, check the Baggage fee for complete details.</div>
							</div>
							</div>
								
                        </div> 
                        ) : null}
				    		{/* <!--next-section close-here--> */}
							
							
							
                        </div>
						
						
						<div class="cnfr-marb15">
						<div class="confirm-title">
							<h5><img src={userIcon} alt=""/> Traveler Details</h5>
							</div>	
					     <div class="confirm-boxsadow">
							 
						<table class="tblwidth">
                      <tr class="tblhead">
                        <td class="tblpadd">S.No</td>
                        <td class="tblpadd">Passenger(s) Name</td>
						{/* <td class="tblpadd">Middle Name</td> */}
						{/* <td class="tblpadd">Last Name</td> */}
                        <td class="tblpadd">Gender</td>
                        <td class="tblpadd">Date of Birth</td>
                    </tr>
                    
                    {data.passengerDetails.map((passenger, index) => (
      <tr className="tblrow" key={index}>
        <td className="tblpadd">{index + 1}.</td>
        {/* <td className="tblpadd">{passenger.title}</td> */}
        <td className="tblpadd">{passenger.title}. {passenger.name}{" "}{passenger.middleName}{" "}{passenger.lastName}</td>
        {/* <td className="tblpadd">{passenger.middleName}</td> */}
        {/* <td className="tblpadd">{passenger.lastName}</td> */}
        <td className="tblpadd">{passenger.gender === 1 ? "Male" : "Female"}</td>
        <td className="tblpadd">
          {`${passenger.dobDay}-${passenger.dobMonth}-${passenger.dobYear}`}
        </td>
      </tr>
    ))}
							
					{/* <tr class="tblrow">
                        <td class="tblpadd">1.</td>
                        <td class="tblpadd">Mrs. Anjali</td>
						<td class="tblpadd">Trivedi</td>
						<td class="tblpadd">Duve</td>
                        <td class="tblpadd">Male</td>
                        <td class="tblpadd">05-nov-2000</td>
                    </tr>		 */}
                    
                </table>
							 
							 <div class="disclaimerbg">	
							<div class="row">								
							<div class="col-md-1 confirm-discseat">Disclaimer&nbsp;:</div>
							<div class="col-md-11 confirm-seat-disc"> Any specific request you may have will be sent to the airline(s). Please contact your airline(s) directly, prior to your departure date, to confirm
                     any Special requests.</div>
							</div>
							</div>
							 
				        </div>						
			            </div>
						  
						<div class="cnfr-marb15">
							<div class="confirm-title">						
						 <h5 class="mar-b">
							 <img src={seatWhite} alt=""/> Price Summary
							 </h5>
							</div>							
							<div class="confirm-boxsadow">
								
							<div class="confirm-fare-area">
						<ul>
						<li><span>Adult ({(data.adults)}x£{data.flightResut.fare.adultFare.toFixed(2).split('.')[0]}.<sup>{data.flightResut.fare.adultFare.toFixed(2).split('.')[1]}</sup>)</span>  <span>£{totalPriceAdults.split('.')[0]}.<sup>{totalPriceAdults.split('.')[1]}</sup></span> </li>
            {data.child ? (
						<li><span>Child ({(data.child)}x£{data.flightResut.fare.childFare.toFixed(2).split('.')[0]}.<sup>{data.flightResut.fare.childFare.toFixed(2).split('.')[1]}</sup>)</span>  <span>£{totalPriceChild.split('.')[0]}.<sup>{totalPriceChild.split('.')[1]}</sup></span> </li>
            ): null}
            {data.infants ? (
						<li><span>Infant <span class="fonsmal">Lap</span> ({(data.infants)}x£{data.flightResut.fare.infantFare.toFixed(2).split('.')[0]}.<sup>{data.flightResut.fare.infantFare.toFixed(2).split('.')[1]}</sup>)</span>  <span>£{totalPriceInfant.split('.')[0]}.<sup>{totalPriceInfant.split('.')[1]}</sup></span> </li>
            ): null}
            {data.infantsWs ? (
						<li><span>Infant <span class="fonsmal">seat</span> ({(data.infantsWs)}x£{data.flightResut.fare.infantWsFare.toFixed(2).split('.')[0]}.<sup>{data.flightResut.fare.infantWsFare.toFixed(2).split('.')[1]}</sup>)</span>  <span>£{totalPriceInfantWs.split('.')[0]}.<sup>{totalPriceInfantWs.split('.')[1]}</sup></span> </li>
            ): null}
						<li><span>Taxes</span>  <span>£{roundedTax.split('.')[0]}.<sup>{roundedTax.split('.')[1]}</sup></span> </li>
            {data.ancillaryProduct && data.ancillaryProduct.length > 0 ? (
  <ul>
    {data.ancillaryProduct.map((product, index) => (
      <li key={index}>
        <span>{product.fullName}</span>
        <span>£{product.price.toString().split('.')[0]}.<sup>{product.price.toString().split('.')[1]}</sup></span>
      </li>
    ))}
  </ul>
) : null}

						{/* <li><span>Seat </span> <span>$35.<sup>25</sup></span> </li>

						<li><span>Baggage </span> <span>$30.<sup>00</sup></span> </li>

						<li><span>Flight Alert </span> <span>$35.<sup>00</sup></span> </li>	

						<li style={{borderBottom: '0px'}}><span>Bundle </span> <span>$30.<sup>00</sup></span> </li>	 */}

						</ul>

							<div class="pay-booked">
							<h6> Total Price : <div style={{float: 'right'}}>£<span>{(roundedPrice).toFixed(2).toString().split('.')[0]}.<sup>{(roundedPrice).toFixed(2).toString().split('.')[1]}</sup></span></div></h6>
								
								
								
							</div>

							</div>
								
								
								
								
								
								
							<div class="disclaimerbg">	
							<div class="row">								
							<div class="col-md-1 confirm-discseat">Disclaimer&nbsp;:</div>
							<div class="col-md-11 confirm-seat-disc"> All Price are quoted in GBP. Your credit/debit card may be billed in multiple charges totaling the final total price. Baggage allowance and fee    
                      amount are not guaranteed and are subject to change by the airline.
                        </div>
							</div>
							</div>	
							</div>
						</div>
						
						<div class="cnfr-marb15">
						<div class="confirm-title">
							<h5><img src={userIcon} alt=""/> 
								Flight Booking Terms & Policies</h5>
							</div>	
					     <div class="confirm-boxsadow">
						  <h5>Booking Policies</h5>	
							 <div class="tmcaply">
							<ul style={{marginBottom: '10px'}}>
								<li>Above itinerary and agency reference number is for information only. Your credit card payment is under process.</li>								
								<li>You would receive your E-tickets and airlines confirmation in a separate email.</li>
								
								<li> Your tickets would be issued once the payment is charged Fares are not guaranteed until the payment is charged.</li>								
								
								<li>Travodeals do not hold any responsibility for the fare or tax difference while the tickets are in issuance process.</li>
								
								<li>Any fare or tax difference has to pay by the passenger before the tickets are issued</li>
								
								<li>If there is a problem in processing the payment, you would be notified instantly. in case you do not receive an email or call within hours of making this reservation. Then please visit us or call us on the following number www.domain ( +44-020-3627-9333 )</li>
								
								<li>We strongly recommend all travelers to confirm the status of their flight at least 72 hours before departure.
                                </li>
								
							 </ul> 
								 </div>
							 
							 <div class="disclaimerbg">
							 <h5 style={{textAlign: 'center', marginBottom: '5px'}}>For immediate help on your booking, please call us at +44-020-3627-9333</h5>
							 </div>

				        </div>						
			            </div>
						
						
						
					
					</div>	
					  
					  <div class="col-md-3" style={{marginTop: '65px'}}>
					    <div class="cnfr-marb15 is-sticky">							
					  <div class="confirm-boxsadow">
						  	<div class="booked-fare-area" style={{background: `url(${background1}) center center/cover no-repeat`, height: '640px', paddingTop: '55px'}}>
							<ul>
							<li>Trustworthy experts always ready to assist you</li>
							<li>Best flight deals for smart travelers available</li>
							<li>Dive into our curated popular destination list.</li>
							<li>Cancel with ease in the first 24-hours</li>
							<li>Confirm your trip with speedy booking assurance</li>
							</ul>
						</div>
						  </div>
							
						<div class="confirm-boxsadow">
							<img src={background2} style={{width: '100%'}} alt=""/> 
							</div>	
							
					 </div>
						  
					   
					  </div>
					  
				    </div>
				  
				  </div>
				</div>
			): (<p>No data received</p>)}	
		      </div>
				
			</div> 
      <Footer2 />
      </>
  )
}
