// NotFoundPage.js

import React from 'react';
import Header from '../components/Header/header';
import Footer from '../components/Footer/footer';
import image from '../resources/images/404/169024464_xl_normal_none.jpg'
import MetaTitle from './metaTitle';

const NotFoundPage = () => {
  return (
    <>
    <MetaTitle pageTitle="404 Page" url='https://www.rawfares.co.uk/404/' />
    <Header />
    <div>
        <img height='500' style={{width: '100%'}} src={image} alt="" />
      <h1 style={{textAlign: 'center'}}>404 - Page Not Found</h1>
      <p style={{textAlign: 'center'}}>Sorry, the page you are looking for does not exist.</p>
    </div>
    <Footer />
    </>
  );
};

export default NotFoundPage;
