// import React from 'react';
// import { Helmet } from 'react-helmet';

// export default function MetaTitle({ title, description, url, image, twitterHandle, pageTitle, pageDescription }) {
//   return (
//     <div>
//       {/* Other content of your component */}
//       <Helmet>
//       <link rel="canonical" href={url} />
//         <meta name="twitter:card" content="summary" />
//         <meta name="twitter:site" content={twitterHandle} />
//         {/* Add other Twitter meta tags as needed */}

//         {/* Open Graph */}
//         <meta property="og:title" content={title} />
//         <meta property="og:description" content={description} />
//         <meta property="og:url" content={url} />
//         <meta property="og:image" content={image} />
//         <title>{pageTitle}</title>
//         <meta name="description" content={pageDescription} />
//       </Helmet>
//     </div>
//   );
// }


import React from 'react';
import { Helmet } from 'react-helmet';

export default function MetaTitle({ title, description, url, image, twitterHandle, pageTitle, pageDescription }) {
  return (
    <div>
      {/* Other content of your component */}
      <Helmet>
      <link rel="canonical" href={url} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Rawfares" />
        <meta name="twitter:creator" content="@Rawfares" />
        <meta name="twitter:image" content="" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        {/* Add other Twitter meta tags as needed */}

        {/* Open Graph */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:site_name" content="Rawfares" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content="https://imgfolders.com/rawfares/rawfaresUS/airline_detail/central_destination/times-square.webp" />
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
    </div>
  );
}
