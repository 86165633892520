/* eslint-disable no-unused-vars */
import React from 'react'
import Logo from '../../resources/images/logo-png--28.png';
//import Logo2 from '../../resources/images/logo2.png'
import '../../resources/css/navber.css';
import '../../resources/css/meanmenu.css';
//import '../../resources/js/custom';
//import '../../resources/js/jquery.meanmenu';
import us from '../../resources/images/flag_us.png';
import uk from '../../resources/images/flag_uk.png';
import ca from '../../resources/images/flag_ca.png';
import callIcon from '../../resources/images/call-icon.png'
import {BrowserRouter, Link, NavLink } from 'react-router-dom';


export default function Header({ backgroundColor }) {
    const headerStyle = {
        backgroundColor: backgroundColor,
        // Add other styles
      };
  return (
    
   
    <header style={headerStyle} className="main_header_arae">
            <div className="navbar-area" style={{backdropFilter: 'blur(10px)'}}>
                <div className="main-responsive-nav">
                    <div className="container">
                        <div className="main-responsive-menu" style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div className="logo">
                                <NavLink to="/">
                                    <img src={Logo} alt="logo"/>
                                </NavLink>
                            </div>
                            <div className="dot-menu">
                            <Link to="tel:0203-627-9333">
                                <img src={callIcon} alt="icons" />
                            </Link>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="main-navbar">
                    <div className="container">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <Link className="navbar-brand" to="/">
                                <img src={Logo} alt="logo"/>
                            </Link>
                            <div className="collapse navbar-collapse mean-menu">
                                
                            </div>
                            <div className="call-number others-options">
                                
                                <Link to="tel:+44-020-3627-9333" title="Call Us 24/7 for Lowest Airfares">0203-627-9333</Link>
                            </div>
                            <div className="country-flag">
                                <div className="sub_header_link">
                                <img src={uk} alt="flag_uk"/>
                                           GBP &pound;  {/* <i className="down_arw"></i> */}
                                    {/* <div className="dropdown-country">
                                        <a href="https://www.rawfares.com/">
                                            <img src={us} alt="flag_us"/>
                                            USD $;
                                        </a>
                                        <a href="https://www.rawfares.ca/">
                                            <img src={ca} alt="flag_ca"/>
                                            CAD $
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </nav>

                    </div>
                </div>

                {/* <div className="others-option-for-responsive">
                    <div className="container">
                        

                    </div>
                </div> */}

            </div>
        </header>
        
  )
}
