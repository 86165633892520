//import logo from './logo.svg';
//import './App.css';
import {BrowserRouter as Router} from 'react-router-dom';
import Layout from './components/Layout/layout';
import CookieNotice from './pages/CookieNotice';

function App() {
  return (
    <Router>
    <div className="App">
      <Layout />
      <CookieNotice />
      
    </div>
    </Router>
  );
}

export default App;
