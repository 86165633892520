import React from 'react'
import FlightList from './FlightList'

export default function Flightsearchresult() {
  return (
    <div>
      <FlightList />
    </div>
  )
}
