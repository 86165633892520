/* eslint-disable no-unused-vars */
import React from 'react';
import '../resources/css/terms&condition.css'
import Footer from '../components/Footer/footer';
import MetaTitle from './metaTitle';
import SITE_CONFIG from '../SiteController/SiteController';

export default function TermsCondition() {
    const { apiIP, siteName, siteID } = SITE_CONFIG;
  return (
    <>
    <MetaTitle pageTitle={`Learn About Term and Conditions- Rawfares.co.uk`} pageDescription='Read the Terms and conditions in detail on Rawfares.co.uk.' url={`${apiIP}/terms-and-condition`} />
    <body>
   
    
    {/* <!--Header Ends Here--> */}

    <div class="blog-head" style={{marginTop: '70px'}}>
        {/* <span>Terms and Conditions</span> */}
    </div>
    <div style={{backgroundColor: '#ffffff'}}>
    <div class="aboutus">
        <article class="mfs_content">
            <p>
               We always look forward with an opportunity to provide the best services to our customers. Your use of
this website completely affirms the terms and conditions hereunder. Please ensure you must read the
Terms and Conditions carefully before trusting our services offered by us. When you use or make a flight
booking on our site, then you are supposed to accept the Terms and Conditions. If in case you do not
agree with any of the Terms and Condition, you should not make use of this website, Rawfares only
have the authority to change the Terms and Condition as needed            </p>

            <h2>Limited Use of Terms and Conditions</h2>
            <p>
               To cancel the flight tickets prior to the departure date, its liability of money will be refunded to the
Traveler, towards the canceled ticket. To alter, amend, differ or withdraw by any tour, the facility has
been advertised or published by the organization. The organization is not liable for any reimbursement,
additional expense, or any loss that is suffered by a group of people or by the traveler.
            </p>

            <p> Apart from the organization, no other person is entitled to modify, add, vary, or waive off any
representation. Description, Terms, and conditions set forth here, or in the organization brochure, or
other Terms and Condition in regards a tour been booked by the Traveler which is authorized by the
organization.</p>

            <h2>To proceed with the Holiday as amended or modified</h2>

            <p>
                To agree with any holiday or tour that organization might offer. The organization is not responsible for
any of the afore cases of the Traveler/Group for damage, additional expense, and huge loss which is
being suffered by the Traveler/Group of people.
            </p>



            <p> In no circumstance, the organization is not liable to the Traveler/Group of people for Any death,
accident, sickness, personal injury, high expense, delay, loss, or consequential damage by any
misadventure or by other similar reason Any default of any carrier, travel element supplier, or by any
agent/servant employed by the travel supplier who may be liable for the provision of meals,
accommodations, carriage facility, or the Traveler/ Group service, howsoever it is caused. In regards to
this, depicts the negligence on the role of any involved person.</p>

            <p> No responsibility for the organization arise, thorough any means out of the contract with regards to
holiday, excursion, tour facility shall initiate the total paid amount or agreed to the paid-for booking the
tour, holidays, excursion, in no cases shall include any damage or additional expense whatsoever</p>

            <p>
                The price quoted in the organization brochure(s)/quotation is in the GBP or its equivalent foreign
currency. The Organization is the sole creation to amend the cost if there is a case of currency fluctuation or in a change in exchange rated or arising flue cost, and to surcharged accordingly. All Such
prices to be paid whenever there is an amendment in the rates. The Flight Ticket does not cover
Insurance premium costs. The Traveler/Group has to make payment for premium cost </p>

            <p> All tour programs are bound by-laws, rules, and regulations, during the entire tour and traveling. The
Organization is not responsible in regards to any condition which is brought about by such laws, or due
to the act of creation.</p>

            <p> Any complaint raised by the traveler (including complaining on behalf of another Traveler or of the
group who submitted the same through the Traveler) is associated with or because of the tour should
be conveyed to the organization prior to 21 days in written with the detail of the tour end date. Under
any circumstances, no form of claim, or complaint shall be entertained with regards to aforesaid dates.
No separate complaint of the traveler traveling with the group of people shall be considered, so the
same campaign must be made only by the Traveler who is the leader of the entire group.</p>

            <p> The organization is not responsible for any traveler for refund, compensation, or claim in regards to
reducing the number of tour days or postpone, cancellation, re-routing for any scheduled transport
service due to come reason like a natural calamity. The rule in regards to the flight cancellation, the
refund is applicable.</p>


            <h2>In Case of Disputes</h2>

            <p> Rawfares is dedicated to providing customer satisfaction at each time, if in case you have any problem
or dispute related to our products or service, we will surely look in to matter at the earliest. Along with
this, if we fail to do so, you can claim for the same at your convenience. It has also been explained in the
below flowing sections:</p>

            <p> While dealing with us, you give us an opportunity to resolve disputes, claim regards the website, dealing
with our agent, any services, or ay product provided by us, any kind of representation made by us, or
privacy policy, by contacting Rawfares customer support division. In case we fail to resolve your claim,
you may seek relief through meditation or in court, which has been set forth in the section</p>

            <p> Any claim can be resolved by mediation rather than court. excluding when you assert a claim on an
individual basis in court for a claim if they clear for the same. This includes any claim you state against
us, our subsidiaries, or any claim that offers products or services with us.</p>

            <p> No need for a Judge or jury in the meditation, the review on meditation is prohibited. However, a
mediator can compensate the damages and relief on an individual basis as the court itself, and also
follow as well as initiate these Terms and Conditions as a court would. American Arbitration Association
(AAA) conducts Mediation under its own set of rules, which include the AAA consumer rules. Payment of
all administration, mediator fees, is governed by the AAA rules, excluding as provided in the section. You
can choose mediation can be conducted over the phone, based on written submission, or in person
within the state where you stay or some mutually agreed location</p>

            <p>
                The claim will be resolved which is made on an individual basis, not as a class. The Federal Act and
Federal Meditation law are applied to the agreement. Meditation decision is confirmed by the court
which holds competent jurisdiction.            </p>

            <h3>Prohibited Activities</h3>

            <p>
                The content and the information used in the website is (which included not only limited to price but also
the availability of travel services) is used to provide information such as proprietary to us or its affiliates
or suppliers. while you make travel copies in regards to travel or reservation book through the website
you too agree to not to copy, transmit, distribute, display, perform, reproduce, license, transfer, or sale
of any information or products or services which is obtained through the website. At the same time also
agree not to:            </p>

             <ul><li><p> Make use of the website for any commercial purpose</p></li>
            <li><p> Make any false, unconfirmed reservation.</p></li>
            <li><p> Make a copy of the information or content of this website by using a manual or automated process
for any purpose without seeking authorization from us</p></li>

           <li> <p> Disobeying the restriction of the website or try to pass another measure which engages to
prevent/limit the website access</p></li>

           <li> <p> Deeply link any part of the website which includes, no limitation, the medium of purchase for any
travel services for any function without our permission</p></li>
            <li><p> Copy or incorporate a segment of this website into another website without taking prior
authorization from us</p> </li></ul>

            <p> In case your flight booking, the account shows any sign of absurdity, fraud, or any suspicious activity
here Rawfares will cancel travel/service reservation, which is associated with your name, email ID or
account also may close your accounts as well. If you have performed any wrong activity then Rawfares
has the right to instigate or may take legal towards the same, also you will be charged a fine by us. In
context to Rawfares booking/cancellation/freezing/closure of an account, kindly get in touch with our
24*7 customer support.</p>
            </article>       

            <p></p>
        
    </div>
    </div>
    <Footer />
</body>
</>
  )
}
