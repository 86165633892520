const AUState = [ 
    { 'ID': '0', 'Name': 'Select State'  },
    { 'ID': 'CT', 'Name': 'CT-Australian Capital Territory'  },
    { 'ID': 'NS', 'Name': 'NS-New South Wales'  },
    { 'ID': 'NT', 'Name': 'NT-Northern Territory'  },
    { 'ID': 'QL', 'Name': 'QL-Queensland'  },
    { 'ID': 'SA', 'Name': 'SA-South Australia'  },
    { 'ID': 'TS', 'Name': 'TS-Tasmania'  },
    { 'ID': 'VI', 'Name': 'VI-Victoria'  },
    { 'ID': 'WA', 'Name': 'WA-Western Australia'  },];
    export default AUState;