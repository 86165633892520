/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// import React, { useState, useEffect } from 'react'
// import { useLocation, Navigate } from 'react-router-dom';

// export default function GoogleMeta() {
//     const location = useLocation();
//   const { search } = location;
//   const [flightResult, setFlightResult] = useState({
//     resultId: '',
//     valCarrier: '',
//     fareType: '',
//     gdsType: '',
//     cabinClass: '',
//     fare: {
//         adultFare: '',
//         childFare: '',
//         infantFare: '',
//         infantWsFare: '',
//         adultTax: '',
//         childTax: '',
//         infantTax: '',
//         infantWsTax: '',
//         adultMarkup: '',
//         childMarkup: '',
//         infantMarkup: '',
//         infantWsMarkup: '',
//         bagFees: '',
//         grandTotal: '',
//         markupID: '',
//         markupType: '',
//         totalMarkup: '',
//         grandOrgTotal: '',
//         baseFare: '',
//         totalTax: '',
//         noPax: '',
//         ttlCharge: '',
//         issueFees: '',
//         atlFee: '',
//         cardFee: '',
//         onlnProfit: '',
//         ccAgntFee: '',
//         agntProfit: '',
//         ttlProfit: ''
//     },
//     outBound: [
//         {
//             SequenceNumber: '',
//             airline: '',
//             orgAirline: '',
//             flightID: '',
//             flightNo: '',
//             equipmentType: '',
//             equipmentTypeDes: '',
//             fromAirport: '',
//             depDate: '',
//             toAirport: '',
//             reachDate: '',
//             opratingAirline: '',
//             resDesignCode: '',
//             toTerminal: '',
//             cabinClass: 1,
//             eft: '',
//             estimateTime:'' ,
//             layOverTime: '',
//             fareType: "",
//             isETicketEligible: false,
//             airlineName: "",
//             orgdepDate: "",
//             orgreachDate: ""
//         }
//     ],
//     inBound: [
//         {
//             SequenceNumber: '',
//             airline: '',
//             orgAirline: '',
//             flightID: '',
//             flightNo: '',
//             equipmentType: '',
//             equipmentTypeDes: '',
//             fromAirport: '',
//             depDate: '',
//             toAirport: '',
//             reachDate: '',
//             opratingAirline: '',
//             resDesignCode: '',
//             toTerminal: '',
//             cabinClass: 1,
//             eft: '',
//             estimateTime:'' ,
//             layOverTime: '',
//             fareType: "",
//             isETicketEligible: false,
//             airlineName: "",
//             orgdepDate: "",
//             orgreachDate: ""
//         }
//     ],
//     outEFT: '',
//     inEFT: '',
//     maxSeat: '',
//     airline: ''
//   })
//   // Function to extract URL parameters
//   const getURLParam = (paramName) => {
//     const urlSearchParams = new URLSearchParams(search);
//     return urlSearchParams.get(paramName);
// };

// const handleBookNow = () => {
//     setFlightResult({
//         cabinClass: getURLParam('Cabin1'),
//         fare: {
//             markupType: getURLParam('PointOfSaleCountry'),
//             grandOrgTotal: getURLParam('DisplayedPrice'),
//         },
//         outBound: [
//             {
//             SequenceNumber: '',
//             airline: getURLParam('Carrier1'),
//             orgAirline: getURLParam('Carrier1'),
//             flightID: '',
//             flightNo: getURLParam('FlightNumber1'),
//             equipmentType: '',
//             equipmentTypeDes: '',
//             fromAirport: getURLParam('Origin1'),
//             depDate: '',
//             toAirport: getURLParam('Destination1'),
//             reachDate: '',
//             opratingAirline: '',
//             resDesignCode: '',
//             toTerminal: '',
//             cabinClass: getURLParam('Cabin1'),
//             eft: '',
//             estimateTime: '',
//             layOverTime: '',
//             fareType: "",
//             isETicketEligible: false,
//             airlineName: "",
//             orgdepDate: "",
//             orgreachDate: ""
//             }
//         ],

//         inBound: [
//             {
//                 SequenceNumber: '',
//                 airline: '',
//                 orgAirline: '',
//                 flightID: '',
//                 flightNo: '',
//                 equipmentType: '',
//                 equipmentTypeDes: '',
//                 fromAirport: '',
//                 depDate: '',
//                 toAirport: '',
//                 reachDate: '',
//                 opratingAirline: '',
//                 resDesignCode: '',
//                 toTerminal: '',
//                 cabinClass: 1,
//                 eft: '',
//                 estimateTime:'' ,
//                 layOverTime: '',
//                 fareType: "",
//                 isETicketEligible: false,
//                 airlineName: "",
//                 orgdepDate: "",
//                 orgreachDate: ""
//             }
//         ],
//         outEFT: '',
//         inEFT: '',
//         maxSeat: '',
//         airline: ''
//     })
//     Navigate('/payment', { flightResult });
//   };

//   useEffect(() => {
//     handleBookNow();
// })

//   return (
//     <div>
      
//     </div>
//   )
// }


import React, { useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    browserName,
    isAndroid,
    isDesktop,
    isIOS,
    isMobile,
  } from "react-device-detect";
  import axios from "axios";

function GoogleMeta() {
  //const [responseDataPayment, setResponseDataPayment] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function fetchDataAndNavigate() {
    try {
    // Extract query parameters from the URL
    const url = new URL(window.location.href);
    const queryParams = Object.fromEntries(url.searchParams.entries());

    const deviceName = isMobile
        ? "Mobile Device"
        : isDesktop
        ? "Desktop"
        : isAndroid
        ? "Android"
        : isIOS;
        const response = await axios.get("https://api.ipify.org?format=json");
        const userIP = response.data.ip;
    // Function to create segments from query parameters
    function createSegments(sliceParam, cabinKey, carrierKey, departureKey, arrivalKey, originKey, destinationKey, departureTimeKey, arrivalTimeKey) {
      const slices = (queryParams[sliceParam] || '').split(',');
    
      const segments = [];
      for (let i = 0; i < slices.length; i++) {
        const sliceData = slices[i];
        const cabinKeyVal = queryParams[cabinKey + sliceData];
    
        // Map cabin class to numbers based on your conditions
        let cabinClass;
        if (cabinKeyVal === 'Economy') {
          cabinClass = '1';
        } else if (cabinKeyVal === 'Premium Economy') {
          cabinClass = '2';
        } else if (cabinKeyVal === 'Business') {
          cabinClass = '3';
        } else if (cabinKeyVal === 'First') {
          cabinClass = '4';
        } else {
          cabinClass = '';
        }
    
        const depDateTime = queryParams[departureKey + sliceData] + 'T' + queryParams[departureTimeKey + sliceData] + ':00';
        const reachDateTime = queryParams[arrivalKey + sliceData] + 'T' + queryParams[arrivalTimeKey + sliceData] + ':00';
    
        // Calculate estimateTime in minutes
        const depDate = new Date(depDateTime);
        const reachDate = new Date(reachDateTime);
    
        const timeDifferenceInMilliseconds = reachDate - depDate;
        const timeDifferenceInMinutes = timeDifferenceInMilliseconds / (1000 * 60); // Convert milliseconds to minutes
    
        const layOverTime = calculateLayoverTime(queryParams, sliceData, i);
        const estimateTime = calculateEstimateTime(queryParams, sliceData, i);
    
        const segment = {
          SequenceNumber: i + 1,
          airline: queryParams[carrierKey + sliceData] || '',
          cabinClass: cabinClass,
          flightNo: '',
          equipmentType: '',
          depDate: depDateTime,
          reachDate: reachDateTime,
          fromAirport: queryParams[originKey + sliceData] || '',
          toAirport: queryParams[destinationKey + sliceData] || '',
          estimateTime: estimateTime,
          layOverTime: layOverTime,
          fareType: 'RP',
          isETicketEligible: '',
          airlineName: queryParams[carrierKey + sliceData] || '',
          orgdepDate: depDateTime,
          orgreachDate: reachDateTime,
          // Add other segment properties as needed
        };
        segments.push(segment);
      }
    
      return segments;
    }
    
    function calculateLayoverTime(queryParams, sliceData, segmentIndex) {
      if (segmentIndex === 0 && sliceData === '1') {
        const arrivalDateTime1 = queryParams['ArrivalDate1'] + 'T' + queryParams['ArrivalTime1'] + ':00';
        const departureDateTime2 = queryParams['DepartureDate2'] + 'T' + queryParams['DepartureTime2'] + ':00';
    
        const arrivalDate1 = new Date(arrivalDateTime1);
        const departureDate2 = new Date(departureDateTime2);
    
        const layoverMilliseconds = departureDate2 - arrivalDate1;
        return layoverMilliseconds / (1000 * 60);
      } 
      else if (segmentIndex === 0 && sliceData === '3') {
        const arrivalDateTime3 = queryParams['ArrivalDate3'] + 'T' + queryParams['ArrivalTime3'] + ':00';
        const departureDateTime4 = queryParams['DepartureDate4'] + 'T' + queryParams['DepartureTime4'] + ':00';
    
        const arrivalDate3 = new Date(arrivalDateTime3);
        const departureDate4 = new Date(departureDateTime4);
    
        const layoverMilliseconds = departureDate4 - arrivalDate3;
        return layoverMilliseconds / (1000 * 60);
      }
      return 0;
    }

    function calculateEstimateTime(queryParams, sliceData, segmentsData) {
      const segments = sliceData.split(',');
    
      let totalTimeInMinutes = 0;
    
      for (let i = 0; i < segments.length - 1; i++) {
        const currentSegment = segmentsData[segments[i]];
        const nextSegment = segmentsData[segments[i + 1]];
    
        if (currentSegment && nextSegment) {
          const currentReachDateTime = new Date(currentSegment.reachDate);
          const nextDepDateTime = new Date(nextSegment.depDate);
    
          const timeDifferenceInMilliseconds = nextDepDateTime - currentReachDateTime;
          const timeDifferenceInMinutes = timeDifferenceInMilliseconds / (1000 * 60);
    
          totalTimeInMinutes += timeDifferenceInMinutes;
        }
      }
    
      return totalTimeInMinutes;
    }
    
    
    
    
    
    

    // function createSegments2(Slice1, Slice2, cabinKey, carrierKey, departureKey, arrivalKey, originKey, destinationKey, departureTimeKey, arrivalTimeKey) {
    //   const slice1 = queryParams[Slice1] || '';
    //   const slice2 = queryParams[Slice2] || '';

    //   const segments = [{
    //     originAirport: queryParams[originKey + slice1] || '',
    //     destinationAirport: queryParams[destinationKey + slice2] || '',
    //     travelDate: queryParams[departureKey + slice1] + 'T' + queryParams[departureTimeKey + slice1] || '',
    //     originCountry: "US",
    //     destinationCountry: "US",
    //   },
    //   {
    //     originAirport: queryParams[originKey + slice2] || '',
    //     destinationAirport: queryParams[destinationKey + slice2] || '',
    //     travelDate: queryParams[departureKey + slice2] + 'T' + queryParams[departureTimeKey + slice2] || '',
        
    //   }]
    
    //   return segments;
    // }
    
    // Set your data and navigate to /payment
    const flightResult = {
      resultId: '1000',
      valCarrier: '',
      gdsType: 2,
      cabinClass: queryParams.Cabin1 === 'Economy' ? 1 :
            queryParams.Cabin1 === 'PremiumEconomy' ? 2 :
            queryParams.Cabin1 === 'Business' ? 3 :
            queryParams.Cabin1 === 'First' ? 4 : '',
      // ... (other data properties)
      fare: {
        //adultFare: queryParams.Adult || '',
        adultFare: queryParams.DisplayedPrice || '',
        childFare: 0.0,
        infantFare: 0.0,
        infantWsFare: 0.0,
        adultTax: 0.0,
        childTax: 0.0,
        infantTax: 0.0,
        infantWsTax: 0.0,
        adultMarkup: 0.0,
        childMarkup: 0.0,
        infantMarkup: 0.0,
        infantWsMarkup: 0.0,
        bagFees: 0.0,
        grandTotal: queryParams.DisplayedPrice || '',
        baseFare: 0.0,
        markupID: 0,
        totalMarkup: 0.0,
        totalTax: 0.0,
        noPax: 0.0,
        ttlCharge: 0.0,
        issueFees: 0.0,
        atlFee: 0.0,
        cardFee: 0.0,
        onlnProfit: 0.0,
        ccAgntFee: 0.0,
        agntProfit: 0.0,
        ttlProfit: 0.0,
        markupType: queryParams.PointOfSaleCountry || '',
        grandOrgTotal: queryParams.DisplayedPrice || '',
        // ... (other fare properties)
      },
      outBound: createSegments('Slice1', 'Cabin', 'Carrier', 'DepartureDate', 'ArrivalDate', 'Origin', 'Destination', 'DepartureTime', 'ArrivalTime'),
      inBound: createSegments('Slice2', 'Cabin', 'Carrier', 'DepartureDate', 'ArrivalDate', 'Origin', 'Destination', 'DepartureTime', 'ArrivalTime'),
      // Set other properties
      outEFT: '',
      inEFT: '',
      maxSeat: '',
      airline: '',
      IsPriceChange: ''
    };
    
    
    
    const slice1Origins = [];
const slice1Destinations = [];
const slice2Origins = [];
const slice2Destinations = [];
const slice2DepartDate = [];
const slice2DepartTime = []

for (let i = 1; queryParams['Origin' + i]; i++) {
  if (queryParams['Slice1'] && queryParams['Slice1'].includes(i.toString())) {
    slice1Origins.push(queryParams['Origin' + i]);
    slice1Destinations.push(queryParams['Destination' + i]);
  } else if (queryParams['Slice2'] && queryParams['Slice2'].includes(i.toString())) {
    slice2Origins.push(queryParams['Origin' + i]);
    slice2Destinations.push(queryParams['Destination' + i]);
    slice2DepartDate.push(queryParams['DepartureDate' + i]);
    slice2DepartTime.push(queryParams['DepartureTime' + i])
  }

  //console.log('i:', i);
  //console.log('slice2Origins:', slice2Origins);
  //console.log('slice2Destinations:', slice2Destinations);
  //console.log(queryParams);

}


const firstOrigin = slice1Origins[0] || '';
const secondOrigin = slice2Origins[0] || '';
const lastDestination = slice2Destinations[slice2Destinations.length - 1] || '';
const lastDestination1 = slice1Destinations[slice1Destinations.length - 1] || '';
const DepartDate2 = slice2DepartDate[slice2DepartDate.length - 1] || '';
const DepartTime2 = slice2DepartTime[slice2DepartTime.length - 1] || '';



// Get the first origin of Slice1 and the last destination of Slice2


    const formData = {
        searchID: queryParams.ReferralId || '',
    client: 2,
    segment: [
      {
        originAirport: firstOrigin,
    destinationAirport: lastDestination1,
    travelDate: queryParams.DepartureDate1 + 'T' + queryParams.DepartureTime1 + ':00' || '',
    originCountry: "US",
    destinationCountry: "US",
      },
      {
        originAirport: secondOrigin,
    destinationAirport: lastDestination,
    travelDate: DepartDate2 + 'T' + DepartTime2 + ':00' || '' ,

      },
    ],
    searchDirectFlight: false,
    flexibleSearch: false,
    tripType: queryParams.TripType === 'RoundTrip' ? 2 : 1,
    adults: queryParams.Adult,
    child: queryParams.Child || '',
    infants: queryParams.Infants || '',
    infantsWs: queryParams.InfantsWs || '',
    cabinType: "1",
    airline: "All",
    currencyCode: "USD",
    siteId: 2,
    source: "Online",
    media: "farehuts",
    sID: "",
    rID: "",
    locale: "en",
    isNearBy: false,
    limit: 200,
    userIP: userIP,
    serverIP: "",
    device: deviceName,
    browser: browserName,
    }
    //console.log(flightResult)
    //console.log(formData)

    try {
      const response = await axios.post(
        "http://localhost:8282/api/googleMeta",{
          flightResult: flightResult,
          adults: queryParams.Adult,
          child: queryParams.Child || '',
          infant: queryParams.Infants || '',
          infantsWs: queryParams.InfantsWs || '',
          currencyCode: formData.currencyCode,
          siteID: 6,
          
           // Initialize flightResult to null
          tripadd: false // Send the requestData object as the request data
          }
      );
      console.log(response);
      console.log(flightResult)

      const depDate = new Date("2023-11-17T12:50:00");
const reachDate = new Date("2023-11-17T15:40:00");

if (!isNaN(depDate) && !isNaN(reachDate)) {
  const timeDifferenceInMilliseconds = reachDate - depDate;
  const timeDifferenceInMinutes = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
  console.log("Time difference in minutes:", timeDifferenceInMinutes);
} else {
  console.log("Invalid date or time format");
}

      if (response.status === 200) {
        if (response.data.IsFlightInvalid) {
          // Navigate to / if IsFlightInvalid is true
          // Replace this with your actual navigation logic
          // Example using React Router: history.push('/');
          navigate('/')
        } else {
          if (response.data.IsPriceChange) {
            const flightResult = {
              resultId: "1000",
              valCarrier: "",
              gdsType: 2,
              cabinClass:
                queryParams.Cabin1 === "Economy"
                  ? 1
                  : queryParams.Cabin1 === "PremiumEconomy"
                  ? 2
                  : queryParams.Cabin1 === "Business"
                  ? 3
                  : queryParams.Cabin1 === "First"
                  ? 4
                  : "",
              // ... (other data properties)
              fare: {
                adultFare: 0.0,
                childFare: 0.0,
                infantFare: 0.0,
                infantWsFare: 0.0,
                adultTax: 0.0,
                childTax: 0.0,
                infantTax: 0.0,
                infantWsTax: 0.0,
                adultMarkup: 0.0,
                childMarkup: 0.0,
                infantMarkup: 0.0,
                infantWsMarkup: 0.0,
                bagFees: 0.0,
                grandTotal: queryParams.DisplayedPrice || "",
                baseFare: 0.0,
                markupID: 0,
                totalMarkup: 0.0,
                totalTax: 0.0,
                noPax: 0.0,
                ttlCharge: 0.0,
                issueFees: 0.0,
                atlFee: 0.0,
                cardFee: 0.0,
                onlnProfit: 0.0,
                ccAgntFee: 0.0,
                agntProfit: 0.0,
                ttlProfit: 0.0,
                markupType: queryParams.PointOfSaleCountry || "",
                grandOrgTotal: queryParams.DisplayedPrice || "",
                // ... (other fare properties)
              },
              outBound: createSegments(
                "Slice1",
                "Cabin",
                "Carrier",
                "DepartureDate",
                "ArrivalDate",
                "Origin",
                "Destination",
                "DepartureTime",
                "ArrivalTime"
              ),
              inBound: createSegments(
                "Slice2",
                "Cabin",
                "Carrier",
                "DepartureDate",
                "ArrivalDate",
                "Origin",
                "Destination",
                "DepartureTime",
                "ArrivalTime"
              ),
              // Set other properties
              outEFT: 11,
              inEFT: 11,
              maxSeat: 2,
              airline: "",
              IsPriceChange: true, // Set IsPriceChange to true
            };
    
            // Navigate to /Payment if IsPriceChange is true
            // Replace this with your actual navigation logic
            // Example using React Router: history.push('/Payment');
            const paymentState = {flightResult, formData , airport: response.data.airport}
            navigate(`/payment/${queryParams.ReferralId}/${flightResult.resultId}`, { state: paymentState });
          } else {
            const paymentState = {flightResult, formData , airport: response.data.airport}
            navigate(`/payment/${queryParams.ReferralId}/${flightResult.resultId}`, { state: paymentState });
            // Handle other cases
            // Navigate to a different route or perform other actions
          }
        }
      } else {
        // Handle other cases
      }
    } catch (error) {
      console.error("Request failed:", error);
    }
    // Navigate to /payment
    //navigate('/payment', { selectedFlight, flightRequest });
  } catch (error) {
    // Handle any errors that may occur during the HTTP request
    console.error('Error:', error);
  }
}

// Call the async function
fetchDataAndNavigate();

  }, [navigate, location]);

  return (
    <></>
    // Your JSX and component rendering logic here
  );
}

export default GoogleMeta;
