import React, { useState, useEffect } from 'react';
import '../resources/css/Cookies.css'

const CookieNotice = () => {
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    const isAccepted = localStorage.getItem('cookieConsent');
    if (isAccepted) {
      setAccepted(true);
    }
  }, []);

  const handleAccept = () => {
    // Set a flag or value to remember the user's choice
    localStorage.setItem('cookieConsent', 'true');
    setAccepted(true);
  };

  if (accepted) {
    return null; // If cookies are accepted, don't display the notice
  }

  return (
    <div className="cookie-notice">
      We use cookies for safer and more dynamic experiences for users. Click here to explore detailed information on the usage of cookies.{' '}
      {/* <a href="#/" onClick={handleLearnMore}>
        Click here to learn more about how we use cookies.
      </a>{' '} */}
      <button className='accept' onClick={handleAccept}>Accept</button>
    </div>
  );
};

export default CookieNotice;

