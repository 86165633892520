import React, { useState } from 'react';
import Header from '../Header/header'
//import Routers from '../Routers/Routers'
//import Footer from '../Footer/footer'
import Router from '../../routers/routers'

const Layout = () => {
   const [headerColor, setHeaderColor] = useState('none');
   //const hideFooter = false;
   //const currentPathname = window.location.pathname;

   
   return (
      <>
         <Header backgroundColor={headerColor}/>
         <Router setHeaderColor={setHeaderColor}/>
         {/* <Footer /> */}
      </>
   )
}

export default Layout