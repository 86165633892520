/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import MetaTitle from './metaTitle';
import '../resources/css/contact-style.css';
import Footer from '../components/Footer/footer';
import SITE_CONFIG from '../SiteController/SiteController';
//import background from "../resources/images/search_banner/Homepage-Main-banner.png";

export default function About() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  return (
    <>
    {/* <Helmet>
        <title>About us - RawFares</title>
        <meta name="description" content="This is About page of www.rawfares.co.uk" />
        {/* Other meta tags */}
        <link rel="canonical" href={`${apiIP}/about`} />
        {/* Additional meta tags for homepage 
      </Helmet> */}
    <MetaTitle pageTitle='Read About Rawfares.co.uk' pageDescription='Do you want to know about Rawfares.co.uk? So you can explore for more.' url={`${apiIP}/about`} />
    <div class="container-fluid" /*style={{marginTop: '75px'}}*/>
			
			<div class="row">
			<div class="content_page_title1" style={{marginTop: '75px'}}>
			<div class="container">
			<h1><img src="resources/images/icons/tnc-icon.svg" alt=""/> </h1>
			</div>
			</div>
				
		<div class="textBox">
        <div class="container">
          <div class="row">
			<div class="content-wrap">
                <p>At RawFares.co.uk, we transcend the commonplace, reshaping the travel landscape to offer a world of
possibilities for every adventurer. Our commitment is simple yet profound: not only to provide the finest
flights to major destinations but also to the undiscovered corners that beckon the curious spirit.</p>
                <p> Our odyssey begins with a profound passion for exploration, a love for wanderlust, and a vision to
democratize travel. RawFares has metamorphosed into a holistic haven for those seeking more than
flights—an immersive and budget-friendly travel experience. Whether your dream entails meandering
through vibrant city streets or discovering the tranquility of a hidden gem, RawFares serves as your
guiding light in the expansive realm of travel.</p>
                <p>We take pride in being your portal to both renowned destinations and the off-the-beaten-path treasures
awaiting exploration. From iconic skylines to secret marvels, our platform swings wide the doors to a
myriad of destinations, each possessing its distinctive charm. We ardently believe that every journey
should weave a tapestry of diverse experiences, and our extensive array of flight options embodies this
philosophy.</p>
				
				<div class="clearfix"></div>
				
				<div class="right_image">
					<img src="resources/images/about-img1.png" style={{width: '100%'}} alt="" />
				  </div>
          <h2>Why Choose RawFares?</h2>
                 <p> Deciphering airline intricacies can be akin to navigating a labyrinth. RawFares.co.uk steps in not just to
provide an array of flight options but to unravel the complexities of airline policies, ensuring you embark
on your journey well-informed and empowered. Whether it&#39;s unraveling baggage regulations or
demystifying cancellation policies, we&#39;ve got you covered, granting you the confidence to navigate the
skies seamlessly.</p> 
                 <p>RawFares.co.uk transcends being a mere flight platform; it&#39;s a curator of exclusive deals meticulously
crafted to elevate your travel experience. Through strategic alliances with airlines, we bring you deals
that surpass the ordinary. Imagine unlocking discounts and perks that not only make your journey
affordable but also infuse it with added value. At RawFares, we believe every traveler deserves VIP
treatment, and our exclusive deals are crafted with this ethos in mind.</p>
				  
				  <h2>Our Pledge: Clear and Authentic</h2>
                <p>
                Transparency is the cornerstone of RawFares.co.uk. We acknowledge that trust is earned, and we are
unwaveringly committed to fostering a relationship grounded in authenticity. Our platform mirrors our
commitment to furnishing you with precise information, impartial insights, and a human touch in the
digital realm of travel planning.</p>
                <p>Embarking on a journey with RawFares.co.uk transcends reaching a destination; it&#39;s about relishing the
excitement, savoring the exploration, and cherishing the moments in between. Our user-friendly
interface ensures that discovering the perfect flight is a seamless experience. Navigating through myriad
options, exploring exclusive deals, and understanding airline policies become a delightful prelude to
your upcoming adventure.</p>
				  
             <h3>Connect with Us</h3>
                <p>
                At RawFares, we understand the power of connections. Our customer support isn&#39;t just a service; it&#39;s a
personal link, a bridge connecting your questions to our commitment to ensuring your travel dreams
take flight. Whether you have a query, a special request, or need advice on your travel plans, our team is
here and ready to assist, guiding you every step of the way.</p>
                <p>In the expansive world of digital travel, RawFares.co.uk is more than a website; it&#39;s a companion, a
guide, and a facilitator of dreams. Come along as we redefine travel, one seamless journey at a time.
RawFares.co.uk - Navigating Dreams, Elevating Journeys.</p>
              </div>	
          </div>
        </div>
      </div>
				
			
				
		      </div>
				
			</div> 
      <Footer />
      </>
  )
}

