import React from 'react';
//import { useLocation } from 'react-router-dom';
import '../resources/css/Loader.css';
import '../resources/css/search_engine-result.css';
import '../resources/css/search_engine.css'
// import RoundIcon from '../resources/images/icons/roundtrip_icon.png';
// import swap from "../resources/images/icons/swap.png";
// import calandar from '../resources/images/icons/calendar.svg'
// import user from '../resources/images/icons/user-black.svg';
export default function Collecting() {
    //const location = useLocation();
    // const formValues = location.state || {};
    // //const airport = responseData?.airport || [];
    // const getCabinTypeLabel = (cabinTypeValue) => {
    //     switch (cabinTypeValue) {
    //       case "1":
    //         return "Economy";
    //       case "2":
    //         return "Business";
    //       case "3":
    //         return "Premium Economy";
    //       case "4":
    //         return "First";
    //       default:
    //         return "Unknown";
    //     }
    //   };

    //   const getAirportInfo = (originAirportCode, airportData) => {
    //     const matchingAirport = airportData.find(
    //       (airport) => airport.airportCode === originAirportCode
    //     );
      
    //     if (matchingAirport) {
    //       return `${matchingAirport.airportCode} - \n${matchingAirport.airportName}`;
    //     } else {
    //       return '';
    //     }
    //   };

    //   const getCityInfo = (originAirportCode, airportData) => {
    //     const matchingAirport = airportData.find(
    //       (airport) => airport.airportCode === originAirportCode
    //     );
    
    //     if (matchingAirport) {
    //       return `${matchingAirport.cityName}(${matchingAirport.countryName})`;
    //     } else {
    //       return '';
    //     }
    //   };
      //console.log(responseData.airport.cityName)

      //const totalPassengers = parseInt(formValues.adults) + parseInt(formValues.child) + parseInt(formValues.infants) + parseInt(formValues.infantsWs);
  return (
    <div>
        
      <loading> 	
    <div class="loader-box">    
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <section id="rightCntr">
              <div class="loader_icon" style={{margin: '174px auto 10px'}}>
                <div></div>
              </div>
              <div class="loading_block_content">
                <div class="searching">Searching for the best deals for you</div>
                <div class="searched_route"> <strong>Kolkata (CCU)</strong> 
					<span class="trip-arrow"> 
					<img src="images/roundtrip.png" alt="" /> </span> 
					<strong>San Francisco (SFO)</strong> 
				  </div>
                <div>
                  <div class="date"> 
				   Thu, Sep 07, 2023 - 	Thu, Sep 14, 2023
					</div>
                  1 Traveler, Economy</div>
                <div> </div>
              </div>
            </section>
          </div>
        </div>
      </div>   
  </div>
	</loading>
    </div>
  )
}
