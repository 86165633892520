const countryList=[
    { 'ID': '0' , 'Name': 'Select Country'  },
    { 'ID': 'US' , 'Name': 'United States'  },
    { 'ID': 'CA' , 'Name': 'Canada'  },
    { 'ID': 'GB' , 'Name': 'United Kingdom'  },
    { 'ID': 'AI' , 'Name': 'Anguilla'  },
    { 'ID': 'AG' , 'Name': 'Antigua and Barbuda'  },
    { 'ID': 'AR' , 'Name': 'Argentina'  },
    { 'ID': 'AM' , 'Name': 'Armenia'  },
    { 'ID': 'AW' , 'Name': 'Aruba'  },
    { 'ID': 'AU' , 'Name': 'Australia'  },
    { 'ID': 'AT' , 'Name': 'Austria'  },
    { 'ID': 'AZ' , 'Name': 'Azerbaijan'  },
    { 'ID': 'BS' , 'Name': 'Bahamas'  },
    { 'ID': 'BH' , 'Name': 'Bahrain'  },
    { 'ID': 'BB' , 'Name': 'Barbados'  },
    { 'ID': 'BE' , 'Name': 'Belgium'  },
    { 'ID': 'BZ' , 'Name': 'Belize'  },
    { 'ID': 'BM' , 'Name': 'Bermuda'  },
    { 'ID': 'BO' , 'Name': 'Bolivia'  },
    { 'ID': 'BA' , 'Name': 'Bosnia Herzegovina'  },
    { 'ID': 'BW' , 'Name': 'Botswana'  },
    { 'ID': 'BR' , 'Name': 'Brazil'  },
    { 'ID': 'VG' , 'Name': 'British Virgin Islands'  },
    { 'ID': 'BN' , 'Name': 'Brunei Darussalam'  },
    { 'ID': 'BG' , 'Name': 'Bulgaria'  },
    { 'ID': 'KH' , 'Name': 'Cambodia'  },
    { 'ID': 'KY' , 'Name': 'Cayman Islands'  },
    { 'ID': 'CL' , 'Name': 'Chile'  },
    { 'ID': 'CN' , 'Name': 'China'  },
    { 'ID': 'CR' , 'Name': 'Costa Rica'  },
    { 'ID': 'HR' , 'Name': 'Croatia'  },
    { 'ID': 'CY' , 'Name': 'Cyprus'  },
    { 'ID': 'CZ' , 'Name': 'Czech Republic'  },
    { 'ID': 'DK' , 'Name': 'Denmark'  },
    { 'ID': 'DM' , 'Name': 'Dominica'  },
    { 'ID': 'DO' , 'Name': 'Dominican Republic'  },
    { 'ID': 'EC' , 'Name': 'Ecuador'  },
    { 'ID': 'EG' , 'Name': 'Egypt'  },
    { 'ID': 'SV' , 'Name': 'El Salvador'  },
    { 'ID': 'EE' , 'Name': 'Estonia'  },
    { 'ID': 'FI' , 'Name': 'Finland'  },
    { 'ID': 'FR' , 'Name': 'France'  },
    { 'ID': 'GE' , 'Name': 'Georgia'  },
    { 'ID': 'DE' , 'Name': 'Germany'  },
    { 'ID': 'GR' , 'Name': 'Greece'  },
    { 'ID': 'GD' , 'Name': 'Grenada'  },
    { 'ID': 'GP' , 'Name': 'Guadeloupe'  },
    { 'ID': 'GU' , 'Name': 'Guam'  },
    { 'ID': 'GT' , 'Name': 'Guatemala'  },
    { 'ID': 'GY' , 'Name': 'Guyana'  },
    { 'ID': 'HT' , 'Name': 'Haiti'  },
    { 'ID': 'HN' , 'Name': 'Honduras'  },
    { 'ID': 'HK' , 'Name': 'Hong Kong'  },
    { 'ID': 'HU' , 'Name': 'Hungary'  },
    { 'ID': 'IS' , 'Name': 'Iceland'  },
    { 'ID': 'IN' , 'Name': 'India'  },
    { 'ID': 'ID' , 'Name': 'Indonesia'  },
    { 'ID': 'IQ' , 'Name': 'Iraq'  },
    { 'ID': 'IE' , 'Name': 'Ireland'  },
    { 'ID': 'IL' , 'Name': 'Israel'  },
    { 'ID': 'IT' , 'Name': 'Italy'  },
    { 'ID': 'JM' , 'Name': 'Jamaica'  },
    { 'ID': 'JP' , 'Name': 'Japan'  },
    { 'ID': 'JO' , 'Name': 'Jordan'  },
    { 'ID': 'KZ' , 'Name': 'Kazakstan'  },
    { 'ID': 'KW' , 'Name': 'Kuwait'  },
    { 'ID': 'KG' , 'Name': 'Kyrgyzstan'  },
    { 'ID': 'LA' , 'Name': 'Lao Peoples Democratic Republic'  },
    { 'ID': 'LV' , 'Name': 'Latvia'  },
    { 'ID': 'LB' , 'Name': 'Lebanon'  },
    { 'ID': 'LT' , 'Name': 'Lithuania'  },
    { 'ID': 'LU' , 'Name': 'Luxembourg'  },
    { 'ID': 'MO' , 'Name': 'Macau'  },
    { 'ID': 'MY' , 'Name': 'Malaysia'  },
    { 'ID': 'MT' , 'Name': 'Malta'  },
    { 'ID': 'MQ' , 'Name': 'Martinique'  },
    { 'ID': 'MX' , 'Name': 'Mexico'  },
    { 'ID': 'FM' , 'Name': 'Micronesia'  },
    { 'ID': 'MN' , 'Name': 'Mongolia'  },
    { 'ID': 'MS' , 'Name': 'Montserrat'  },
    { 'ID': 'NA' , 'Name': 'Namibia'  },
    { 'ID': 'NL' , 'Name': 'Netherlands'  },
    { 'ID': 'AN' , 'Name': 'Netherlands Antilles'  },
    { 'ID': 'NZ' , 'Name': 'New Zealand'  },
    { 'ID': 'NI' , 'Name': 'Nicaragua'  },
    { 'ID': 'NO' , 'Name': 'Norway'  },
    { 'ID': 'OM' , 'Name': 'Oman'  },
    { 'ID': 'PS' , 'Name': 'Palestinian Territory, Occupied'  },
    { 'ID': 'PG' , 'Name': 'Papua New Guinea'  },
    { 'ID': 'PY' , 'Name': 'Paraguay'  },
    { 'ID': 'PH' , 'Name': 'Philippines'  },
    { 'ID': 'PL' , 'Name': 'Poland'  },
    { 'ID': 'PT' , 'Name': 'Portugal'  },
    { 'ID': 'QA' , 'Name': 'Qatar'  },
    { 'ID': 'MK' , 'Name': 'Republic of Macedonia'  },
    { 'ID': 'RO' , 'Name': 'Romania'  },
    { 'ID': 'RU' , 'Name': 'Russia'  },
    { 'ID': 'SM' , 'Name': 'San Marino'  },
    { 'ID': 'SA' , 'Name': 'Saudi Arabia'  },
    { 'ID': 'RS' , 'Name': 'Serbia'  },
    { 'ID': 'SG' , 'Name': 'Singapore'  },
    { 'ID': 'SK' , 'Name': 'Slovakia'  },
    { 'ID': 'SI' , 'Name': 'Slovenia'  },
    { 'ID': 'ZA' , 'Name': 'South Africa'  },
    { 'ID': 'KR' , 'Name': 'South Korea'  },
    { 'ID': 'ES' , 'Name': 'Spain'  },
    { 'ID': 'LK' , 'Name': 'Sri Lanka'  },
    { 'ID': 'KN' , 'Name': 'St. Christopher (St. Kitts) Nevis'  },
    { 'ID': 'LC' , 'Name': 'St. Lucia'  },
    { 'ID': 'PM' , 'Name': 'St. Pierre and Miquelon'  },
    { 'ID': 'VC' , 'Name': 'St. Vincent and The Grenadines'  },
    { 'ID': 'SR' , 'Name': 'Suriname'  },
    { 'ID': 'SE' , 'Name': 'Sweden'  },
    { 'ID': 'CH' , 'Name': 'Switzerland'  },
    { 'ID': 'SY' , 'Name': 'Syrian Arab Republic'  },
    { 'ID': 'TW' , 'Name': 'Taiwan'  },
    { 'ID': 'TH' , 'Name': 'Thailand'  },
    { 'ID': 'TT' , 'Name': 'Trinidad and Tobago'  },
    { 'ID': 'TR' , 'Name': 'Turkey'  },
    { 'ID': 'TC' , 'Name': 'Turks and Caicos Islands'  },
    { 'ID': 'UA' , 'Name': 'Ukraine'  },
    { 'ID': 'AE' , 'Name': 'United Arab Emirates'  },
    { 'ID': 'UY' , 'Name': 'Uruguay'  },
    { 'ID': 'VI' , 'Name': 'US Virgin Islands'  },
    { 'ID': 'VE' , 'Name': 'Venezuela'  },
    { 'ID': 'VN' , 'Name': 'Vietnam'  },
    { 'ID': 'YE' , 'Name': 'Yemen'  }, 
    ];	

// const usState = [ { 'ID': '0' , 'Name': 'Please Select'  },
//              { 'ID': 'AA'  ,'Name': 'AA-Armed Forces (the) Americas'  },
//                 { 'ID': 'AE'  ,'Name': 'AE-Armed Forces Elsewhere'  },
//                 { 'ID': 'AP'  ,'Name': 'AP-Armed Forces Pacific'  },
//                 { 'ID': 'AL'  ,'Name': 'AL-Alabama'  },
//                 { 'ID': 'AK'  ,'Name': 'AK-Alaska'  },
//                 { 'ID': 'AZ'  ,'Name': 'AZ-Arizona'  },
//                 { 'ID': 'AR'  ,'Name': 'AR-Arkansas'  },
//                 { 'ID': 'CA'  ,'Name': 'CA-California'  },
//                 { 'ID': 'CO'  ,'Name': 'CO-Colorado'  },
//                 { 'ID': 'CT'  ,'Name': 'CT-Connecticut'  },
//                 { 'ID': 'DE'  ,'Name': 'DE-Delaware'  },
//                 { 'ID': 'DC'  ,'Name': 'DC-District of Columbia'  },
//                 { 'ID': 'FL'  ,'Name': 'FL-Florida'  },
//                 { 'ID': 'GA'  ,'Name': 'GA-Georgia'  },
//                 { 'ID': 'HI'  ,'Name': 'HI-Hawaii'  },
//                 { 'ID': 'ID'  ,'Name': 'ID-Idaho'  },
//                 { 'ID': 'IL'  ,'Name': 'IL-Illinois'  },
//                 { 'ID': 'IN'  ,'Name': 'IN-Indiania'  },
//                 { 'ID': 'IA'  ,'Name': 'IA-Iowa'  },
//                 { 'ID': 'KS'  ,'Name': 'KS-Kansas'  },
//                 { 'ID': 'KY'  ,'Name': 'KY-Kentucky'  },
//                 { 'ID': 'LA'  ,'Name': 'LA-Louisiana'  },
//                 { 'ID': 'ME'  ,'Name': 'ME-Maine'  },
//                 { 'ID': 'MD'  ,'Name': 'MD-Maryland'  },
//                 { 'ID': 'MA'  ,'Name': 'MA-Massachusetts'  },
//                 { 'ID': 'MI'  ,'Name': 'MI-Michigan'  },
//                 { 'ID': 'MN'  ,'Name': 'MN-Minnesota'  },
//                 { 'ID': 'MS'  ,'Name': 'MS-Mississippi'  },
//                 { 'ID': 'MO'  ,'Name': 'MO-Missouri'  },
//                 { 'ID': 'MT'  ,'Name': 'MT-Montana'  },
//                 { 'ID': 'NE'  ,'Name': 'NE-Nebraska'  },
//                 { 'ID': 'NV'  ,'Name': 'NV-Nevada'  },
//                 { 'ID': 'NH'  ,'Name': 'NH-New Hampshire'  },
//                 { 'ID': 'NJ'  ,'Name': 'NJ-New Jersey'  },
//                 { 'ID': 'NM'  ,'Name': 'NM-New Mexico'  },
//                 { 'ID': 'NY'  ,'Name': 'NY-New York'  },
//                 { 'ID': 'NC'  ,'Name': 'NC-North Carolina'  },
//                 { 'ID': 'ND'  ,'Name': 'ND-North Dakota'  },
//                 { 'ID': 'OH'  ,'Name': 'OH-Ohio'  },
//                 { 'ID': 'OK'  ,'Name': 'OK-Oklahoma'  },
//                 { 'ID': 'OR'  ,'Name': 'OR-Oregon'  },
//                 { 'ID': 'PA'  ,'Name': 'PA-Pennsylvania'  },
//                 { 'ID': 'RI'  ,'Name': 'RI-Rhode Island'  },
//                 { 'ID': 'SC'  ,'Name': 'SC-South Carolina'  },
//                 { 'ID': 'SD'  ,'Name': 'SD-South Dakota'  },
//                 { 'ID': 'TN'  ,'Name': 'TN-Tennessee'  },
//                 { 'ID': 'TX'  ,'Name': 'TX-Texas'  },
//                 { 'ID': 'UT' , 'Name': 'UT-Utah'  },
//                 { 'ID': 'VT'  ,'Name': 'VT-Vermont'  },
//                 { 'ID': 'VA'  ,'Name': 'VA-Virginia'  },
//                 { 'ID': 'WA' , 'Name': 'WA-Washington'  },
//                 { 'ID': 'WV' , 'Name': 'WV-West Virginia'  },
//                 { 'ID': 'WI' , 'Name': 'WI-Wisconsin'  },
//                 { 'ID': 'WY' , 'Name': 'WY-Wyoming'  },
//          ];
//            const usPhone = [ 
//             { 'ID': '1','Name': 'Puerto Rico (1)'},
//             { 'ID': '93','Name': 'Afghanistan (93)'},
//             { 'ID': '355','Name': 'Albania (355)'},
//             { 'ID': '213','Name': 'Algeria (213)'},
//             { 'ID': '1684','Name': 'American Samoa (1684)'},
//             { 'ID': '376','Name': 'Andorra (376)'},
//             { 'ID': '244','Name': 'Angola (244)'},
//             { 'ID': '1264','Name': 'Anguilla (1264)'},
//             { 'ID': '672','Name': 'Antarctica (672)'},
//             { 'ID': '1268','Name': 'Antigua and Barbuda (1268)'},
//             { 'ID': '54','Name': 'Argentina (54)'},
//             { 'ID': '297','Name': 'Aruba (297)'},
//             { 'ID': '61','Name': 'Australia (61)'},
//             { 'ID': '43','Name': 'Austria (43)'},
//             { 'ID': '1242','Name': 'Bahamas (1242)'},
//             { 'ID': '973','Name': 'Bahrain (973)'},
//             { 'ID': '880','Name': 'Bangladesh (880)'},
//             { 'ID': '1246','Name': 'Barbados (1246)'},
//             { 'ID': '32','Name': 'Belgium (32)'},
//             { 'ID': '501','Name': 'Belize (501)'},
//             { 'ID': '229','Name': 'Benin (229)'},
//             { 'ID': '1441','Name': 'Bermuda (1441)'},
//             { 'ID': '975','Name': 'Bhutan (975)'},
//             { 'ID': '591','Name': 'Bolivia (591)'},
//             { 'ID': '387','Name': 'Bosnia and Herzegovina (387)'},
//             { 'ID': '267','Name': 'Botswana (267)'},
//             { 'ID': '55','Name': 'Brazil (55)'},
//             { 'ID': '673','Name': 'Brunei (673)'},
//             { 'ID': '359','Name': 'Bulgaria (359)'},
//             { 'ID': '226','Name': 'Burkina Faso (226)'},
//             { 'ID': '257','Name': 'Burundi (257)'},
//             { 'ID': '855','Name': 'Cambodia (855)'},
//             { 'ID': '237','Name': 'Cameroon (237)'},
//             { 'ID': '1','Name': 'Canada (1)'},
//             { 'ID': '238','Name': 'Cape Verde (238)'},
//             { 'ID': '1345','Name': 'Cayman Islands (1345)'},
//             { 'ID': '236','Name': 'Central African Republic (236)'},
//             { 'ID': '235','Name': 'Chad (235)'},
//             { 'ID': '56','Name': 'Chile (56)'},
//             { 'ID': '86','Name': 'China (86)'},
//             { 'ID': '61','Name': 'Christmas Island (61)'},
//             { 'ID': '61','Name': 'Cocos (Keeling) Islands (61)'},
//             { 'ID': '57','Name': 'Colombia (57)'},
//             { 'ID': '269','Name': 'Comoros (269)'},
//             { 'ID': '243','Name': 'Democratic Republic of the Congo (243)'},
//             { 'ID': '682','Name': 'Cook Islands (682)'},
//             { 'ID': '506','Name': 'Costa Rica (506)'},
//             { 'ID': '385','Name': 'Croatia (385)'},
//             { 'ID': '357','Name': 'Cyprus (357)'},
//             { 'ID': '420','Name': 'Czech Republic (420)'},
//             { 'ID': '45','Name': 'Denmark (45)'},
//             { 'ID': '253','Name': 'Djibouti (253)'},
//             { 'ID': '1767','Name': 'Dominica (1767)'},
//             { 'ID': '1809','Name': 'Dominican Republic (1809)'},
//             { 'ID': '593','Name': 'Ecuador (593)'},
//             { 'ID': '20','Name': 'Egypt (20)'},
//             { 'ID': '503','Name': 'El Salvador (503)'},
//             { 'ID': '240','Name': 'Equatorial Guinea (240)'},
//             { 'ID': '372','Name': 'Estonia (372)'},
//             { 'ID': '251','Name': 'Ethiopia (251)'},
//             { 'ID': '500','Name': 'Falkland Islands (500)'},
//             { 'ID': '298','Name': 'Faroe Islands (298)'},
//             { 'ID': '679','Name': 'Fiji (679)'},
//             { 'ID': '358','Name': 'Finland (358)'},
//             { 'ID': '33','Name': 'France (33)'},
//             { 'ID': '594','Name': 'French Guiana(594)'},
//             { 'ID': '689','Name': 'French Polynesia (689)'},
//             { 'ID': '241','Name': 'Gabon (241)'},
//             { 'ID': '220','Name': 'Gambia (220)'},
//             { 'ID': '49','Name': 'Germany (49)'},
//             { 'ID': '233','Name': 'Ghana (233)'},
//             { 'ID': '350','Name': 'Gibraltar (350)'},
//             { 'ID': '30','Name': 'Greece (30)'},
//             { 'ID': '299','Name': 'Greenland (299)'},
//             { 'ID': '1473','Name': 'Grenada (1473)'},
//             { 'ID': '1784','Name': 'Saint Vincent and the Grenadines (1784)'},
//             { 'ID': '1671','Name': 'Guam (1671)'},
//             { 'ID': '502','Name': 'Guatemala (502)'},
//             { 'ID': '224','Name': 'Guinea (224)'},
//             { 'ID': '245','Name': 'Guinea-Bissau (245)'},
//             { 'ID': '592','Name': 'Guyana (592)'},
//             { 'ID': '509','Name': 'Haiti (509)'},
//             { 'ID': '504','Name': 'Honduras (504)'},
//             { 'ID': '852','Name': 'Hong Kong (852)'},
//             { 'ID': '36','Name': 'Hungary (36)'},
//             { 'ID': '354','Name': 'Iceland (354)'},
//             { 'ID': '91','Name': 'India (91)'},
//             { 'ID': '62','Name': 'Indonesia (62)'},
//             { 'ID': '98','Name': 'Iran (98)'},
//             { 'ID': '964','Name': 'Iraq (964)'},
//             { 'ID': '353','Name': 'Ireland (353)'},
//             { 'ID': '972','Name': 'Israel (972)'},
//             { 'ID': '39','Name': 'Italy (39)'},
//             { 'ID': '225','Name': 'Ivory Coast (225)'},
//             { 'ID': '1876','Name': 'Jamaica (1876)'},
//             { 'ID': '81','Name': 'Japan (81)'},
//             { 'ID': '962','Name': 'Jordan (962)'},
//             { 'ID': '254','Name': 'Kenya (254)'},
//             { 'ID': '686','Name': 'Kiribati (686)'},
//             { 'ID': '850','Name': 'North Korea (850)'},
//             { 'ID': '82','Name': 'South Korea (82)'},
//             { 'ID': '965','Name': 'Kuwait (965)'},
//             { 'ID': '371','Name': 'Latvia (371)'},
//             { 'ID': '961','Name': 'Lebanon (961)'},
//             { 'ID': '266','Name': 'Lesotho (266)'},
//             { 'ID': '231','Name': 'Liberia (231)'},
//             { 'ID': '370','Name': 'Lithuania (370)'},
//             { 'ID': '352','Name': 'Luxembourg (352)'},
//             { 'ID': '853','Name': 'Macau (853)'},
//             { 'ID': '261','Name': 'Madagascar (261)'},
//             { 'ID': '265','Name': 'Malawi (265)'},
//             { 'ID': '60','Name': 'Malaysia (60)'},
//             { 'ID': '960','Name': 'Maldives (960)'},
//             { 'ID': '223','Name': 'Mali (223)'},
//             { 'ID': '356','Name': 'Malta (356)'},
//             { 'ID': '692','Name': 'Marshall Islands (692)'},
//             { 'ID': '596','Name': 'Martinique(596)'},
//             { 'ID': '222','Name': 'Mauritania (222)'},
//             { 'ID': '230','Name': 'Mauritius (230)'},
//             { 'ID': '262','Name': 'Mayotte (262)'},
//             { 'ID': '52','Name': 'Mexico (52)'},
//             { 'ID': '691','Name': 'Micronesia (691)'},
//             { 'ID': '373','Name': 'Moldova (373)'},
//             { 'ID': '377','Name': 'Monaco (377)'},
//             { 'ID': '976','Name': 'Mongolia (976)'},
//             { 'ID': '1664','Name': 'Montserrat (1664)'},
//             { 'ID': '212','Name': 'Morocco (212)'},
//             { 'ID': '258','Name': 'Mozambique (258)'},
//             { 'ID': '95','Name': 'Burma (Myanmar) (95)'},
//             { 'ID': '264','Name': 'Namibia (264)'},
//             { 'ID': '674','Name': 'Nauru (674)'},
//             { 'ID': '977','Name': 'Nepal (977)'},
//             { 'ID': '31','Name': 'Netherlands (31)'},
//             { 'ID': '599','Name': 'Netherlands Antilles (599)'},
//             { 'ID': '687','Name': 'New Caledonia (687)'},
//             { 'ID': '64','Name': 'New Zealand (64)'},
//             { 'ID': '505','Name': 'Nicaragua (505)'},
//             { 'ID': '227','Name': 'Niger (227)'},
//             { 'ID': '234','Name': 'Nigeria (234)'},
//             { 'ID': '683','Name': 'Niue (683)'},
//             { 'ID': '672','Name': 'Norfolk Island(672)'},
//             { 'ID': '1670','Name': 'Northern Mariana Islands (1670)'},
//             { 'ID': '47','Name': 'Norway (47)'},
//             { 'ID': '968','Name': 'Oman (968)'},
//             { 'ID': '92','Name': 'Pakistan (92)'},
//             { 'ID': '680','Name': 'Palau (680)'},
//             { 'ID': '507','Name': 'Panama (507)'},
//             { 'ID': '675','Name': 'Papua New Guinea (675)'},
//             { 'ID': '595','Name': 'Paraguay (595)'},
//             { 'ID': '51','Name': 'Peru (51)'},
//             { 'ID': '63','Name': 'Philippines (63)'},
//             { 'ID': '48','Name': 'Poland (48)'},
//             { 'ID': '351','Name': 'Portugal (351)'},
//             { 'ID': '1','Name': 'United States (1)'},
//             { 'ID': '974','Name': 'Qatar (974)'},
//             { 'ID': '40','Name': 'Romania (40)'},
//             { 'ID': '7','Name': 'Russia (7)'},
//             { 'ID': '250','Name': 'Rwanda (250)'},
//             { 'ID': '1758','Name': 'Saint Lucia (1758)'},
//             { 'ID': '685','Name': 'Samoa (685)'},
//             { 'ID': '378','Name': 'San Marino (378)'},
//             { 'ID': '239','Name': 'Sao Tome and Principe (239)'},
//             { 'ID': '966','Name': 'Saudi Arabia (966)'},
//             { 'ID': '221','Name': 'Senegal (221)'},
//             { 'ID': '248','Name': 'Seychelles (248)'},
//             { 'ID': '232','Name': 'Sierra Leone (232)'},
//             { 'ID': '65','Name': 'Singapore (65)'},
//             { 'ID': '421','Name': 'Slovakia (421)'},
//             { 'ID': '386','Name': 'Slovenia (386)'},
//             { 'ID': '677','Name': 'Solomon Islands (677)'},
//             { 'ID': '252','Name': 'Somalia (252)'},
//             { 'ID': '27','Name': 'South Africa (27)'},
//             { 'ID': '34','Name': 'Spain (34)'},
//             { 'ID': '94','Name': 'Sri Lanka (94)'},
//             { 'ID': '290','Name': 'Saint Helena (290)'},
//             { 'ID': '1869','Name': 'Saint Kitts and Nevis (1869)'},
//             { 'ID': '508','Name': 'Saint Pierre and Miquelon (508)'},
//             { 'ID': '249','Name': 'Sudan (249)'},
//             { 'ID': '597','Name': 'Suriname (597)'},
//             { 'ID': '268','Name': 'Swaziland (268)'},
//             { 'ID': '46','Name': 'Sweden (46)'},
//             { 'ID': '41','Name': 'Switzerland (41)'},
//             { 'ID': '963','Name': 'Syria (963)'},
//             { 'ID': '886','Name': 'Taiwan (886)'},
//             { 'ID': '255','Name': 'Tanzania (255)'},
//             { 'ID': '66','Name': 'Thailand (66)'},
//             { 'ID': '228','Name': 'Togo (228)'},
//             { 'ID': '676','Name': 'Tonga (676)'},
//             { 'ID': '1868','Name': 'Trinidad and Tobago (1868)'},
//             { 'ID': '216','Name': 'Tunisia (216)'},
//             { 'ID': '90','Name': 'Turkey (90)'},
//             { 'ID': '1649','Name': 'Turks and Caicos Islands (1649)'},
//             { 'ID': '688','Name': 'Tuvalu (688)'},
//             { 'ID': '256','Name': 'Uganda (256)'},
//             { 'ID': '380','Name': 'Ukraine (380)'},
//             { 'ID': '971','Name': 'United Arab Emirates (971)'},
//             { 'ID': '44','Name': 'United Kingdom (44)'},
//             { 'ID': '598','Name': 'Uruguay (598)'},
//             { 'ID': '678','Name': 'Vanuatu (678)'},
//             { 'ID': '58','Name': 'Venezuela (58)'},
//             { 'ID': '84','Name': 'Vietnam (84)'},
//             { 'ID': '1 284  ','Name': 'British Virgin Islands (1 284  )'},
//             { 'ID': '1340','Name': 'US Virgin Islands (1340)'},
//             { 'ID': '681','Name': 'Wallis and Futuna (681)'},
//             { 'ID': '967','Name': 'Yemen (967)'},
//             { 'ID': '243','Name': 'Zaire(243)'},
//             { 'ID': '260','Name': 'Zambia (260)'},
//             { 'ID': '263','Name': 'Zimbabwe (263)'},
//             { 'ID': '374','Name': 'Armenia (374)'},
//             { 'ID': '994','Name': 'Azerbaijan (994)'},
//             { 'ID': '375','Name': 'Belarus (375)'},
//             { 'ID': '53','Name': 'Cuba (53)'},
//             { 'ID': '291','Name': 'Eritrea (291)'},
//             { 'ID': '970','Name': 'Gaza Strip (970)'},
//             { 'ID': '995','Name': 'Georgia (995)'},
//             { 'ID': '39','Name': 'Holy See (Vatican City) (39)'},
//             { 'ID': '44','Name': 'Isle of Man (44)'},
//             { 'ID': '7','Name': 'Kazakhstan (7)'},
//             { 'ID': '381','Name': 'Kosovo (381)'},
//             { 'ID': '996','Name': 'Kyrgyzstan (996)'},
//             { 'ID': '856','Name': 'Laos (856)'},
//             { 'ID': '218','Name': 'Libya (218)'},
//             { 'ID': '423','Name': 'Liechtenstein (423)'},
//             { 'ID': '389','Name': 'Macedonia (389)'},
//             { 'ID': '382','Name': 'Montenegro (382)'},
//             { 'ID': '850','Name': 'North Korea (850)'},
//             { 'ID': '870','Name': 'Pitcairn Islands (870)'},
//             { 'ID': '590','Name': 'Saint Barthelemy (590)'},
//             { 'ID': '1599','Name': 'Saint Martin (1599)'},
//             { 'ID': '381','Name': 'Serbia (381)'},
//             { 'ID': '82','Name': 'South Korea (82)'},
//             { 'ID': '992','Name': 'Tajikistan (992)'},
//             { 'ID': '690','Name': 'Tokelau (690)'},
//             { 'ID': '670','Name': 'Timor-Leste (670)'},
//             { 'ID': '993','Name': 'Turkmenistan (993)'},
//             { 'ID': '998','Name': 'Uzbekistan (998)'},
//             { 'ID': '970','Name': 'West Bank (970)'},

//             ];
            export default countryList;