import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCard from './StripeCard';

const PUBLIC_KEY ="pk_test_51JyKjkI5hdiTYSSLx0ovXaZcQZv6IvaSaiGscRey28jMvhrqXOIoC5M7hyaJPVr2TkHQ7loGVlHGd5NrNQcSY3sj005Zkwua1x"
// const PUBLIC_KEY ="pk_live_51JyKjkI5hdiTYSSLQng0FaxXgJ7NsAVxlQmZWRK3ID192qqGRVi67QvapqucnlgHV3jb5gRguAHObjwib3rFAv4X00imcf8PDd"
const stripeTestPromise = loadStripe(PUBLIC_KEY)

export default function StripContainer() {
  return (
    <Elements stripe={stripeTestPromise}>
      <StripeCard />
    </Elements>
  )
}
