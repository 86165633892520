// all custom JS here //
import $ from 'jquery'
$(document).ready(function(){
$(".filter_icon_mobile").click(function(){
		$(".show-component-mobile").addClass("open");
		$("body").addClass("open-model");
		});

	$(".sortingclick").click(function(){
		$(".sorting_dropdown").slideToggle();
		
	});
  $(".modifyswap_icon").click(function(){
	  $(this).toggleClass("exchange");
  });

 // CLEARABLE INPUT
	function tog(v){return v?'addClass':'removeClass';} 
	$(document).on('input', '.input_clear', function(){
		$(this)[tog(this.value)]('x');
	}).on('mousemove', '.x', function( e ){
		$(this)[tog(this.offsetWidth-18 < e.clientX-this.getBoundingClientRect().left)]('onX');
	}).on('touchstart click', '.onX', function( ev ){
		ev.preventDefault();
		$(this).removeClass('x onX').val('').change();
	});
	
	
	// traveller section
	$(".traveller_click").click(function(){
		$(".traveller_block").show();
		$(".search_overlay").show();
		$(this).addClass("activefield");		
			$("html, body").animate({            
        }, "2000", "swing")
	});
	
	$(".done_button, .searchwindowclose").click(function(){
		$(".traveller_block").hide();
		$(".search_overlay").hide();
		$(".traveller_click").removeClass("activefield");
	});
	
	
	
	$(".filter_close_mobile, .mobile_apply_filter").click(function(){
		if(!$(this).hasClass("disable")){
		$(".show-component-mobile").removeClass("open");
		$("body").removeClass("open-model");
		}
		});
	
	$(".modifySearchInfoBox").click(function(){
		 $(".modifySearchBox").addClass("open");
		$(".resultsbg").addClass("open-model");
	 });
	
	$(".modify_close_mobile").click(function(){
		 $(".modifySearchBox").removeClass("open");
		$(".resultsbg").removeClass("open-model");

	 });
	


	
	// From desktop section
	$(".from_input,.dfrom_input").click(function(){
		$(".traveller_block").hide();
		$(".search_overlay").show();
		$(this).addClass("activefield");
		$("#fromCity").select();
	});
	//
		//
	$(".to_input,.dto_input").click(function(){
		$(".traveller_block").hide();
		$(".search_overlay").show();
		$(this).addClass("activefield");
		$("#toCity").select();
	});
	//
	
	$(".from_inputmob").click(function(){
		$("#from_suggestmob").show();
	});
	//
		//
	$(".to_input").click(function(){
		$("#to_suggest").show();
		$(".search_overlay").show();
		$(this).addClass("activefield");
	});
	//
	
	
	if($(window).width() < 768){
	$(".from_input").click(function(){
		$("#flyfrom").show();
		$("#fromCityMob").focus();
		if($("#fromCityMob").val() !== ''){
		$("#fromCityMob").select();
		}
		
	});
	
	$(".to_input").click(function(){
		$("#flyfrom").show();
		$("#toCityMob").focus();
		if($("#toCityMob").val() !== ''){
		$("#toCityMob").select();
		}
	});
	
	$(".dates").click(function(){
	
		$("#mobileCal").show();
		$('#ui-datepicker-div').insertAfter('.date_cal');
		
	});
	
	$(".searchwindowclose").click(function(){
		if($("#fromCityMob").val() === ''){
			$("#fromCity").val("");
		}
		if($("#toCityMob").val() === ''){
			$("#toCity").val("");
		}
		$("#flyfrom, #flyto, #mobileCal, .traveller_block, .search_overlay").hide();
		
	});
	
	$("#mobileDateLabel").click(function(event){
			//$("#mobileCal").hide();
			if($("#datepickerMob").hasClass("active")){
				
				$("#datepickerMob").datepicker('show');
				
			}
			else if($("#datepickerMob2").hasClass("active")){
				$("#datepickerMob2").datepicker('show');
			}
		
	});
	
	}
	
	//New end
	
	//
	$(document).on('click touch', function(event) {
        if (!$(event.target).parents().addBack().is('.traveller_click, .from_input, .to_input, .dfrom_input, .dto_input, .dates, .from_inputmob,.sortingclick,#mobCalDiv')) {
            $('.traveller_block').hide();
			$(".search_overlay").hide();
			
			$(".dateoverlay").hide();
			$(".traveller_click, .from_input,.dfrom_input, .to_input,.dto_input, .dates, .from_inputmob").removeClass("activefield");
			$("#from_suggest").hide();
			$("#from_suggestmob").hide();
			$("#to_suggest").hide();
			$(".calendar_div").hide();	
			$(".sorting_dropdown").hide();
        }
    });
		 $('.traveller_block').on('click touch', function(event) {
        event.stopPropagation();
    });
	//
	
	});//Document close
	
	

 
