/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback} from 'react';
import { Link, useLocation } from 'react-router-dom';
import SITE_CONFIG from '../SiteController/SiteController';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import '../resources/css/bootstrap.min.css';
import '../resources/css/jquery-ui.css';
import '../resources/css/search_engine-result.css';
import '../resources/css/search_engine.css'
import '../resources/css/jquery-ui.css';
import '../resources/css/fontawesome.all.min.css';
import '../resources/images/favicon.png';
import '../resources/font/bootstrap-icons.css';
import '../resources/css/mobilefilter.css';
import '../resources/css/banner.css';
import closeBtn from'../resources/images/banner/close.png';
import popupBanner from '../resources/images/banner/popup.jpg';
import roundPopup from '../resources/images/banner/round-trip.png';
import phone3 from '../resources/images/banner/phone3.gif'
//import '../resources/jc/owl.carousel.min.css';
//import '../resources/js/jquery.meanmenu.js';
import '../resources/font/bootstrap-icons.css';
import '../resources/js/custom.js';
//import '../resources/js/jquery.js';
import '../resources/css/results-style.css';
import '../resources/css/slick_matrix.css?v231';
import '../resources/js/blurEffect';
import Footer2 from '../components/Footer/footer2'
import calandar from '../resources/images/icons/calendar.svg'
import RoundIcon from '../resources/images/icons/roundtrip_icon.png';
import swap from "../resources/images/icons/swap.png";
import filters from '../resources/images/filters.png';
import FiltersMenu from '../resources/images/filters-menu2.png';
import Loader from '../resources/loader/loader.gif'
//import AA from '../resources/images/airline-logo/AA.png';
//import IB from '../resources/images/airline-logo/IB.png';
import carryone from '../resources/images/icons/carryon_bag.png';
import bag from '../resources/images/icons/bag.png';
import user from '../resources/images/icons/user-black.svg';
import sessionExpire from '../resources/images/icons/Session-Expire.png'
import callimgage from '../resources/images/banner/call-ani.webp'
//import transport from "../resources/images/transport.png";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import airlinesName from '../controller/Controller';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import ModifySearch from '../Shared/ModifySearch';
import NoResult from '../resources/images/no-result.png'
import MetaTitle from './metaTitle.jsx';
//import WorkingWatch from '../AddOn/Timer.jsx';

function formatDateAndTime(isoDate) {
    const dateOptions = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    };
  
    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
  
    const formattedDate = new Date(isoDate);
    const formattedDateString = new Intl.DateTimeFormat('en-US', dateOptions).format(formattedDate);
    const formattedTimeString = new Intl.DateTimeFormat('en-US', timeOptions).format(formattedDate);
  
    return { formattedDateString, formattedTimeString };
  }
  
  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} hrs ${remainingMinutes} min`;
  }

  
  function openCity(evt, cityName) {
    const tabcontents = document.getElementsByClassName('tabcontent1');
    for (let i = 0; i < tabcontents.length; i++) {
      tabcontents[i].style.display = 'none';
    }
  
    const tablinks = document.getElementsByClassName('tablinks');
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }
  
    const cityElement = document.getElementById(cityName);
    if (cityElement) {
      cityElement.style.display = 'block';
    }
  
    evt.currentTarget.className += ' active';
  }

  
export default function FlightList() {
  
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  const location = useLocation();
  const { responseData, formData } = location.state || {};
  const urlSearchID = new URLSearchParams(location.search).get('searchID');
  ////console.log(formData)
    //const responseData = location.state?.data;
    const flightResult = useMemo(() => responseData?.flightResult || [], [responseData]);
    //console.log(responseData)
    const airline = responseData?.airline || [];
    const noOfResults = responseData?.noOfResult || 0;
    const airport = responseData?.airport || [];
    const baggageInformation = responseData?.baggageInformation || [];
    const[isSearchVisible, setSearchVisible] = useState(false)
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [flightDetailsVisible, setFlightDetailsVisible] = useState(true);
    const [fareFamily, setFareFamily] = useState(false);
    //const [currentPage, setCurrentPage] = useState(1);
    const [openResultID, setOpenResultID] = useState(null);
    const [openToolTip, setOpenToolTip] = useState(null);
    //const [resultStates, setResultStates] = useState({});
    //const [cards, setCards] = useState([]);
    //const [selectedAirline, setSelectedAirline] = useState(null);
    
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showAllAirport, setShowAllAirport] = useState(false);
  const [showAllAirlines, setShowAllAirlines] = useState(false);
  const [stopFilter, setStopFilter] = useState(null);
  
  const itemsPerPage = 20;
  const matrixResultFrame = 5;
  const [matrixFrame, setMatrixFrame] = useState(null);
  

    const [selectedAirline, setSelectedAirline] = useState(null);
    
    const [selectedLayOver, setSelectedLayOver] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const minPrice = responseData?.minPrice;
    const maxPrice = responseData?.maxPrice;
    const [priceRange, setPriceRange] = useState([minPrice, maxPrice]);
    const [sliderDurationValue, setSliderDurationValue] = useState(720); // Default slider Duration value in minutes
    const [sliderConnectionValue, setSliderConnectionValue] = useState(720); // Default slider Connection value in minutes
    const [selectedMatrixAirline, setSelectedMatrixAirline] = useState(null);
    const [selectedMatrixFlightType, setSelectedMatrixFlightType] = useState(null);
  const [selectedMatrixPriceRange, setSelectedMatrixPriceRange] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [marginTop, setMarginTop] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  // useEffect(() => {
  //   // Initialize with all results
  //   //setFilteredData(flightResult);
  //   const newTotalPages = Math.ceil(flightResult.length / itemsPerPage);
  // setTotalPages(newTotalPages);

  // const startIdx = (currentPage - 1) * itemsPerPage;
  // const visibleItems = flightResult.slice(startIdx, startIdx + itemsPerPage);
  // setFilteredData(visibleItems);
  // }, []);
  // useEffect(() => {
  //   // Initialize with all results
  //   setFilteredData(flightResult);
  //   const newTotalPages = Math.ceil(flightResult.length / itemsPerPage);
  //   setTotalPages(newTotalPages);
  
  //   const startIdx = 0; // Start with the first page
  //   const visibleItems = flightResult.slice(startIdx, startIdx + itemsPerPage);
  //   setFilteredData(newTotalPages);
  // }, [flightResult]);
  
  
  
  //For Box Hide And Show
//   const [tripType, setTripType] = useState(true);
// const [isActive, setIsActive] = useState(false);
// const [selectTripType, setSelectTripType] = useState(2);
// const [selectedOriginOption, setSelectedOriginOption] = useState(null);
// const [destinationAirportModify, setDestinationAirportModify] = useState("");
// const [responseDataForSearchModify, setResponseDataForSearchModify] = useState(null);
// const [originAirportModify, setOriginAirportModify] = useState("");
// const [APIData, setAPIData] = useState([]);
// const [filteredOriginResults, setFilteredOriginResults] = useState([]);
//   const [filteredDestinationResults, setFilteredDestinationResults] = useState([]);
//   const [isVisible, setIsVisible] = useState(true);
//   const [isVisibledes, setIsVisibledes] = useState(true);
//   const [selectedDestinationOption, setSelectedDestinationOption] = useState(null);
//   const [selectedOneDate, setSelectedOneDate] = useState(new Date());
//   const [calendarVisible, setCalendarVisible] = useState(false);
//   const today = new Date();
//   const [selectedStartDate, setSelectedStartDate] = useState(today);
//   const [selectedEndDate, setSelectedEndDate] = useState(today);
//   const [adultModify, setAdultModify] = useState(1);
//   const [childModify, setChildModify] = useState(0);
//   const [infantModify, setInfantModify] = useState(0);
//   const [infantWsModify, setInfantWsModify] = useState(0);
//   const [errorModify, setErrorModify] = useState("");
//   const [travellerBlockVisible, setTravellerBlockVisible] = useState(false);
//   const [activeFieldModify, setActiveFieldModify] = useState(false);
//   const [totalTravellerModify, setTotalTravellerModify] = useState("Travellor(s)");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [responseDataPayment, setResponseDataPayment] = useState(null);
  const [selectedDepTimeFilter, setSelectedDepTimeFilter] = useState(null);
  const [selectedRetTimeFilter, setSelectedRetTimeFilter] = useState(null);
  // const [fareRules, setFareRules] = useState({
  //   adults: formData.adults,
  //   infant: formData.infants,
  //   infantsWs: formData.infantsWs,
  //   child: formData.child,
  //   siteID: 6,
  //   currencyCode: formData.currencyCode,
  //   flightResult: null, // Initialize flightResult to null
  //   tripadd: true
  // });
  const navigate = useNavigate();

  useEffect(() => {
    setSearchVisible(false); // Setting isSearchVisible to false when the component mounts
  }, [flightResult]);

  const handleFlightSelect = async (flight) => {
    
  //   document.body.style.overflowY = 'hidden';
  //     const homeBody = document.querySelector('#wrapper2');
  //   if (homeBody) {
  //   const elementsToBlur = homeBody.querySelectorAll(':not(.loader-overlay)');
  //   elementsToBlur.forEach((element) => {
  //     element.style.filter = 'blur(8px)';
  //   });
  // }
    const selectedResultID = flight.resultId; // Assuming the result ID is stored in 'resultId'
    setSelectedFlight(selectedResultID);
    //alert("Form Submitted")
    const selectedFlightResult = flightResult.find(
      (flightItem) => flightItem.resultId === selectedResultID
    );

    // Update the fareRules state with the selectedFlightResult
    // setFareRules((prevFareRules) => ({
    //   ...prevFareRules,
    //   flightResult: selectedFlightResult
    // }));
    // //console.log("After state update:", fareRules);

    const paymentState = { flightResult, airport, formData, baggageInformation };
    navigate(`/payment/${formData.searchID}/${selectedResultID}`, { state: paymentState });
    //navigate(`/payment/${urlSearchID}/${selectedResultID}`, { state: paymentState });
    // Navigate to the PaymentPage with the selectedResultID as a parameter
    //navigate(`/payment/${selectedResultID}`, { state: paymentState });
    // try {
    //   const response = await axios.post(
    //     `${apiIP}:8181/api/tripadd`,{
    //       flightResult: selectedFlightResult,
    //       adults: formData.adults,
    //       child: formData.child,
    //       infant: formData.infants,
    //       infantsWs: formData.infantsWs,
    //       currencyCode: formData.currencyCode,
    //       siteID: siteID,
          
    //        // Initialize flightResult to null
    //       tripadd: true // Send the requestData object as the request data
    //       }
    //   );
    //   ////console.log(response);

    //   if (response.status === 200) {
    //     setResponseDataPayment(selectedFlight); // Update the state with the response data
    //     ////console.log(responseDataPayment);
    //     navigate(`/payment/${formData.searchID}/${selectedResultID}`, { state: paymentState });
    //   } else {
    //     navigate(`/payment?searchID=${formData.searchID}/${selectedResultID}`, { state: paymentState });
    //     ////console.error("Request failed with status:", response.status);
    //   }
    // } catch (error) {
    //   ////console.error("Error making POST request:", error);
    // }
    //setResponseDataPayment(selectedFlight);
    
    ////console.log('paymentState in Payment.jsx:', paymentState);
    ////console.log("Jai Shree Ram", selectedFlightResult);
  };
  
  
  useEffect(() => {
    const handleScroll = () => {
      // Determine how much to offset the margin-top based on the scroll position
      const scrollY = window.scrollY;

      // You can adjust this value to control the offset as per your requirement
      // Set the margin-top to -166px when scrolling down
      const newMarginTop = scrollY > 0 ? -125 : 0;

      // Update the marginTop state
      setMarginTop(newMarginTop);
    };

    // Add a scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  //const navigate = useNavigate();
  ////console.log(formData?.searchID, 'error')
    ////console.log(airport[0].airportName);

  const handleChange = useCallback((value) => {
   // setCurrentPage(value);
   setCurrentPage(value);
  }, [setCurrentPage]);
  
const toggleCollapse = (resultIdToClose) => {
    if (resultIdToClose !== openResultID) {
      setFlightDetailsVisible(true);
    //   setOpenResultID(resultIdToClose); // Open the clicked component
    setOpenResultID(prevOpenResultID => resultIdToClose);
    } else {
      setFlightDetailsVisible(false); // Toggle visibility of the detailed section
    }
    setFareFamily(false);
  };
const toggleClose = () => {
    setFlightDetailsVisible(false);
    setFareFamily(false);
}  


  const toggleFareFamily = (resultIdToClose) => {
    if(resultIdToClose === openResultID){
        setFareFamily(!fareFamily);
    } else {
        setFareFamily(true);
    }
    //setFareFamily(!fareFamily);
    setFlightDetailsVisible(false);
  }

    const toggleSearch = () => {
        setSearchVisible(!isSearchVisible);
    }

    const toggleFilter = () => {
        setIsFilterVisible(!isFilterVisible);
      };

    
    
    useEffect(() => {
      
      // Initialize the filtered data with the flightResult on component mount
      setFilteredData(flightResult);
      ////console.log('Jai Shree Ram');
    }, [flightResult]);
    // if (!flightResult || !flightResult.outBound || flightResult.outBound.length === 0) {
    //     return <div>No flight data available</div>;
    //   }
    //const { formattedDateString, formattedTimeString } = formatDateAndTime(flightResult.outBound[0].depDate);

  // Function to handle airline filter
  
  // const handleAirlineFilter = useCallback((airline) => {
  //   setSelectedAirline(airline);
  //   const filteredResultsForAirline = flightResult.filter((item) => item.airline === airline);
  //   setTotalPages((prevTotalPages) => ({
  //     ...prevTotalPages,
  //     currentPage: 1,
  //   }));
  //   setFilteredData(filteredResultsForAirline);
  // }, [setSelectedAirline, flightResult]);

  const handleAirlineFilter = (airline) => {
    setSelectedAirline(airline);
    
    if (airline === null) {
      // Clear the filter and show all results
      
      const newTotalPages = Math.ceil(flightResult.length / itemsPerPage);
    setTotalPages(newTotalPages);

    const startIdx = (currentPage - 1) * itemsPerPage;
    const visibleItems = flightResult.slice(startIdx, startIdx + itemsPerPage);
    setFilteredData(visibleItems);
    } else {
      // Filter results based on the selected airline
      const filteredResultsForAirline = flightResult.filter((result) => result.airline === airline);
      //setFilteredData(filteredResultsForAirline);
      const newTotalPages = Math.ceil(filteredResultsForAirline.length / itemsPerPage);
    setTotalPages(newTotalPages);

    const startIdx = (currentPage - 1) * itemsPerPage;
    const visibleItems = filteredResultsForAirline.slice(startIdx, startIdx + itemsPerPage);
    setFilteredData(visibleItems);
    }
  };
  
 

  
  

  

  



  // Function to reset the filter
  const resetFilter = () => {
    //setCurrentPage(1);
    setSelectedAirlineForFilter(itemsToShowAirline);
    setSelectedMatrixFlightType(null);
    setSelectedMatrixAirline(null);
    setSelectedMatrixPriceRange(null);
    setSliderDurationValue(720);
    setSliderConnectionValue(720);
    setStopFilter(null);
    setSelectedAirline(null);
    setSelectedLayOver('');
    setPriceRange([minPrice, maxPrice]); // Reset price range to its default
    setCurrentPage(1);
    setFilteredData(flightResult);
    setSelectedDepTimeFilter(null);
    ////console.log('reachTime')
  };
  // useEffect(() => {
  //   ////console.log('Filtering...');
  //   const filteredResult =
  //     Array.isArray(flightResult) && flightResult.length > 0
  //       ? flightResult.filter((item) => item.fare.baseFare >= priceRange[0] && item.fare.baseFare <= priceRange[1])
  //       : [];

  //   // Update total pages based on the filtered result count
  // const newTotalPages = Math.ceil(filteredResult.length / itemsPerPage);
  // setTotalPages(newTotalPages);

  // // Paginate the filtered results
  // const startIdx = (currentPage - 1) * itemsPerPage;
  // const visibleItems = filteredResult.slice(startIdx, startIdx + itemsPerPage);

  //   ////console.log('Filtered result:', filteredResult);
  //   setFilteredData(visibleItems);
  // }, [flightResult, priceRange, currentPage]);
  
  

  

  
  
  const oneStopAirports = flightResult
    .filter(result => result.outBound[1]?.fromAirport)
    .map(result => result.outBound[1]?.fromAirport);
  // useEffect(() => {
  //   const filteredResults = flightResult.filter(result => {
  //     if (stopFilter === null) {
  //       return true; // No filters, show all results
  //     }
      
  //     if (stopFilter === 'oneStop') {
  //       return oneStopAirports.includes(result.outBound[1]?.fromAirport);
  //     } else if (stopFilter === 'nonStop') {
  //       return !result.outBound[1]?.fromAirport;
  //     }
      
  //     return stopFilter === result.outBound[1]?.fromAirport;
  //   });
  
  //   const newTotalPages = Math.ceil(filteredResults.length / itemsPerPage);
  //   setTotalPages(newTotalPages);
  
  //   const startIdx = (currentPage - 1) * itemsPerPage;
  //   const visibleItems = filteredResults.slice(startIdx, startIdx + itemsPerPage);
  
  //   setFilteredData(visibleItems);
  //   //console.log('unClicked')
  // }, []);
  useEffect(() => {
    if (isFilterApplied) {
    const filteredResults = filteredData.filter(result => {
      if (stopFilter === null) {
        return true; // No filters, show all results
      }
      
      if (stopFilter === 'oneStop') {
        return oneStopAirports.includes(result.outBound[1]?.fromAirport);
      } else if (stopFilter === 'nonStop') {
        return !result.outBound[1]?.fromAirport;
      }
      
      return stopFilter === result.outBound[1]?.fromAirport;
    });
    const newTotalPages = Math.ceil(filteredResults.length / itemsPerPage);
    setTotalPages(newTotalPages);
  
    const startIdx = (currentPage - 1) * itemsPerPage;
    const visibleItems = filteredResults.slice(startIdx, startIdx + itemsPerPage);
  
    setFilteredData(visibleItems);
  } else {
    const filteredResults = flightResult.filter(result => {
      if (stopFilter === null) {
        return true; // No filters, show all results
      }
      
      if (stopFilter === 'oneStop') {
        return oneStopAirports.includes(result.outBound[1]?.fromAirport);
      } else if (stopFilter === 'nonStop') {
        return !result.outBound[1]?.fromAirport;
      }
      
      return stopFilter === result.outBound[1]?.fromAirport;
    });
    const newTotalPages = Math.ceil(filteredResults.length / itemsPerPage);
    setTotalPages(newTotalPages);
  
    const startIdx = (currentPage - 1) * itemsPerPage;
    const visibleItems = filteredResults.slice(startIdx, startIdx + itemsPerPage);
  
    setFilteredData(visibleItems);
  }
  
    setIsFilterApplied(true);
    ////console.log('Filter updated');
  }, [stopFilter]);

  const handleStopFilter = (selectedValue) => {
    setCurrentPage(1);
    if (stopFilter === selectedValue) {
      // Uncheck the checkbox and clear the filter
      setStopFilter(null);
      setFilteredData(flightResult); // Reset filtered data to original
      ////console.log('unClicked')
    } else {
      setStopFilter(selectedValue);
    }
  };
  const [nonStopFilter, setNonStopFilter] = useState(false);
  const [oneStopFilter, setOneStopFilter] = useState(false);

  const handleNonStopFilter = () => {
    setCurrentPage(1);
    if (nonStopFilter === null) {
      // Check the checkbox and set the filter value
      setNonStopFilter(true);
    } else {
      // Uncheck the checkbox and clear the filter
      setNonStopFilter(null);
    }
  };
  
  const handleOneStopFilter = () => {
    setCurrentPage(1);
    if (oneStopFilter === null) {
      // Check the checkbox and set the filter value
      setOneStopFilter(true);
    } else {
      // Uncheck the checkbox and clear the filter
      setOneStopFilter(null);
    }
  };
  
  useEffect(() => {
    setCurrentPage(1);
    const filteredResults = flightResult.filter(result => {
      if (nonStopFilter && oneStopFilter) {
        return true; // No filters, show all results
      }
  
      if (nonStopFilter) {
        return !result.outBound[1]?.fromAirport;
      } else if (oneStopFilter) {
        return oneStopAirports.includes(result.outBound[1]?.fromAirport);
      }
  
      return true; // Return true for other cases to maintain the unchanged data
    });
  
    const newTotalPages = Math.ceil(filteredResults.length / itemsPerPage);
    setTotalPages(newTotalPages);
  
    const startIdx = (currentPage - 1) * itemsPerPage;
    const visibleItems = filteredResults.slice(startIdx, startIdx + itemsPerPage);
  
    setFilteredData(visibleItems);
    setIsFilterApplied(true);
  }, [nonStopFilter, oneStopFilter]);
  

  const handleDepTimeFilterClick = useCallback((startTime, endTime) => {
    setSelectedDepTimeFilter({ startTime, endTime });
    
    const filteredDepTime = flightResult.filter(flight => {
      const departureTime = formatDateAndTime(flight.outBound[0]?.depDate).formattedTimeString;
      const hours = parseInt(departureTime.split(':')[0]);
      const isPM = departureTime.includes('PM');
      let adjustedHours = hours;
  
      if (isPM && hours < 12) {
        adjustedHours += 12;
      }
  
      return adjustedHours >= startTime && adjustedHours < endTime;
    });
  
    const newTotalPages = Math.ceil(filteredDepTime.length / itemsPerPage);
    setTotalPages(newTotalPages);
    if (filteredDepTime.length === 0) {
      // Display a message when no data is available for the selected filter
      setFilteredData([{ noDataAvailable: true }]);
    } else {
    const startIdx = (currentPage - 1) * itemsPerPage;
    const visibleItems = filteredDepTime.slice(startIdx, startIdx + itemsPerPage);
  
    setFilteredData(visibleItems);
    setIsFilterApplied(true);
    }
  }, [itemsPerPage, currentPage, flightResult]);
  

//   const handleDepTimeFilterClick = useCallback((startTime, endTime) => {
//   //   if (selectedDepTimeFilter && selectedDepTimeFilter.startTime === startTime && selectedDepTimeFilter.endTime === endTime) {
//   //     // Ignore the click and return early
//   //     return;
//   //   }

//      setSelectedDepTimeFilter({startTime, endTime})
//   //   //let filteredDepTime
//   //   if (isFilterApplied) {
//   //   const filteredDepTime = filteredData.filter(flight => {
//   //     const departureTime = formatDateAndTime(flight.outBound[0]?.depDate).formattedTimeString;
//   //     const hours = parseInt(departureTime.split(':')[0]);
//   //     const isPM = departureTime.includes('PM');

//   //     let adjustedHours = hours; // Create a new variable to store the adjusted hour value

//   //     if (isPM && hours < 12) {
//   //       adjustedHours += 12; // Convert PM time to 24-hour format
//   //     }

//   //     return adjustedHours >= startTime && adjustedHours < endTime;
//   //   });
//   //   const newTotalPages = Math.ceil(filteredDepTime.length / itemsPerPage);
//   //   setTotalPages(newTotalPages);
  
//   //   const startIdx = (currentPage - 1) * itemsPerPage;
//   //   const visibleItems = filteredDepTime.slice(startIdx, startIdx + itemsPerPage);
  
//   //   setFilteredData(visibleItems);
//   // } else {
//     const filteredDepTime = flightResult.filter(flight => {
//       const departureTime = formatDateAndTime(flight.outBound[0]?.depDate).formattedTimeString;
//       const hours = parseInt(departureTime.split(':')[0]);
//       const isPM = departureTime.includes('PM');

//       let adjustedHours = hours; // Create a new variable to store the adjusted hour value

//       if (isPM && hours < 12) {
//         adjustedHours += 12; // Convert PM time to 24-hour format
//       }

//       return adjustedHours >= startTime && adjustedHours < endTime;
//     });
//     const newTotalPages = Math.ceil(filteredDepTime.length / itemsPerPage);
//     setTotalPages(newTotalPages);
  
//     const startIdx = (currentPage - 1) * itemsPerPage;
//     const visibleItems = filteredDepTime.slice(startIdx, startIdx + itemsPerPage);
  
//     setFilteredData(visibleItems);
//     setIsFilterApplied(true);
// //}

//     // Apply pagination to the filtered results
    
    
//     ////console.log('depTime', filteredData);
//     ////console.log('unClicked')
//   }, [filteredData, itemsPerPage, currentPage, flightResult]);

const handleReachTimeFilterClick = (startTime, endTime) => {
  setSelectedRetTimeFilter({ startTime, endTime });

  const filteredReachTime = flightResult.filter(flight => {
    const departureTime = formatDateAndTime(flight.inBound[0]?.depDate).formattedTimeString;
    const hours = parseInt(departureTime.split(':')[0]);
    const isPM = departureTime.includes('PM');
    let adjustedHours = hours;

    if (isPM && hours < 12) {
      adjustedHours += 12;
    }

    return adjustedHours >= startTime && adjustedHours < endTime;
  });

  const newTotalPages = Math.ceil(filteredReachTime.length / itemsPerPage);
  setTotalPages(newTotalPages);
  if (filteredReachTime.length === 0) {
    setFilteredData([{ noDataAvailable: true }]);
  } else {
  const startIdx = (currentPage - 1) * itemsPerPage;
  const visibleItems = filteredReachTime.slice(startIdx, startIdx + itemsPerPage);

  setFilteredData(visibleItems);
  setIsFilterApplied(true);
  }
};


//   const handleReachTimeFilterClick = (startTime, endTime) => {
//     setSelectedRetTimeFilter({startTime, endTime});
//     //let filteredReachTime;
// //     if (isFilterApplied) {
// //     const filteredReachTime = filteredData.filter(flight => {
// //       const departureTime = formatDateAndTime(flight.inBound[0].depDate).formattedTimeString;
// //       const hours = parseInt(departureTime.split(':')[0]);
// //       const isPM = departureTime.includes('PM');

// //       let adjustedHours = hours; // Create a new variable to store the adjusted hour value

// //       if (isPM && hours < 12) {
// //         adjustedHours += 12; // Convert PM time to 24-hour format
// //       }

// //       return adjustedHours >= startTime && adjustedHours < endTime;
// //     });
// //     const newTotalPages = Math.ceil(filteredReachTime.length / itemsPerPage);
// // setTotalPages(newTotalPages);

// // const startIdx = (currentPage - 1) * itemsPerPage;
// // const visibleItems = filteredReachTime.slice(startIdx, startIdx + itemsPerPage);

// // setFilteredData(visibleItems);
// //   } else {
//     const filteredReachTime = flightResult.filter(flight => {
//       const departureTime = formatDateAndTime(flight.inBound[0].depDate).formattedTimeString;
//       const hours = parseInt(departureTime.split(':')[0]);
//       const isPM = departureTime.includes('PM');

//       let adjustedHours = hours; // Create a new variable to store the adjusted hour value

//       if (isPM && hours < 12) {
//         adjustedHours += 12; // Convert PM time to 24-hour format
//       }

//       return adjustedHours >= startTime && adjustedHours < endTime;
//   });
//   const newTotalPages = Math.ceil(filteredReachTime.length / itemsPerPage);
// setTotalPages(newTotalPages);

// const startIdx = (currentPage - 1) * itemsPerPage;
// const visibleItems = filteredReachTime.slice(startIdx, startIdx + itemsPerPage);

// setFilteredData(visibleItems);
// //}
// // Apply pagination to the filtered results

// setIsFilterApplied(true);
// ////console.log('reachTime')
// };

  

  

  

  

  

  // const handleMouseOver = (toolIdToClose) => {
  //   if (toolIdToClose !== openToolTip) {
  //     setTooltipVisible(true);
  //   //   setOpenResultID(toolIdToClose); // Open the clicked component
  //   //setOpenToolTip(prevOpenToolTip => toolIdToClose);
  //   } else {
  //     setTooltipVisible(false); // Toggle visibility of the detailed section
  //   }
  //   //setTooltipVisible(true);
  // };

  const [hoveredResultId, setHoveredResultId] = useState(null);

const handleMouseOver = (resultId) => {
  setHoveredResultId(resultId); // Set the hovered result ID
  setTooltipVisible(true); // Show the tooltip
};

const handleMouseOut = () => {
  setHoveredResultId(null); // Reset the hovered result ID
  setTooltipVisible(false); // Hide the tooltip
};
  //const itemsPerPage = 10;
  
 


  //const mergedData = { ...airlinesName, ...filteredData };
  const airlineNames = [];

  for (const key in filteredData) {
    if (filteredData.hasOwnProperty(key)) {
      airlineNames.push(filteredData[key]);
    }
  }
  const airlines = [...new Set(flightResult.map(flight => flight.airline))];
  const totalMatrixFrame = Math.ceil(airlines.length / matrixResultFrame);
  
    const startIndexMatrix = matrixFrame * matrixResultFrame;
    const endIndexMatrix = startIndexMatrix + matrixResultFrame;

    // Slice the airlines array to get the results for the current page
    const displayedAirlines = airlines.slice(startIndexMatrix, endIndexMatrix);

    const handleLeftArrowClick = () => {
      if (matrixFrame > 0) {
        setMatrixFrame(matrixFrame - 1);
      }
    };
  
    // Function to handle right arrow button click
    const handleRightArrowClick = () => {
      if (matrixFrame < totalMatrixFrame - 1) {
        setMatrixFrame(matrixFrame + 1);
      }
    };

    // Return JSX to display the results
   
 
  //const layOver = [...new Set(airport.map(flight => flight.layover))]
//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//     updateFilteredData(newPage);
//   };

//   const updateFilteredData = (page) => {
//     const lastIndex = page * itemsPerPage;
//     const firstIndex = lastIndex - itemsPerPage;
//     setFilteredData(flightResult.slice(firstIndex, lastIndex));
//   };
  
  const itemsToShow = showAllAirport ? airport : airport.slice(0, 5);
  const itemsToShowAirline = showAllAirlines ? airlines : airlines.slice(0, 5);
  const [selectedAirlineForFilter, setSelectedAirlineForFilter] = useState(itemsToShowAirline);
  const handleAirlineFilterForFilter = (airline) => {
    let updatedSelectedAirlines;

    if (airline === null) {
      // All Airlines checkbox is checked, show all airlines' data
      updatedSelectedAirlines = null;
    } else {
      // Individual airline checkbox is clicked
      if (!selectedAirlineForFilter || !selectedAirlineForFilter.includes(airline)) {
        // If the clicked airline is not selected, add it to selection
        updatedSelectedAirlines = selectedAirlineForFilter ? [...selectedAirlineForFilter, airline] : [airline];
      } else {
        // If the clicked airline is already selected, remove it from selection
        updatedSelectedAirlines = selectedAirlineForFilter.filter(item => item !== airline);
      }
    }

    // Update All Airlines checkbox state
    const allAirlinesChecked = updatedSelectedAirlines === null || updatedSelectedAirlines.length === itemsToShowAirline.length;

    // Filter results based on the selected airline(s)
    const filteredResultsForAirlines = flightResult.filter(result =>
      !updatedSelectedAirlines || updatedSelectedAirlines.includes(result.airline)
    );

    const newTotalPages = Math.ceil(filteredResultsForAirlines.length / itemsPerPage);
    setTotalPages(newTotalPages);

    const startIdx = (currentPage - 1) * itemsPerPage;
    const visibleItems = filteredResultsForAirlines.slice(startIdx, startIdx + itemsPerPage);
    setFilteredData(visibleItems);

    setSelectedAirlineForFilter(updatedSelectedAirlines);
  };

  const toggleShowAllAirport = () => {
    setShowAllAirport(prevShowAll => !prevShowAll);
  };

  const toggleShowAllAirlines = () => {
    setShowAllAirlines(prevShowAll => !prevShowAll);
  };

  

  // Effect to update filtered results whenever priceRange changes
  
  ////console.log('flightResult:', flightResult);




  
  
  
  


    


      
      
    
      


      
    
      

      const getCabinTypeLabel = (cabinTypeValue) => {
        switch (cabinTypeValue) {
          case "1":
            return "Economy";
          case "2":
            return "Business";
          case "3":
            return "Premium Economy";
          case "4":
            return "First";
          default:
            return "Unknown";
        }
      };

      const getAirportInfo = (originAirportCode, airportData) => {
        const matchingAirport = airportData.find(
          (airport) => airport.airportCode === originAirportCode
        );
      
        if (matchingAirport) {
          return `${matchingAirport.airportCode} - \n${matchingAirport.airportName}`;
        } else {
          return '';
        }
      };

      const getCityInfo = (originAirportCode, airportData) => {
        const matchingAirport = airportData.find(
          (airport) => airport.airportCode === originAirportCode
        );
    
        if (matchingAirport) {
          return `${matchingAirport.cityName}(${matchingAirport.countryName})`;
        } else {
          return '';
        }
      };
      ////console.log(responseData.airport.cityName)

      const totalPassengers = parseInt(formData.adults) + parseInt(formData.child) + parseInt(formData.infants) + parseInt(formData.infantsWs);
      // //console.log('adults:', formData.adults);
      // //console.log('child:', formData.child);
      // //console.log('infants:', formData.infants);
      // //console.log('infantsWs:', formData.infantsWs);
//       const getOutBoundCityInfo = () => {
// //         const cityName = flight.outBound[0].fromAirport === airport.airportCode
// //     ? airport.cityName
// //     : '';

// //   return (
// //     <div>
// //       Flight Information:
// //       <p>City Name: {cityName}</p>
// //     </div>
// //   );
// const fromAirportCode = flightResult.outBound[0].fromAirport;
//         const matchingAirport = airport.find(
//             (airport) => airport.airportCode === fromAirportCode
//           );
//       };

// const getOutBoundCityInfo = () => {
//     const fromAirportCode = flightResult.outBound[0]?.fromAirport;
  
//     return airport.find(
//       (airport) => airport.airportCode === fromAirportCode
//     );
//   };
//   const matchingOutBoundAirport = getOutBoundCityInfo(flightResult, airport);
////console.log(airline);
const filterFlights = (value) => {
  if (isFilterApplied) {
  const filteredFlights = filteredData.filter(flight => flight.outBound[0].estimateTime <= value);
  // Apply pagination to the filtered results
  const newTotalPages = Math.ceil(filteredFlights.length / itemsPerPage);
  setTotalPages(newTotalPages);

  const startIdx = (currentPage - 1) * itemsPerPage;
  const visibleItems = filteredFlights.slice(startIdx, startIdx + itemsPerPage);
  setFilteredData(visibleItems);
  } else {
    const filteredFlights = flightResult.filter(flight => flight.outBound[0].estimateTime <= value);
  // Apply pagination to the filtered results
  const newTotalPages = Math.ceil(filteredFlights.length / itemsPerPage);
  setTotalPages(newTotalPages);

  const startIdx = (currentPage - 1) * itemsPerPage;
  const visibleItems = filteredFlights.slice(startIdx, startIdx + itemsPerPage);
  setFilteredData(visibleItems);
  }
  ////console.log('reachTime')
};
useEffect(() => {
  filterFlights(sliderDurationValue);
  ////console.log('reachTime')
}, [sliderDurationValue]);


const filterConnectionFlights = (value) => {
  const filteredFlights = flightResult.filter(flight => flight.outBound[0].layOverTime <= value);
  // Apply pagination to the filtered results
  const newTotalPages = Math.ceil(filteredFlights.length / itemsPerPage);
  setTotalPages(newTotalPages);

  const startIdx = (currentPage - 1) * itemsPerPage;
  const visibleItems = filteredFlights.slice(startIdx, startIdx + itemsPerPage);
  setFilteredData(visibleItems);
  ////console.log('reachTime')
};
useEffect(() => {
  filterConnectionFlights(sliderConnectionValue);
  ////console.log('reachTime')
}, [sliderConnectionValue]);
const applyMatrixFilters = (flightData) => {
  const filteredFlights = flightData.filter((flight) => {
    // const airlineMatch = !selectedMatrixAirline || flight.airline === selectedMatrixAirline;
    // const flightTypeMatch =
    //   !selectedMatrixFlightType ||
    //   (selectedMatrixFlightType === 'nonStop' && flight.outBound.every((segment) => segment.layOverTime === 0)) ||
    //   // Adjust this logic to match your specific data structure
    //   (selectedMatrixFlightType === '1Stop' && flight.outBound.some((segment) => segment.layOverTime > 0));

    // const priceMatch =
    //   !selectedMatrixPriceRange ||
    //   (flight.fare.grandOrgTotal >= selectedMatrixPriceRange.min && flight.fare.grandOrgTotal <= selectedMatrixPriceRange.max);

    // return airlineMatch && flightTypeMatch && priceMatch;
    const airlineMatch =
      !selectedMatrixAirline || flight.airline === selectedMatrixAirline;

    // Apply other filters only when an airline is not selected
    if (!selectedMatrixAirline) {
      const flightTypeMatch =
        !selectedMatrixFlightType ||
        (selectedMatrixFlightType === 'nonStop' && flight.outBound.every((segment) => segment.layOverTime === 0)) ||
        (selectedMatrixFlightType === '1Stop' && flight.outBound.some((segment) => segment.layOverTime > 0));

      const priceMatch =
        !selectedMatrixPriceRange ||
        (flight.fare.grandOrgTotal >= selectedMatrixPriceRange.min && flight.fare.grandOrgTotal <= selectedMatrixPriceRange.max);

      return airlineMatch && flightTypeMatch && priceMatch;
    }

    return airlineMatch;
  });

  const newTotalPages = Math.ceil(filteredFlights.length / itemsPerPage);
  setTotalPages(newTotalPages);
  ////console.log('reachTime')
  const startIdx = (currentPage - 1) * itemsPerPage;
  const visibleItems = filteredFlights.slice(startIdx, startIdx + itemsPerPage);
  return visibleItems;
};
// useEffect(() => {
//   const visibleFlights = applyMatrixFilters(flightResult);
//   setFilteredData(visibleFlights);
//   //console.log('reachTime')
// }, [selectedMatrixAirline, selectedMatrixFlightType, selectedMatrixPriceRange, currentPage, flightResult]);
useEffect(() => {
  const visibleFlights = applyMatrixFilters(flightResult);
  setFilteredData(visibleFlights);
}, [selectedMatrixAirline, selectedMatrixFlightType, selectedMatrixPriceRange]);
const handleLayOverFilter = useCallback((event) => {
  const airportCode = event.target.value;
  setSelectedLayOver((prevSelectedLayOver) => {
    if (prevSelectedLayOver.includes(airportCode)) {
      return prevSelectedLayOver.filter((code) => code !== airportCode);
    } else {
      return [...prevSelectedLayOver, airportCode];
    }
  });
  setCurrentPage(1); // Reset to the first page when filter changes
}, [setSelectedLayOver, setCurrentPage]);
useEffect(() => {
  // Filter flight results based on selected layover airports
  let filteredLayOverResults = flightResult;
  if (selectedLayOver.length > 0) {
      filteredLayOverResults = flightResult.filter((result) =>
          selectedLayOver.includes(result.outBound[0]?.toAirport)
      );
  }

  const newTotalPages = Math.ceil(filteredLayOverResults.length / itemsPerPage);
  setTotalPages(newTotalPages);

  const startIdx = (currentPage - 1) * itemsPerPage;
  const visibleItems = filteredLayOverResults.slice(startIdx, startIdx + itemsPerPage);

  setFilteredData(visibleItems);
}, [selectedLayOver, currentPage]);
const handleSliderChange = (value) => {
  setCurrentPage(1);
  setPriceRange(value);
};
useEffect(() => {
  // Apply the filter based on priceRange
  const filteredResultPrice =
    Array.isArray(flightResult) && flightResult.length > 0
      ? flightResult.filter((item) => item.fare.baseFare >= priceRange[0] && item.fare.baseFare <= priceRange[1])
      : [];
      ////console.log('Filtered result:', filteredResult);
  // Update total pages based on the filtered result count
  const newTotalPages = Math.ceil(filteredResultPrice.length / itemsPerPage);
  setTotalPages(newTotalPages);
  if (filteredResultPrice.length === 0) {
    setFilteredData([{ noDataAvailable: true }]);
  } else {
  // Paginate the filtered results
  const startIdx = (currentPage - 1) * itemsPerPage;
  const visibleItemsPrice = filteredResultPrice.slice(startIdx, startIdx + itemsPerPage);

  // Set filteredData with the filtered and paginated result
  setFilteredData(visibleItemsPrice);
  }
  ////console.log(visibleItemsPrice);
}, [priceRange]);
// useEffect(() => {
//   const startIdx = (totalPages.currentPage - 1) * totalPages.itemsPerPage;
//   const endIdx = totalPages.currentPage * totalPages.itemsPerPage;
//   setFilteredData(flightResult.slice(startIdx, endIdx + totalPages.itemsPerPage));
//   //console.log('unfiltered')
// }, [totalPages.currentPage, totalPages.itemsPerPage, flightResult]);

const [flightData, setFlightData] = useState(null);
const [flightDataDes, setFlightDataDes] = useState(null);
const [orgAirData, setOrgAirData] = useState('')
const [desAirData, setDesAirData] = useState('')
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`https://www.tgsapi.com/flights/CrossAirportList?data=${formData.segment[0].originAirport}&authcode=farehuts`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const filteredData = data.filter(item => item.airportCode === formData.segment[0].originAirport);
      setFlightData(filteredData);
      if (filteredData.length > 0) {
        setOrgAirData(`${filteredData[0].airportCode} - ${filteredData[0].airportName}`);
      } else {
        setOrgAirData('No data found for origin airport');
      }
    } catch (error) {
      //console.error('Error fetching flight data:', error);
    }
  };

  fetchData();
}, [formData.segment[0].originAirport]);
////console.log(flightData[0].airportCode)

useEffect(() => {
  const fetchDataDes = async () => {
    try {
      const response = await fetch(`https://www.tgsapi.com/flights/CrossAirportList?data=${formData.segment[0].destinationAirport}&authcode=farehuts`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const filteredData = data.filter(item => item.airportCode === formData.segment[0].destinationAirport);
      setFlightDataDes(filteredData);
      if (filteredData.length > 0) {
        setDesAirData(`${filteredData[0].airportCode} - ${filteredData[0].airportName}`);
      } else {
        setDesAirData('No data found for origin airport');
      }
    } catch (error) {
      //console.error('Error fetching flight data:', error);
    }
  };

  fetchDataDes();
}, [formData.segment[0].destinationAirport]);
////console.log(flightData)

//const desAirData = `${flightDataDes[0].airportCode} - ${flightDataDes[0].airportName}`

if (!flightResult || flightResult.length === 0) {
  return ( 
    <>
    <div class="searchcntr">
                  <div class="container-fluid">
          <div class={`filter_overlay ${isSearchVisible ? 'active' : ''}`} ></div> 
                      <div class="row">
                        <div class="col-sm-12 resultsearch_bg" style={{ marginTop: '75px'}}>						
              <div class={`modifySearchdesktop modifydummy ${isSearchVisible ? 'active' : ''}`} style={{marginTop: '75px'}}>
              <div class='EngineBox2'>
                                      <div class="row">
                                          <div class="col-lg-10 col-md-12">
                                              <div class="row">
                           <div class="col-lg-2 col-md-12 roundtrip">
                        <p  onClick={toggleSearch}>
                        <img src={RoundIcon} width="30" height="30" alt=""/> {parseInt(formData.tripType) === 1 ? 'One Way' : 'Round Trip'}
                        </p>
                        </div>
                                                  <div class="col-lg-5 col-md-12">
                                                      <div class="holder relative2">
                                                          <div class="leavto2 input_block dcol-50">
                          <span class="icons bi bi-geo-alt-fill"></span>
                          {/* <input type="text" required="" value={formData.segment[0]?.originAirport? getAirportInfo(formData.segment[0]?.originAirport, airport) : ''} onClick={toggleSearch}/> */}
                          <input type="text" required="" /*value={formData.segment[0]?.originAirport}*/ value={orgAirData} onClick={toggleSearch}/>
                          <label for="input"  /*value={formData.segment[0]?.originAirport? getAirportInfo(formData.segment[0]?.originAirport, airport) : ''}*/ value={orgAirData}></label>
                              
                                                          </div>
                            
                                                       <div style={{cursor: 'pointer'}} class="swap_icon2"  onClick={toggleSearch}>
                          <img src={swap} alt=""/>
                            </div>
                            
                                                          <div class="leavto2 input_block dcol-50">		
                          <span class="icons bi bi-geo-alt-fill"></span>
                          {/* <input type="text" required="" value={formData.segment[0]?.destinationAirport? getAirportInfo(formData.segment[0]?.destinationAirport, airport) : ''}  onClick={toggleSearch}/> */}
                          <input type="text" required="" /*value={formData.segment[0]?.destinationAirport}*/ value={desAirData}  onClick={toggleSearch}/>
                          <label for="input"></label>
                          <i class="inputClear flyingClear" style={{display: 'none'}}></i>
                                                          </div>
                            
                                                      </div>
                                                  </div>
                                                  <div class="col-lg-5 col-md-12">
                                                      <div class="dates">
                                                          <div class="input_block col-50">
                                                              <span class="label_text">Departure</span>
                                                              <input id="datepicker3" name="departDate" class="inputField cursor hasDatepicker" readonly="readonly" type="text" value={formData.segment[0]?.travelDate}  onClick={toggleSearch}/>
                                                              <span class="icons"><img src={calandar} alt=""/></span>
                                                          </div>
                                                          {parseInt(formData.tripType) === 2 ? (
                                                          <div class="input_block col-50">
                                                              <span class="label_text">Return</span>
                                                              <input id="datepicker4" name="returnDate" class="inputField cursor hasDatepicker" readonly="readonly" type="text" value={formData.segment[1]?.travelDate}  onClick={toggleSearch}/>
                                                              <span class="icons"><img src={calandar} alt=""/></span>
                                                          </div>
                                                          ) : null}
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="col-lg-2 col-md-12">
                                              <div class="class_div relative2">
                                                  <div class="input_block">
                                                      <span class="label_text">Traveler(s) &amp; Cabin <i class="bi bi-caret-down-fill traveller_icon"></i></span>
                                                      <input type="text" value={`${totalPassengers} Travelor ${getCabinTypeLabel(formData.cabinType)}`} class="inputField traveller cursor" readonly="" onClick={toggleSearch}/>
                                                      <span class="icons person-icon"><img src={user} alt=""/></span>
                                                  </div>
                                                  
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                </div>
                {isSearchVisible && (
                  <div class="block_search2 modify-result" style={{display: 'block'}}>
                  <span class="close-search-lg dark" style={{cursor: 'pointer'}} onClick={toggleSearch}>X</span>
                  {/* <span class="close-search-sm" style={{cursor: 'pointer'}} onClick={toggleSearch}>X</span> */}
              <ModifySearch searchId={formData.searchID} originOption={orgAirData} destinationOption = {desAirData} departDate = {formData.segment[0]?.travelDate} returnDate = {formData.segment[1]?.travelDate} pax = {totalPassengers} cabin = {formData.cabinType} />
              </div>
              )}
              {/* <!--search-Section--> */}
                             
              
                          </div>
                      </div>
          
                  </div>
              </div>
              <div className='no-rel-cls' >
              <Link to='tel:020-3627-9333'>
       <img src={NoResult} alt='' style={{width: '100%', height: 'auto'}}/>
       </Link>
       </div>
  <Footer2 />
  </>
  )
}

// useEffect(() => {
//     const defaultOpenElement = document.getElementById('defaultOpen');
//     if (defaultOpenElement) {
//       defaultOpenElement.click();
//     }
//   }, []);

  const handleTabClick = (e, cityName) => {
    openCity(e, cityName);
  };
  
  ////console.log(urlSearchID)
  //console.log(formData)
  ////console.log(formData.tripType)

  useEffect(() => {
    if (flightResult.length > 0) {
      const newTotalPages = Math.ceil(flightResult.length / itemsPerPage);
      setTotalPages(newTotalPages);
  
      // Start with the first page
      const startIdx = 0;
      const visibleItems = flightResult.slice(startIdx, startIdx + itemsPerPage);
      setFilteredData(visibleItems);
    }
  }, [flightResult, itemsPerPage]);

  const [ bannerDisplay, setBannerDisplay ] = useState(false);
  const [ closeBanner, setCloseBanner ] = useState(false) ; 

  useEffect(() => {
    let sessionTimeout;
    let userActivityTimer;

    const handleUserActivity = () => {
      clearTimeout(userActivityTimer);
      clearTimeout(sessionTimeout);
      //setSessionExpired(false);
      //setBannerDisplay(false)
      
      userActivityTimer = setTimeout(() => {
        sessionTimeout = setTimeout(() => {
          if(closeBanner === false){
          setBannerDisplay(true);
        } else {
          setBannerDisplay(false);
        }
          //console.log(sessionExpired)
        }, 30 * 1000); // 5 minutes in milliseconds
      }, 1000); // Reset session timeout after 1 second of inactivity
    };

    handleUserActivity();

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      clearTimeout(sessionTimeout);
      clearTimeout(userActivityTimer);
    };
  }, []);

  useEffect(() => {
    let sessionTimeout;
    let userActivityTimer;

    const handleUserActivity = () => {
      clearTimeout(userActivityTimer);
      clearTimeout(sessionTimeout);
      //setSessionExpired(false);
      
      userActivityTimer = setTimeout(() => {
        sessionTimeout = setTimeout(() => {
          setSessionExpired(true);
          console.log(sessionExpired)
        }, 5 * 60 * 1000); // 5 minutes in milliseconds
      }, 1000); // Reset session timeout after 1 second of inactivity
    };

    handleUserActivity();

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      clearTimeout(sessionTimeout);
      clearTimeout(userActivityTimer);
    };
  }, []);
  //console.log(formData.tripType)



  const [timeLeft, setTimeLeft] = useState('');
    const [timerStarted, setTimerStarted] = useState(false);

    useEffect(() => {
        if (timerStarted === false) {
            // Set your end time here
            const endTime = new Date();
            endTime.setMinutes(endTime.getMinutes() + 15); // Setting end time to 15 minutes from now

            const timer = setInterval(() => {
                const now = new Date().getTime();
                const timeDifference = endTime - now;

                if (timeDifference > 0) {
                    // Calculate hours, minutes, and seconds from the time difference
                    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

                    const timeString = formatTime(hours, minutes, seconds);
                    setTimeLeft(timeString);
                } else {
                    clearInterval(timer);
                    setTimeLeft('00:00:00');
                }
            }, 1000);

            setTimerStarted(true);

            return () => clearInterval(timer); // Clean up timer on unmount
        } else {
            setTimerStarted(false);
        }
    }, []);

    const formatTime = (hours, minutes, seconds) => {
        // Add leading zeros if necessary
        const hoursString = (hours < 10 ? '0' : '') + hours;
        const minutesString = (minutes < 10 ? '0' : '') + minutes;
        const secondsString = (seconds < 10 ? '0' : '') + seconds;

        return `${hoursString}:${minutesString}:${secondsString}`;
    };

  return (
    <>
    {bannerDisplay === true && closeBanner === false ? (
    <div id="boxed">
		<div class="home-page-popup">
			<div /*={{display: 'none'}}*/ id="dialog" class="window">
				<div onClick={() => {setCloseBanner(true)}} id="close_id" style={{cursor: 'pointer'}} class="closed">
					<img src={closeBtn} alt="cheap flight" />
				</div>
				<div class="popup-dsgn-box">
					<div class="content_bxmain">
						<div class="content_left">
							<img src={popupBanner} alt="supp" style={{width: '100%'}} />
              <span class="WatchThisTrip__alarm__fc">{timeLeft}</span>
						</div>
						<div class="content_right">
							<div class="count-content-list">
								<div class="type-tripclass" style={{marginTop: '-42px'}}>									
									<p class="timeoffer">
										Limited Time Offer <br/>
										<span class="price_">£{((flightResult[0].fare.grandTotal - 25) / totalPassengers).toFixed(2)}*</span></p>
								</div>
								
								<div class="section-itenerary relative">
									<span class="icon-trip-type">
										<img src={roundPopup} alt="trip"/>
									</span>
									
									<div class="city-code2 right">										
										<b>{formData.segment[0].destinationAirport}</b>
                    {formData.tripType === "2" ? (
										<div class="name-return">										
										{formatDateAndTime(formData.segment[1].travelDate).formattedDateString}
									</div>
                  ) : (<div class="name-return">										
                  {formatDateAndTime(formData.segment[0].travelDate).formattedDateString}
                </div>)}
									</div>
									<div class="city-code2">
										<b>{formData.segment[0].originAirport}</b>
										<div class="name-return">										
										{formatDateAndTime(formData.segment[0].travelDate).formattedDateString}
									</div>
									</div>
									
									
									<div style={{textAlign: 'center'}}>
									<p>{formData.tripType === "2" ? ("Round Trip") : ("One Way")},{getCabinTypeLabel(formData.cabinType)}</p>
									</div>

								</div>

								
								{/* <!--TFN Dynamic Start--> */}

								<div class="callnow-btn">									
									<Link to="tel:020-3627-9333" id="callTracker">
									<img src={phone3} width="30" alt="call" /> 020-3627-9333
									</Link>
								</div>
								{/* <!--TFN Dynamic End --> */}
								<div class="txt-disclaimer">
									* Fares are subject to seat availability and not guaranteed until ticketed.T&C Applied
								</div>



							</div>
						</div>
						
						<div style={{clear: 'both'}}></div>
					</div>

				</div>
			</div>
			<div id="mask"></div>
		</div>
	</div>
    ) : null }
    {closeBanner === true ? (
    <div class="count-top-icon pointer uc-minimize-strip" id="reopen_ucb" style={{display: 'block'}} onClick={() => {setCloseBanner(false); setBannerDisplay(true)}}>
		<div class="strip-content ng-binding" style={{display: 'flex'}}>
			<p style={{margin: '11px'}}>upto £25* off <span id="timer1" class="Timer1">{timeLeft}</span></p>
		</div>
	</div>
  ) : null}
    <MetaTitle url={`${apiIP}/flight-List`} />
    {/* <div style={{display: 'none'}}>
    {responseDataForSearchModify ? (
        <div>
          <h2>API Response:</h2>
          <pre>{JSON.stringify(responseDataForSearchModify, null, 2)}</pre>
        </div>
      ) : (
        <div>
          <p>No response yet. Click the button to fetch data.</p>
          <button className="btn btn-primary" onClick={handleSubmit}>
            Click Me
          </button>
        </div>
      )}
      </div> */}
   <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.2/css/bootstrap.min.css" />
	{/* <!--flight Details--> */}
	<script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/1.9.1/jquery.min.js"></script>
    <script type="text/javascript" src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.2/js/bootstrap.min.js"></script>
    {sessionExpired ? (
        // <div>
        //   <h1>Session Expired</h1>
        //   <p>Your session has expired. Please <Link to='/'>Click Here</Link> to continue.</p>
        // </div>
        <div class="modal" tabindex="-1" style={{display: 'block', zIndex: '99'}}>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Session Expired</h5>
        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
      </div>
      <div class="modal-body">
      <img src={sessionExpire} alt="Session-Expire" width="55px" />
        <p> Your Session has a timeout please. Please click the continue button for Homepage</p>
      </div>
      <div class="modal-footer">
        {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
        <button type="button" class="btn btn-primary"><Link to='/' style={{color: 'white'}}>Continue</Link></button>
      </div>
    </div>
  </div>
</div>
      ) : (
    <div id="wrapper2"> 
    {isLoading && (
        <div className="loader-overlay">
          <img src={Loader} alt="Loader" />
        </div>
      )}
  {/* <!--  / main container / --> */}
    <div id="cntrmain">
		
        
		
        {/* <!--  / header container / --> */}
        <div id="SearchflightRqust" style={{marginTop: '66px'}}>
        <div class="bannerBox2">
		{/* <!-- modify Search Information Start--> */}
		<div class="modifySearchInfoBox">        	
            	   {/* <a href="#modify"> */}
					   <button type="button" class="modifySearchBtn" onClick={toggleSearch}><i class="bi bi-search"></i></button>
			      {/* </a> */}
		       <div class="searchInfo">
				   <span class="code">{formData.segment[0]?.originAirport? getAirportInfo(formData.segment[0]?.originAirport, airport) : ''}</span>				
				<i class="bi bi-arrow-left-right arrow"></i>
				<span class="code" style={{textTransform:'capitalize'}}>{formData.segment[0]?.destinationAirport? getAirportInfo(formData.segment[0]?.destinationAirport, airport) : ''}</span> 
				<div>{formData.segment[0]?.travelDate}{parseInt(formData.tripType) === 2 ? ( <span> - {formData.segment[1]?.travelDate}</span> ): null}			
				<span class="date">{totalPassengers} Travelers</span>,  			
					<span> {getCabinTypeLabel(formData.cabinType)}</span>
				</div>
			</div>            
        </div>
        <div className="container">
      <div className="filter-btn" style={{ width: '100%', textAlign: 'center' }}>
        <div onClick={toggleFilter} style={{cursor: 'pointer'}}>
          <i className="fa fa-filter"></i> Filters
        </div>
      </div>
      {isFilterVisible && (
      <div className="search_popup_mobile" id="filters" style={{ display: 'block' }}>
        <div onClick={toggleFilter} style={{cursor: 'pointer'}} className="searchwindowclose">
          <i className="bi bi-x"></i>
        </div>
        <div className="popup_head blockshadow" style={{ marginBottom: '0px' }}>
          <span className="icons bi bi-geo-alt-fill"></span> Filter Area
        </div>

        <div className="inner_block">
          <div className="tab1">
            <button className="tablinks active" onClick={(e) => handleTabClick(e, 'Stop')} id="defaultOpen">
              Stop
            </button>
            <button className="tablinks" onClick={(e) => handleTabClick(e, 'Price')}>
              Price
            </button>
            <button class="tablinks" onClick={(e) => handleTabClick(e, 'Time')}>
              Time
            </button>
            <button class="tablinks" onClick={(e) => handleTabClick(e, 'Airlines')}>
              Airlines
            </button>
            {/* Add similar onClick handlers for other buttons */}
          </div>

          <div className="clearfix"></div>

          <div id="Stop" className="tabcontent1" style={{ display: 'block' }}>
            <h5>Stop</h5>
            <div className="fi-content">
            {
             flightResult.some(flight => flight.outBound[0].layOverTime === 0) && (
            <label class="ng-scope">
	            <input type="checkbox" checked={stopFilter === 'nonStop'}
              onChange={() => handleStopFilter('nonStop')}/>
	            <div class="cb">&nbsp;non-stop</div>
	            <div class="stoprates"></div>
	          </label>
             )}
            {flightResult.some(flight => flight.outBound[0].layOverTime > 0) && (
	            <label class="ng-scope">
	            <input type="checkbox" checked={stopFilter === 'oneStop'}
              onChange={() => handleStopFilter('oneStop')}/>
	            <div class="cb">&nbsp;1 Stop</div>
	            <div class="stoprates"></div>
	            </label>
            )}
	            {/* <label class="ng-scope">
	            <input type="checkbox"/>
	            <div class="cb">&nbsp;2 Stop</div>
	            <div class="stoprates">$682.11</div>
	            </label> */}
            </div>
          </div>
          <div id="Price" className="tabcontent1" style={{ display: 'none' }}>
            <h3>Price</h3>
            <Slider range min={minPrice} max={maxPrice} value={priceRange} onChange={handleSliderChange} />
				    <p style={{fontSize: '12px'}}> Min Price: £{priceRange[0].toFixed(2)} | Max Price: £{priceRange[1].toFixed(2)} </p>
          </div>
          <div id="Time" class="tabcontent1" style={{ display: 'none' }}>
            <h3>Time</h3>
            <p>Duration of Journey: {convertMinutesToHoursAndMinutes(sliderDurationValue)}</p>
				      <Slider
                  min={0}
                  max={720}
                  step={15}
                  value={sliderDurationValue}
                  onChange={(value) => setSliderDurationValue(value)}
               />
          </div>		
          <div id="Airlines" class="tabcontent1" style={{ display: 'none' }}>
	          <div class="nav_Airlines">
	          <h5>Airlines</h5>
	          <ul>
	          <li><label><input type="checkbox" checked={selectedAirline === null} onChange={() => handleAirlineFilter(null)} /> All Airlines </label></li>
            {itemsToShowAirline.map((airline) => {
          const secondAirlineCode = airline.split(',')[1];
          return (
        <li>
          <label>
            <input
              type="checkbox"
              checked={selectedAirline === `${airline}`} onClick={() => handleAirlineFilter(`${airline}`)}
            />
            {secondAirlineCode ? (
									<span class="">{airlinesName[airline.split(',')[0]]} & {airlinesName[secondAirlineCode]}</span>
                  ):(
                    <span class="">{airlinesName[airline.split(',')[0]]}</span>
                  )}
          </label>
        </li>
        )})}
	          {/* <li><label><input type="checkbox"/> Alaska Airlines</label></li>
	          <li><label><input type="checkbox"/> American Airlines</label></li>
	          <li><label><input type="checkbox"/> Spirit Airlines</label></li>
	          <li><label><input type="checkbox"/> Frontier Airlines</label></li>					 */}
	          </ul> 
            {airline.length > 5 && (
                  <span style={{cursor: 'pointer', color: '#fc6604'}} onClick={toggleShowAllAirlines}>
                {showAllAirlines ? 'Show Less' : 'Show More'}
                  </span>
                )}
	          </div>
          </div>	
          {/* Add content for other tabs similarly */}
        </div>

        <div className="bottom_row">
          <button type="button" className="done_button" onClick={resetFilter}>
            Clear Filter
          </button>
        </div>
      </div>
       )}
    </div>
		{/* <!-- modify Search information End-->				 */}
        <div class="searchcntr">
                    <div class="container-fluid">
						<div class={`filter_overlay ${isSearchVisible ? 'active' : ''}`} ></div> 
                        <div class="row">
                          <div class="col-sm-12 resultsearch_bg" style={{}}>						
							  <div class={`modifySearchdesktop modifydummy ${isSearchVisible ? 'active' : ''}`}>
							  <div class='EngineBox2'>
                                        <div class="row">
                                            <div class="col-lg-10 col-md-12">
                                                <div class="row">
							               <div class="col-lg-2 col-md-12 roundtrip">
													<p  onClick={toggleSearch}>
													<img src={RoundIcon} width="30" height="30" alt="RoundIcon"/> {parseInt(formData.tripType) === 1 ? 'OneWay' : 'Round Trip'}
													</p>
													</div>
                                                    <div class="col-lg-5 col-md-12">
                                                        <div class="holder relative2">
                                                            <div class="leavto2 input_block dcol-50">
														<span class="icons bi bi-geo-alt-fill"></span>
														{/* <input type="text" required="" value={formData.segment[0]?.originAirport? getAirportInfo(formData.segment[0]?.originAirport, airport) : ''} onClick={toggleSearch}/> */}
                            <input type="text" required="" /*value={formData.segment[0]?.originAirport}*/ value={orgAirData} onClick={toggleSearch}/>
														<label for="input"  ></label>
																
                                                            </div>
															
                                                         <div style={{cursor: 'pointer'}} class="swap_icon2"  onClick={toggleSearch}>
														<img src={swap} alt="swap"/>
															</div>
															
                                                            <div class="leavto2 input_block dcol-50">		
														<span class="icons bi bi-geo-alt-fill"></span>
														{/* <input type="text" required="" value={formData.segment[0]?.destinationAirport? getAirportInfo(formData.segment[0]?.destinationAirport, airport) : ''}  onClick={toggleSearch}/> */}
                            <input type="text" required="" /*value={formData.segment[0]?.destinationAirport}*/ value={desAirData}  onClick={toggleSearch}/>
														<label for="input"></label>
														<i class="inputClear flyingClear" style={{display: 'none'}}></i>
                                                            </div>
															
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-5 col-md-12">
                                                        <div class="dates">
                                                            <div class="input_block col-50">
                                                                <span class="label_text">Departure</span>
                                                                <input id="datepicker3" name="departDate" class="inputField cursor hasDatepicker" readonly="readonly" type="text" value={formData.segment[0]?.travelDate}  onClick={toggleSearch}/>
                                                                <span class="icons"><img src={calandar} alt="calandar"/></span>
                                                            </div>
                                                            {parseInt(formData.tripType) === 2 ? (
                                                            <div class="input_block col-50">
                                                                <span class="label_text">Return</span>
                                                                <input id="datepicker4" name="returnDate" class="inputField cursor hasDatepicker" readonly="readonly" type="text" value={formData.segment[1]?.travelDate}  onClick={toggleSearch}/>
                                                                <span class="icons"><img src={calandar} alt="calandar"/></span>
                                                            </div>
                                                            ): null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-12">
                                                <div class="class_div relative2">
                                                    <div class="input_block">
                                                        <span class="label_text">Traveler(s) &amp; Cabin <i class="bi bi-caret-down-fill traveller_icon"></i></span>
                                                        <input type="text" value={`${totalPassengers} Travelor ${getCabinTypeLabel(formData.cabinType)}`} class="inputField traveller cursor" readonly="" onClick={toggleSearch}/>
                                                        <span class="icons person-icon"><img src={user} alt="user"/></span>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
								  </div>
                  {isSearchVisible && (
                    <div class="block_search2 modify-result" style={{display: 'block'}}>
                    <span class="close-search-lg" style={{cursor: 'pointer'}} onClick={toggleSearch}>X</span>
                    <span class="close-search-sm" style={{cursor: 'pointer'}} onClick={toggleSearch}>X</span>
							  {/* <ModifySearch searchId={urlSearchID} /> */}
                <ModifySearch searchId={formData.searchID} originOption={orgAirData} destinationOption = {desAirData} departDate = {formData.segment[0]?.travelDate} returnDate = {formData.segment[1]?.travelDate} pax = {totalPassengers} cabin = {formData.cabinType} />
                </div>
                )}
                {/* <p style={{display: 'none'}}>Filter</p> */}
								{/* <!--search-Section--> */}
                               
							  
                            </div>
                        </div>
						
                    </div>
                </div>
            </div>
		</div>
       
        {/* <!-- mobile Search popup Fly From Start here --> */}
        <div class="mobile_search_popup" id="flyfrom" style={{display: 'none'}}>
            <a href="javascript" class="searchwindowclose"><i class="bi bi-x"></i></a>
            <div class="popup_head blockshadow"><span class="icons bi bi-geo-alt-fill"></span> 
				Select Loaction 
			</div>

            <div class="inner_block">
                <div class="destination2_field">
                    <span class="line"></span>
                    <div class="input_block from_input relative2">
                        <input type="text" placeholder="From" id="fromCityMob" class="inputField mobile_input"/>
                        <span class="label_text change_label">From</span>

                        {/* <!--<div class="error_text">Please select an origin</div>--> */}
                    </div>
                    <div class="row_margin relative2">
                        <a href="javascript" class="swap_icon2">
                            <img src={swap} alt="swap" />
                        </a>
                        <div class="input_block to_input">
                            <input type="text" placeholder="To" id="toCityMob" class="inputField mobile_input"/>
                            <span class="label_text change_label">To</span>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        {/* <!--mobile Search popup Start here -->
        <!-- mobile Search popup Fly To Start here --> */}
        <div class="mobile_search_popup" id="flyto" style={{display: 'none'}}>
            <a href="javascript" class="searchwindowclose"><i class="bi bi-x"></i></a>
            <div class="input_block">
                <div class="blockshadow from_inputmob">
                    <input type="text" placeholder="To" id="toCityMob" class="inputField"/>
                    {/* <!--<span class="label_text change_label">Flying from</span>--> */}
                    <span class="icons bi bi-geo-alt-fill"></span>
                </div>
            </div>
        </div>
        {/* <!--mobile Search popup Start here -->
        <!-- mobile calendar popup Start here --> */}
        <div class="mobile_search_popup" id="mobileCal" style={{display: 'none'}}>
            <a href="javascript" class="searchwindowclose"><i class="bi bi-x"></i></a>
            <div class="row row_margin" id="mobCalDiv">
                <div class="col-12">
                    <div class="dates">
                        <div class="mobile_header text-center" id="mobileDateLabel">Select Depart Date</div>
                        <div class="input_block col-50" id="mobileCalDept">
                            <input type="text" id="datepickerMob" class="inputField cursor" readonly/>
                            <span class="icons bi bi-calendar3"></span>
                        </div>
                        <div class="input_block col-50" id="mobileCalRet">
                            <input type="text" id="datepickerMob2" class="inputField cursor" readonly/>
                            <span class="icons bi bi-calendar3"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="full_calender">
                <div class="date_cal"></div>
            </div>
        </div>
        {/* <!--mobile Search popup Start here --> */}

        <script type="text/javascript" src="resources/js/searchengine.js?5768"></script>
		

        {/* <!--container-content--> */}
        <div class="container-fluid" style={{marginTop: '20px'}}>
			<div class="row">
			<div class="col-md-1 flclass" style={{ marginTop: `${marginTop}px` }}>				
				<div class={`filter_overlay ${isFilterVisible ? 'active' : ''}`} ></div>   {/*  {`bg-light ${isFilterVisible ? 'active' : ''}`} */}
				<div class={`filter-container ${isFilterVisible ? 'active' : ''} bg-light`} id="open-filture" onClick={toggleFilter}>
				<div style={{cursor: 'pointer'}} onClick={toggleFilter}>
					<img src={filters} width="65" alt="filters"/> </div>
			     <img src={FiltersMenu} width="70" height="auto" alt="FiltersMenu"/> 
				</div>
                {isFilterVisible && (    //{`filture ${isFilterVisible ? 'show' : 'hide'}`}
				<div class='filter-content filture' style={{display: 'block'}}>
				<span class="float-end"><div id="hide-filture" style={{cursor: 'pointer'}} onClick={toggleFilter}>Close X </div></span>					
				<div style={{marginTop: '20px'}}>
					
				<div class="nav_logo-name">
				 Filter Your Results <span onClick={resetFilter}>Clear Filter</span> 
				<p>{noOfResults} results found</p>
				</div>	
					
				{/* <div class="nav_link">			  
			    <div class="nav_stop">
				  <p>Stop</p>
					{
  flightResult.some(flight => flight.outBound[0].layOverTime === 0) && (
    <div>
      <input
        type="checkbox"
        checked={stopFilter === 'nonStop'}
        onChange={() => handleStopFilter('nonStop')}
        style={{ width: '20px', height: '15px' }}
      />
      <label>Non-Stop</label>
    </div>
  )
}
  {flightResult.some(flight => flight.outBound[0].layOverTime > 0) && (
  <div>
    <input
      type="checkbox"
      checked={stopFilter === 'oneStop'}
      onChange={() => handleStopFilter('oneStop')}
      placeholder='1'
      style={{width: '20px', height: '15px'}}
    />
    <label>One Stop</label>
  </div>
)}
      
			  </div>
		      </div> */}
         <div class="nav_link">			  
  <div class="nav_stop">
    <p>Stop</p>
    <div>
      <input
        type="checkbox"
        checked={nonStopFilter !== null}
        onChange={handleNonStopFilter}
        style={{ width: '20px', height: '15px' }}
      />
      <label>Non-Stop</label>
    </div>
    <div>
      <input
        type="checkbox"
        checked={oneStopFilter !== null}
        onChange={handleOneStopFilter}
        style={{ width: '20px', height: '15px' }}
      />
      <label>One Stop</label>
    </div>
  </div>
</div>


					
				<div class="nav_link">			  
			    <div class="nav_stop">
				  <p>Depart</p>
					<ul class="time_filter">
					  <li className={selectedDepTimeFilter && selectedDepTimeFilter.startTime === 0 ? 'selected' : ''} onClick={() => handleDepTimeFilterClick(0, 6)}>
						<span class="icons ermorning_icon"></span>
						<div class="time">00 - 06</div>
					</li>					  
					  <li className={selectedDepTimeFilter && selectedDepTimeFilter.startTime === 6 ? 'selected' : ''} onClick={() => handleDepTimeFilterClick(6, 12)}>
						<span class="icons morning_icon"></span>
						<div class="time">06 - 12</div>
					</li>					  
					   					  
					  <li className={selectedDepTimeFilter && selectedDepTimeFilter.startTime === 12 ? 'selected' : ''} onClick={() => handleDepTimeFilterClick(12, 18)}>
						<span class="icons afternoon_icon"></span>
						<div class="time">12 - 18</div>
					</li>					  
                    <li className={selectedDepTimeFilter && selectedDepTimeFilter.startTime === 18 ? 'selected' : ''} onClick={() => handleDepTimeFilterClick(18, 0)}>
						<span class="icons evening_icon"></span>
						<div class="time">18 - 00</div>
					</li>
					  
				</ul>
				  
			  
			  </div>
		      </div>
					
			<div class="nav_link">			  
			    <div class="nav_stop">
				  <p>Return</p>
				  <ul class="time_filter">
					  <li className={selectedRetTimeFilter && selectedRetTimeFilter.startTime === 0 ? 'selected' : ''} onClick={() => handleReachTimeFilterClick(0, 6)}>
						<span class="icons ermorning_icon"></span>
						<div class="time">00 - 06</div>
					</li>					  
					  <li className={selectedRetTimeFilter && selectedRetTimeFilter.startTime === 6 ? 'selected' : ''} onClick={() => handleReachTimeFilterClick(6, 12)}>
						<span class="icons morning_icon"></span>
						<div class="time">06 - 12</div>
					</li>					  
					   					  
					  <li className={selectedRetTimeFilter && selectedRetTimeFilter.startTime === 12 ? 'selected' : ''} onClick={() => handleReachTimeFilterClick(12, 18)}>
						<span class="icons afternoon_icon"></span>
						<div class="time">12 - 18</div>
					</li>
					  <li className={selectedRetTimeFilter && selectedRetTimeFilter.startTime === 18 ? 'selected' : ''} onClick={() => handleReachTimeFilterClick(18, 0)}>
						<span class="icons evening_icon"></span>
						<div class="time">18 - 00</div>
					</li>
					  
				</ul>
			  
			  </div>
		      </div>
					
					
			<div class="nav_link">
			<div class="nav_Airlines">
			  <p>Airlines</p>
        <ul>
  <li>
    <label>
      <input
        type="checkbox"
        checked={!selectedAirlineForFilter || selectedAirlineForFilter.length === itemsToShowAirline.length} // Check for "All Airlines" option
        onChange={() => handleAirlineFilterForFilter(null)} // Pass null to indicate all airlines
      />
      All Airlines
    </label>
  </li>
  {itemsToShowAirline.map((airline, index) => {
    const secondAirlineCode = airline.split(',')[1];
    return (
      <li key={index}>
        <label>
          <input
            type="checkbox"
            checked={selectedAirlineForFilter && selectedAirlineForFilter.includes(airline)}
            onChange={() => handleAirlineFilterForFilter(airline)}
          />
          {secondAirlineCode ? (
            <span>{airlinesName[airline.split(',')[0]]} & {airlinesName[secondAirlineCode]}</span>
          ) : (
            <span>{airlinesName[airline.split(',')[0]]}</span>
          )}
        </label>
      </li>
    );
  })}
</ul>

      {airline.length > 5 && (
                  <span style={{cursor: 'pointer', color: '#fc6604'}} onClick={toggleShowAllAirlines}>
                {showAllAirlines ? 'Show Less' : 'Show More'}
                  </span>
                )}
					
			  </div>
				
		      </div>
					
				<div class="nav_link">			  
			    <div class="nav_stop">
				  <p>Pricing</p>
                  <Slider range min={minPrice} max={maxPrice} value={priceRange} onChange={handleSliderChange} />
				  <p style={{fontSize: '12px'}}> Min Price: £{priceRange[0].toFixed(2)} | Max Price: £{priceRange[1].toFixed(2)} </p>
			  
			  </div>
		      </div>
					
					
			<div class="nav_link">			  
			    <div class="nav_stop">
				  <p>Duration of Journey: {convertMinutesToHoursAndMinutes(sliderDurationValue)}</p>
				  <Slider
                   min={0}
                   max={720}
                   step={15}
                   value={sliderDurationValue}
                   onChange={(value) => setSliderDurationValue(value)}
                  />
			  
			  </div>
		      </div>
					
			<div class="nav_link">			  
			    <div class="nav_stop">
				  <p>Connection Time: {convertMinutesToHoursAndMinutes(sliderConnectionValue)}</p>
				  <Slider
                   min={0}
                   max={720}
                   step={15}
                   value={sliderConnectionValue}
                   onChange={(value) => setSliderConnectionValue(value)}
                  />
			  
			  </div>
		      </div>
					
			<div class="nav_link">
			<div class="nav_Airlines">
			  <p>Layover Airports</p>
              
				<ul>
                {/* {itemsToShow.map((airport, index) => (
               
				<li><label><input type="checkbox" key={index} id={airport.airportCode} value={airport.airportCode} checked={selectedLayOver.includes(airport.airportCode)} onClick={handleLayOverFilter}/> {airport.airportName}</label></li>
                ))} */}
                {itemsToShow.map((airport, index) => (
    <li key={index}>
        <label>
            <input
                type="checkbox"
                value={airport.airportCode}
                checked={selectedLayOver.includes(airport.airportCode)}
                onChange={handleLayOverFilter}
            />{' '}
            {airport.airportName}
        </label>
    </li>
    ))}
                 {/* {layOver.map((layover) => (    
				<li><label><input type="checkbox" check={selectedLayOver === airport.airport} onChange={() => handleLayOverFilter(airport.toAirport)}/> {airport[0].airportName}</label></li>
                ))} */}
				{/* <li><label><input type="checkbox"/> Sky Harbor International</label></li>
				<li><label><input type="checkbox"/> Sky Harbor International</label></li>
				<li><label><input type="checkbox"/> Sky Harbor International</label></li>					 */}
				</ul>
                {airport.length > 5 && (
                  <span style={{cursor: 'pointer', color: '#fc6604'}} onClick={toggleShowAllAirport}>
                {showAllAirport ? 'Show Less' : 'Show More'}
                  </span>
                )}
                
					
			  </div>
				
		      </div>		
					
					
				</div>	
					
				</div>
                )}				
			  </div>
			<div class="col-md-11">
				  <div class="row hidden-xs">
					<div class="col-md-12">
					<div class="matrix-head-row">
                    {/* <div class="matrix-head">Showing {noOfResults} Flights from {formData.segment[0]?.originAirport ? getCityInfo(formData.segment[0]?.originAirport, airport) : ''} to {formData.segment[0]?.destinationAirport ? getCityInfo(formData.segment[0]?.destinationAirport, airport) : ''}</div> */}
                    <div class="matrix-head">Showing {noOfResults} Flights from {orgAirData} to {desAirData}</div>
                        <div class="matrix-slide-button-container">
							
                            <button type="button" class="matrix-slide-button" onClick={handleLeftArrowClick} disabled={matrixFrame === 0} id="slideLeft" ng-click="matrixLeftClick();"><i class="fa fa-angle-left"></i> </button>
							
                            <button type="button" class="matrix-slide-button" onClick={handleRightArrowClick} disabled={matrixFrame === totalMatrixFrame - 1} id="slideRight" ng-click="matrixRightClick();"> <i class="fa fa-angle-right"></i></button>
							
                        </div>
                    </div>						
					<div class="matrix ng-scope">
                    <div class="matrixtable">
                        <div class="fixed-table-column">
                            <div class="ft-item">
                                <div class="fare-col bolder">Airline</div>
                                <div class="amont-col" onClick={() => setSelectedMatrixFlightType('nonStop')}>Non Stop</div>
                                <div class="amont-col"  onClick={() => setSelectedMatrixFlightType('1Stop')}>1+ Stop</div>
                                <div class="amont-col" onClick={() => setSelectedMatrixFlightType('2+Stop')}>2+ Stop</div>
                            </div>
                        </div>
				    {/* <!--<div class="matrix-table-columns responsive">--> */}
                    {displayedAirlines.map((airline) => {
                      const secondAirlineCode = airline.split(',')[1]; // Assuming the second code is separated by a comma
                      return (
						<div class="matrix-table-columns" key={airline}>
							
   								<div class="mt-item">
                                <div class="fare-col" checked={selectedAirline === `${airline}`} onClick={() => handleAirlineFilter(`${airline}`)}>
                                {secondAirlineCode ? (
                                  <>
                                  <img src={`https://imgfolders.com/farehutz/${airline.split(',')[0]}.png`} checked={selectedAirline === `${airline}`} onClick={() => handleAirlineFilter(`${airline}`)}/*onClick={() => setSelectedMatrixAirline(airlinesName[airline])}*/ alt={airline} style={{width: '40px'}}/>
                                
<svg width="25" height="21" checked={selectedAirline === `${airline}`} onClick={() => handleAirlineFilter(`${airline}`)} style={{position: 'absolute', marginLeft: '166px', marginTop: '-29px'}} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.92914 24C9.93424 23.4991 9.94556 22.9982 9.94103 22.4979C9.93933 22.3396 9.98691 22.2391 10.1172 22.1442C10.785 21.6573 11.4477 21.1632 12.1036 20.6606C12.184 20.5989 12.2582 20.4714 12.2588 20.3737C12.2679 18.3904 12.2656 16.4076 12.265 14.4243C12.265 14.3727 12.2582 14.321 12.2514 14.2295C9.16958 15.1858 6.10246 16.1375 3.00475 17.0983C3.00475 16.7895 3.00475 16.5076 3.00475 16.2257C3.00475 15.8214 3.01325 15.4171 3.00079 15.0134C2.99569 14.8489 3.05516 14.7618 3.1928 14.677C6.12512 12.8622 9.0529 11.0389 11.9875 9.228C12.2004 9.09661 12.2735 8.96016 12.2718 8.71421C12.2605 6.85107 12.2962 4.98682 12.2543 3.12424C12.2293 2.01243 12.7238 1.25999 13.7734 1.00056L14.2254 1.00056C14.6049 1.10725 14.9623 1.25269 15.2392 1.54749C15.5921 1.92258 15.7377 2.36675 15.736 2.87717C15.7298 4.85261 15.736 6.82861 15.7292 8.80405C15.7286 8.98206 15.7824 9.08369 15.9359 9.17915C18.8818 11.0013 21.8227 12.8307 24.7691 14.6523C24.9357 14.7556 25.0048 14.859 24.9997 15.0583C24.9844 15.6563 24.994 16.2555 24.994 16.8541C24.994 16.9265 24.994 16.9984 24.994 17.0983C21.9008 16.1387 18.8394 15.1886 15.7468 14.2284C15.7411 14.3351 15.7337 14.4131 15.7337 14.4912C15.7332 16.4368 15.7349 18.3825 15.7298 20.3282C15.7298 20.4871 15.7773 20.5859 15.907 20.6808C16.5686 21.1637 17.2194 21.6607 17.881 22.1436C18.0113 22.2391 18.0588 22.339 18.0571 22.4974C18.052 22.9982 18.0639 23.4986 18.069 23.9994C18.039 23.9994 18.009 23.9994 17.9784 23.9994C17.9603 23.9871 17.9439 23.9686 17.924 23.9629C16.6604 23.603 15.3967 23.2419 14.1308 22.8893C14.0368 22.8629 13.9206 22.8764 13.8238 22.9028C13.1866 23.0774 12.5511 23.2588 11.9161 23.4424C11.284 23.6255 10.6519 23.8141 10.0198 24C9.98975 24 9.95973 24 9.92914 24Z" fill="#ADB8BF"/>
                                            <path d="M6.2985 20.9546C6.30314 20.4992 6.31344 20.0439 6.30932 19.589C6.30777 19.445 6.35103 19.3537 6.46947 19.2674C7.07659 18.8248 7.67908 18.3755 8.27539 17.9186C8.34851 17.8625 8.41597 17.7466 8.41648 17.6578C8.42472 15.8547 8.42266 14.0521 8.42215 12.249C8.42215 12.202 8.41597 12.155 8.40979 12.0718C5.60796 12.9412 2.81952 13.8065 0.00326767 14.68C0.00326765 14.3992 0.00326764 14.1429 0.00326763 13.8867C0.00326761 13.5191 0.0109918 13.1515 -0.000337051 12.7845C-0.00497159 12.6349 0.0490979 12.5558 0.17423 12.4787C2.84011 10.8288 5.50188 9.17115 8.16982 7.52478C8.36344 7.40532 8.42987 7.28127 8.42833 7.05767C8.41803 5.36381 8.45047 3.66894 8.41236 1.9756C8.38971 0.964807 8.83925 0.280732 9.79345 0.0448798L10.2044 0.0448798C10.5494 0.141875 10.8743 0.274095 11.1261 0.54211C11.4469 0.883126 11.5793 1.28693 11.5777 1.75098C11.5721 3.54693 11.5777 5.34339 11.5716 7.13935C11.5711 7.30118 11.62 7.39358 11.7595 7.48036C14.4378 9.13695 17.1114 10.8002 19.7901 12.4562C19.9415 12.5502 20.0044 12.6441 19.9997 12.8253C19.9858 13.369 19.9946 13.9137 19.9946 14.4579C19.9946 14.5238 19.9946 14.5891 19.9946 14.68C17.1824 13.8075 14.3991 12.9438 11.5875 12.0708C11.5824 12.1678 11.5757 12.2388 11.5757 12.3097C11.5752 14.0786 11.5767 15.8475 11.5721 17.6164C11.5721 17.7609 11.6153 17.8507 11.7333 17.937C12.3347 18.376 12.9264 18.8278 13.5279 19.2669C13.6463 19.3537 13.6895 19.4445 13.688 19.5885C13.6834 20.0439 13.6942 20.4987 13.6988 20.9541C13.6715 20.9541 13.6442 20.9541 13.6164 20.9541C13.5999 20.9428 13.585 20.926 13.567 20.9209C12.4181 20.5937 11.2693 20.2654 10.1184 19.9448C10.0329 19.9208 9.92734 19.9331 9.83928 19.9571C9.25997 20.1158 8.6822 20.2807 8.10494 20.4477C7.53026 20.6141 6.95558 20.7856 6.3809 20.9546C6.3536 20.9546 6.32631 20.9546 6.2985 20.9546Z" fill="#E2E2E2"/>
                                            </svg>
</>      ) : (<img src={`https://imgfolders.com/farehutz/${airline.split(',')[0]}.png`} checked={selectedAirline === `${airline}`} onClick={() => handleAirlineFilter(`${airline}`)}/*onClick={() => setSelectedMatrixAirline(airlinesName[airline])}*/ alt={airline} style={{width: '40px'}}/>)}

                                {/* {secondAirlineCode && (
                <div className="second-airline-toggle">
                  {/* Display the '+' icon here to toggle the second airline *
                  <span onClick={() => {handleAirlineFilter(airline);
                                        
                                        document.getElementsByClassName('.fare-col').style.display = 'none';
                  }} >+</span>
                </div>
              )}
              {airline === selectedAirline && secondAirlineCode && (
                <div className="second-airline-logo">
                  {/* Display the second airline's logo here *
                  <img
                    src={`https://imgfolders.com/farehutz${secondAirlineCode}.png`}
                    alt={secondAirlineCode}
                    style={{ width: '55px' }}
                  />
                </div>
              )} */}
              {secondAirlineCode ? (
									<span class="">{airlinesName[airline.split(',')[0]]} {/*& {airlinesName[secondAirlineCode]}*/}</span>
                  ):(
                    <span class="">{airlinesName[airline.split(',')[0]]}</span>
                  )}</div>
								<div class="amont-col">
                                    {flightResult.find(flight => flight.airline === airline)?.outBound[0].layOverTime === 0 ? (                                
									<span class="hover-col" onClick={() => setSelectedMatrixPriceRange({ min: flightResult.find(flight => flight.airline === airline)?.fare.grandOrgTotal, max:flightResult.find(flight => flight.airline === airline)?.fare.grandOrgTotal})}>£{flightResult.find(flight => flight.airline === airline)?.fare.grandOrgTotal.toFixed(2)}</span>
                                    ): <span class="">-</span>}
                                </div>
								<div class="amont-col">
                                {flightResult.find(flight => flight.airline === airline)?.outBound[0].layOverTime > 0 ? (                                
									<span class="hover-col" onClick={() => setSelectedMatrixPriceRange({ min: flightResult.find(flight => flight.airline === airline)?.fare.grandOrgTotal, max: flightResult.find(flight => flight.airline === airline)?.fare.grandOrgTotal })}>£{flightResult.find(flight => flight.airline === airline)?.fare.grandOrgTotal.toFixed(2)}</span>
                                    ): <span class="">-</span>}
                                </div>
								<div class="amont-col">                                
                                {flightResult.find(flight => flight.airline === airline)?.outBound[1]?.layOverTime > 0 ? (                                
									<span class="hover-col" onClick={() => setSelectedMatrixPriceRange({ min: flightResult.find(flight => flight.airline === airline)?.fare.grandOrgTotal, max:flightResult.find(flight => flight.airline === airline)?.fare.grandOrgTotal })}>£{flightResult.find(flight => flight.airline === airline)?.fare.grandOrgTotal.toFixed(2)}</span>
                                    ): <span class="">-</span>}
                                </div>
                                
                            	</div>
							    
                                </div>
                                
                      )
                                })}
                        {/* {displayedAirlines.map((airlinesString) => {
  const airlineArray = airlinesString.split(','); // Split mixed airlines

  return (
    <div className="matrix-table-columns" key={airlinesString}>
      {airlineArray.map((airline) => (
        <div className="mt-item" key={airline}>
          <div className="fare-col">
            <img
              src={`https://imgfolders.com/farehutz${airline}.png`}
              alt={airline}
              style={{ width: '55px' }}
            />
            <span>{airlinesName[airline]}</span>
          </div>
          <div className="amont-col">
            {flightResult.find((flight) => flight.airline === airline)?.outBound[0].layOverTime === 0 ? (
              <span className="hover-col" onClick={() => setSelectedMatrixPriceRange({ min: flightResult.find((flight) => flight.airline === airline)?.fare.grandOrgTotal, max: flightResult.find((flight) => flight.airline === airline)?.fare.grandOrgTotal })}>
                ${flightResult.find((flight) => flight.airline === airline)?.fare.grandOrgTotal}
              </span>
            ) : (
              <span className="">-</span>
            )}
          </div>
          <div className="amont-col">
            {flightResult.find((flight) => flight.airline === airline)?.outBound[0].layOverTime > 0 ? (
              <span className="hover-col" onClick={() => setSelectedMatrixPriceRange({ min: flightResult.find((flight) => flight.airline === airline)?.fare.grandOrgTotal, max: flightResult.find((flight) => flight.airline === airline)?.fare.grandOrgTotal })}>
                ${flightResult.find((flight) => flight.airline === airline)?.fare.grandOrgTotal}
              </span>
            ) : (
              <span className="">-</span>
            )}
          </div>
          <div className="amont-col">
            {flightResult.find((flight) => flight.airline === airline)?.outBound[1]?.layOverTime > 0 ? (
              <span className="hover-col" onClick={() => setSelectedMatrixPriceRange({ min: flightResult.find((flight) => flight.airline === airline)?.fare.grandOrgTotal, max: flightResult.find((flight) => flight.airline === airline)?.fare.grandOrgTotal })}>
                ${flightResult.find((flight) => flight.airline === airline)?.fare.grandOrgTotal}
              </span>
            ) : (
              <span className="">-</span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
})} */}
                    </div>
                </div>					
					</div>
				  </div>
				  <div class="row hidden-xs">
				  <div class="col-md-12">
					 <div class="hidden-xs">
		                 <p><strong>Note</strong>: <sup>*</sup> All the fares displayed are for {formData.tripType === "2" ? ("Round Trip") : ("One Way")}and are in GBP, inclusive of base fare, taxes and service fees. Additional  <a href="/baggage-fees" class="text-link-blue" target="_blank">baggage fees</a>  may apply as per the airline(s) policies. Some of the flights results shown could either be for other dates or nearby airport(s).</p>
	                 </div>
				  </div>
				  </div>
                  				  
				  <div class="row">					
					<div class="col-md-12">
						{/* <!--next-section-->	 */}
            
                        {filteredData.map((flight, index) => (
                          <>
                          {flight.noDataAvailable ? (
                            <div className='no-rel-cls'>
                              <img src={NoResult} alt='' style={{width: '100%', height: 'auto'}}/>
                            </div>
                            
                          ) : (
                          <>
                          {parseInt(formData.cabinType) !== flight.outBound[0].cabinClass ? (
                            <div class="alert alert-primary" role="alert">
                            The Required {parseInt(formData.cabinType) === 1 ? 'Economy' : parseInt(formData.cabinType) === 2 ? 'Bussiness Class' : parseInt(formData.cabinType) === 3 ? 'Premium Economy' : parseInt(formData.cabinType) === 4 ? 'First' : 'Unknown'} is not available. Here Are some better options!
                          </div>
                            
                            
                          ): null}
						<div class="result-container" key={index}>
              
              {flight.outBound[0]?.depDate !== flight.outBound[0].depDate ? (
              <div class="top-saver-deal">
							This is an Alternate Date Option
							</div>
              ): null}
					    <div class="result-blocks">
							<div class="flight-info">                        
                            <div class="flight-info-row">
                              <div class= "mobile-respons">
                                <div class="flight-info-airline">
                                    <img src={`https://imgfolders.com/farehutz/${flight.outBound[0].airlineName}.png`} alt={flight.outBound[0].airlineName}/> 
									
									<div class="flight-info-daydate">
									<p class="airlineName">{airlinesName[flight.outBound[0].airlineName]}</p>
									<p class="flightNumer">Flight No: {flight.outBound[0].flightNo} | EQP-{flight.outBound[0].equipmentType}</p>
                  {flight.outBound[0].orgAirline !== flight.outBound[0].opratingAirline ? (
                                        <div class="flightno">Operated by {airlinesName[flight.outBound[0].opratingAirline]} </div>
                                        ) : null}
                                    </div>
                                </div>   
                                </div>   
                                <div class="mobile-respons-next">                          
                                <div class="flight-info-fromtodura">
                                    <div class="flight-info-from">
                                        <div class="from-time">{formatDateAndTime(flight.outBound[0]?.depDate).formattedTimeString}</div>
                                        <div class="from-city" title="London">{flight.outBound[0]?.fromAirport}</div>
										{flight.outBound[0]?.depDate !== flight.outBound[0].depDate ? (<div class="from-date"><span class="bgcolr">{formatDateAndTime(flight.outBound[0]?.depDate).formattedDateString}</span></div>): <div class="from-date">{formatDateAndTime(flight.outBound[0]?.depDate).formattedDateString}</div>}
                                    </div>
                                    <div class="flight-info-durastop">
                                        <div class="flight-duration">{`${convertMinutesToHoursAndMinutes(flight.outBound[0].estimateTime)}`}</div>
                                        <div class="flight-stop-graph"> 
                                        {flight.outBound[0].layOverTime > 0 && (                                           
											<div class="stop-dot" ng-if="outLength > 1">
                                                <div class="stop-tool">
                                                    <div class="stoplay-head">
                                                       <span class="ng-binding">{`Layover: ${convertMinutesToHoursAndMinutes(flight.outBound[0].layOverTime)}, ${flight.outBound[0].toAirport}`} {" "}</span>
                                                        {/* <span>Layover Time</span> */}
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        )}
											
                                        </div>
                                        
                                        {flight.outBound[0] && flight.outBound[0].layOverTime > 0 ? (
    <div className="flight-stop">1 Stops</div>
  ) : flight.outBound[1] && flight.outBound[1].layOverTime > 0 ? (
    <div className="flight-stop">2 Stop</div>
  ) : null}
                                    </div>
									
                                    <div class="flight-info-to">                                      
                                        <div class="to-time">{flight.outBound[2]?.reachDate ? ( formatDateAndTime(flight.outBound[2]?.reachDate)?.formattedTimeString || 'No Time To Display' ) : flight.outBound[1]?.reachDate ? ( formatDateAndTime(flight.outBound[1]?.reachDate)?.formattedTimeString || 'No Time To Display' ) :flight.outBound[0]?.reachDate ? ( formatDateAndTime(flight.outBound[0]?.reachDate)?.formattedTimeString || 'No Time To Display' ) : 'No reach date available'}</div>
                                        <div class="to-city" title="San Francisco">{flight.outBound[2]?.toAirport ? flight.outBound[2].toAirport : flight.outBound[1]?.toAirport ? flight.outBound[1].toAirport : flight.outBound[0].toAirport}</div>
										<div class="to-date">{flight.outBound[2]?.reachDate ? ( formatDateAndTime(flight.outBound[2]?.reachDate)?.formattedDateString || 'No Date To Display' ) : flight.outBound[1]?.reachDate ? ( formatDateAndTime(flight.outBound[1]?.reachDate)?.formattedDateString || 'No Date To Display' ) : flight.outBound[0]?.reachDate ? ( formatDateAndTime(flight.outBound[0]?.reachDate)?.formattedDateString || 'No Date To Display' ) : 'No Date To Display'}</div>
                                    </div>
                                </div>
                                </div>
                            </div>	

							<div class="vertical-line"></div>
              {flight.inBound && flight.inBound[0] ? (
							<div class="flight-info-row">
                <div class= "mobile-respons">
                                <div class="flight-info-airline">
                                    <img src={`https://imgfolders.com/farehutz/${flight.inBound[0].airlineName}.png`} alt={flight.outBound[0].airlineName}/> 
									
									<div class="flight-info-daydate">
									<p class="airlineName">{airlinesName[flight.inBound[0].airlineName]}</p>
									<p class="flightNumer">Flight No: {flight.inBound[0].flightNo} | EQP-{flight.inBound[0].equipmentType}</p>
                  {flight.inBound[0].orgAirline !== flight.inBound[0].opratingAirline ? (
                                        <div class="flightno">Operated by {airlinesName[flight.inBound[0].opratingAirline]} </div>
                                        ) : null}
                                    </div>
                                </div>        
                                </div> 
                                <div class="mobile-respons-next">                       
                                <div class="flight-info-fromtodura">
                                    <div class="flight-info-from">
                                        <div class="from-time">{formatDateAndTime(flight.inBound[0].depDate).formattedTimeString}</div>
                                        <div class="from-city" title="London">{flight.inBound[0]?.fromAirport}</div>
										<div class="from-date">{formatDateAndTime(flight.inBound[0].depDate).formattedDateString}</div>
                                    </div>
                                    <div class="flight-info-durastop">
                                        <div class="flight-duration">{`${convertMinutesToHoursAndMinutes(flight.inBound[0].estimateTime)}`} </div>
                                        <div class="flight-stop-graph">
                                        {flight.inBound[0].layOverTime > 0 && (
                                            <div class="stop-dot" ng-if="outLength > 1">
                                                <div class="stop-tool">
                                                    <div class="stoplay-head">
                                                       <span class="ng-binding">{`Layover: ${convertMinutesToHoursAndMinutes(flight.inBound[0].layOverTime)}, ${flight.inBound[0].toAirport}`} {" "}</span>
                                                        {/* <span>Layover Time</span> */}
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        )}	
                                        </div>
                                        {flight.inBound[0] && flight.inBound[0].layOverTime > 0 ? (
    <div className="flight-stop">1 Stops</div>
  ) : flight.inBound[1] && flight.inBound[1].layOverTime > 0 ? (
    <div className="flight-stop">2 Stop</div>
  ) : null}									
                                    </div>
									
                                    <div class="flight-info-to">                                      
                                        <div class="to-time">{flight.inBound[2]?.reachDate ? ( formatDateAndTime(flight.inBound[2]?.reachDate)?.formattedTimeString || 'No Time To Display' ) : flight.inBound[1]?.reachDate ? ( formatDateAndTime(flight.inBound[1]?.reachDate)?.formattedTimeString || 'No Time To Display' ) :flight.inBound[0]?.reachDate ? ( formatDateAndTime(flight.inBound[0]?.reachDate)?.formattedTimeString || 'No Time To Display' ) : 'No reach date available'}</div>
                                        <div class="to-city" title="San Francisco">{flight.inBound[2]?.toAirport ? flight.inBound[2].toAirport : flight.inBound[1]?.toAirport ? flight.inBound[1].toAirport : flight.inBound[0].toAirport}</div>
										<div class="to-date">{flight.inBound[2]?.reachDate ? ( formatDateAndTime(flight.inBound[2]?.reachDate)?.formattedDateString || 'No Date To Display' ) : flight.inBound[1]?.reachDate ? ( formatDateAndTime(flight.inBound[1]?.reachDate)?.formattedDateString || 'No Date To Display' ) : flight.inBound[0]?.reachDate ? ( formatDateAndTime(flight.inBound[0]?.reachDate)?.formattedDateString || 'No Date To Display' ) : 'No Date To Display'}</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                      ):null}     
                        </div>
						<div class="flight-price-select">
            <div class="flight-ppp" key={flight.resultId}>
                <div 
                  class="baggage-dot"  
                  onMouseOver={() => handleMouseOver(flight.resultId)} 
                  onMouseOut={handleMouseOut}
                >
                  <img src={carryone} width="30" height="30" alt=""/> 
                  <img src={bag} width="20" height="40" alt=""/>
                </div>								
                
            </div>
							<div class="flight-ppp">{flight.cabinClass === 1 ? 'Economy' : flight.cabinClass === 2 ? 'Premium Economy' : flight.cabinClass === 3 ? 'Business' : flight.cabinClass === 4 ? 'First' : ''}</div>
							<div class="flight-price">£{(flight.fare.grandTotal / totalPassengers).toFixed(2)}</div>							
              {/* <div class="flight-price">${flight.fare.adultFare.toFixed(2)}</div> */}
              <div class="flight-ppp-price">Price per Person<br/>
                 (incl. taxes & fees)</div>
					        <button type="button"  onClick={() => handleFlightSelect(flight)}>SELECT FLIGHT</button>
                  {tooltipVisible && hoveredResultId === flight.resultId && (
  <div class="container2 tooltiptest">
    {responseData?.baggageInformation.map(bagInfo => {
      if (flight.bagOptionRef[0] === bagInfo.id) {
        return (
          <React.Fragment key={bagInfo.id}>
            <i class="arrow right2"></i>
            <div class="carrybag">
              <div><b>Carry on Bag</b></div> 
              <div>{bagInfo.carryOnBag}</div>
            </div>
            <div class="carrybag">
              <div><b>Checked Bag</b></div>
              <div>{bagInfo.checkedBagF}</div>
              {/* <div>{bagInfo.checkedBagSecond}</div> */}
            </div>
          </React.Fragment>
        );
      } else {
        return null; // Return null if the condition doesn't match
      }
    })}
  </div>
)}

                        </div>
							
						{/* <script>
							$('.baggage-dot').mouseover(() => {
								$('.container2').css("display", "block")
							})
							$('.baggage-dot').mouseout(() => {
								$('.container2').css("display", "none")
							})
						</script> */}
							
							
							
						</div>
							
							
							
					<div class="flight-detail-button">                       
						 <div class="flight-detail-show wid-thbtn" key={flight.resultId}>
                                        <div data-toggle="collapse" style={{color: '#f38216', cursor: 'pointer'}} onClick={() => {toggleCollapse('resultIdToClose'); setOpenResultID(flight.resultId);}}>
                                            Flight Details <i class={`fa fa-angle-down ${flightDetailsVisible ? '' : 'rotate-reset'}`}></i>
                                        </div>
                                    </div>
                         {/* <div class="flight-detail-show wid-thbtnext">
                                        <div data-toggle="collapse" style={{color: '#f38216', cursor: 'pointer'}} onClick={() => {toggleFareFamily('resultIdToClose'); setOpenResultID(flight.resultId);}}>
                                            Show More Option <i class="fa fa-angle-down rotate-reset"></i>
                                        </div>
                                    </div> */}
						
						<div style={{clear: 'both', marginBottom: '5px'}}></div>
						{flightDetailsVisible && openResultID === flight.resultId && (
              <>
              <button class="btn btn-outline-dark btn-sm" style={{width: '100%', borderColor: 'black'}} onClick={toggleClose}>Close X</button>
						<div id="collapseOne" class="flight-detail-container">  
                        {/* <button class="btn btn-dark close-search-lg" style={{cursor: 'pointer', color: 'white'}} onClick={toggleClose}>Close X</button>                       */}
						<div class="flight-detail">
							<div class="flight-detail-head">
								<div class="head-depar">Departure</div>
								<div class="head-Baggage">
								{/*<strong>Baggage:</strong> 2pc | */}<strong>Cabin</strong> {flight.cabinClass === 1 ? 'Economy' : flight.cabinClass === 2 ? 'Premium Economy' : flight.cabinClass === 3 ? 'Business' : flight.cabinClass === 4 ? 'First' : ''}
								{/* <p><strong>Brand Name:</strong> Basic Economy</p> */}
								</div>
							</div>							
                            <div class="flight-detail-row">
                              <div className="mobile-respons">
                                <div class="flight-detail-airline">
                                    <img src={`https://imgfolders.com/farehutz/${flight.outBound[0].airlineName}.png`} alt={flight.outBound[0].airlineName}/>
                                    <div class="airline-name-fno">
                                        <div class="airlineName">{airlinesName[flight.outBound[0].airlineName]}</div>
                                        <div class="flightno">Flight No. {flight.outBound[0].flightNo} | EQP-{flight.outBound[0].equipmentType} </div>
                                        {flight.outBound[0].orgAirline !== flight.outBound[0].opratingAirline ? (
                                        <div class="flightno">Operated by {airlinesName[flight.outBound[0].opratingAirline]} </div>
                                        ) : null}
                                    </div>
                                </div>
                              </div>
                            <div className="mobile-respons-next">
                          <div class="flight-detail-from-to" style={{borderBottom: '1px solid #ddd'}}>
									
                          <div class="flight-detail-from" style={{width: '160px'}}>
                         <div class="fdfrom-city"><strong>{flight.outBound[0].fromAirport} </strong> - {flight.outBound[0]?.fromAirport && airport.find(a => a.airportCode === flight.outBound[0]?.fromAirport)?.airportName}</div>
                         <div class="fdfrom-time"><strong>{formatDateAndTime(flight.outBound[0]?.depDate).formattedTimeString}</strong> {formatDateAndTime(flight.outBound[0].depDate).formattedDateString}</div>
                        </div>
									
                                    <div class="flight-ddinfo-durastop">
                                        <div class="flight-duration">{`${convertMinutesToHoursAndMinutes(flight.outBound[0].eft)}`} </div>
                                        <div class="flight-Detailsstop-graph"></div>
                                        {flight.outBound[0].layOverTime > 0 ? (
							            <div class="flight-stop">1<sup>st</sup> Layover ({convertMinutesToHoursAndMinutes(flight.outBound[0].layOverTime)}, {flight.outBound[0].toAirport})</div>
                                        ) : null}
                                        </div>
									
                                    <div class="flight-detail-to" style={{width: '160px'}}>
                                        <div class="fdfrom-city"><strong>{flight.outBound[0].toAirport}</strong> - {flight.outBound[0]?.toAirport && airport.find(a => a.airportCode === flight.outBound[0]?.toAirport)?.airportName}</div>
                                        <div class="fdfrom-time"><strong>{formatDateAndTime(flight.outBound[0].reachDate).formattedTimeString}</strong>{ formatDateAndTime(flight.outBound[0]?.depDate).formattedDateString !== formatDateAndTime(flight.outBound[0]?.reachDate).formattedDateString ? <sup>+1</sup> : null } {formatDateAndTime(flight.outBound[0].reachDate).formattedDateString}</div>
                                        {
                                  formatDateAndTime(flight.outBound[0]?.depDate).formattedDateString !==
                                  formatDateAndTime(flight.outBound[0]?.reachDate).formattedDateString
                                    
                                    ?
										<p class="arrivnext">Arrivel next day</p>
                    : null }
                                    </div>
									
									
                                </div>
                                </div>
                                <div class="flight-detail-cabin">
                                    
                                </div>
                            </div>
                            {flight.outBound[0]?.layOverTime > 0 && (
							<div class="flight-detail-row">
                <div className="mobile-respons">
                                <div class="flight-detail-airline">
                                    <img src={`https://imgfolders.com/farehutz/${flight.outBound[1]?.airlineName}.png`} alt={flight.outBound[1]?.airlineName}/>
                                    <div class="airline-name-fno">
                                        <div class="airlineName">{airlinesName[flight.outBound[1]?.airlineName]}</div>
                                        <div class="flightno">Flight No. {flight.outBound[1]?.flightNo} | EQP-{flight.outBound[1]?.equipmentType} </div>
                                        {flight.outBound[1].orgAirline !== flight.outBound[1].opratingAirline ? (
                                        <div class="flightno">Operated by {airlinesName[flight.outBound[1].opratingAirline]} </div>
                                        ) : null}
										
                                    </div>
                                </div>
								</div>
                <div className="mobile-respons-next">
                          <div class="flight-detail-from-to" style={{borderBottom: '1px solid #ddd'}}>
									
                          <div class="flight-detail-from" style={{ width: '160px'}}>
                         <div class="fdfrom-city"><strong>{flight.outBound[1]?.fromAirport}</strong> - {flight.outBound[1]?.fromAirport && airport.find(a => a.airportCode === flight.outBound[1]?.fromAirport)?.airportName}</div>
                         <div class="fdfrom-time"><strong>{formatDateAndTime(flight.outBound[1]?.depDate).formattedTimeString}</strong> {formatDateAndTime(flight.outBound[1]?.depDate).formattedDateString}</div>
                         {
  flight.outBound[1]?.fromAirport &&
  airport.find(a => a.airportCode === flight.outBound[1]?.fromAirport)?.airportName !==
    (flight.outBound[0]?.toAirport &&
      airport.find(a => a.airportCode === flight.outBound[0]?.toAirport)?.airportName) && (
        <p class="arrivnext">Airport Changes</p>
  )
}
							  
                        </div>
									
                                    <div class="flight-ddinfo-durastop">
                                        <div class="flight-duration">{`${convertMinutesToHoursAndMinutes(flight.outBound[1]?.eft)}`} </div>
                                        <div class="flight-Detailsstop-graph"></div>
                                        {flight.outBound[1].layOverTime > 0 ? (
							            <div class="flight-stop">1<sup>st</sup> Layover ({convertMinutesToHoursAndMinutes(flight.outBound[1]?.layOverTime)}, {flight.outBound[1]?.toAirport})</div>
                                        ) : null}
                                        </div>
									
                                    <div class="flight-detail-to" style={{width: '160px'}}>
                                        <div class="fdfrom-city"><strong>{flight.outBound[1]?.toAirport}</strong> - {flight.outBound[1]?.toAirport && airport.find(a => a.airportCode === flight.outBound[1]?.toAirport)?.airportName}</div>
                                        <div class="fdfrom-time"><strong>{formatDateAndTime(flight.outBound[1]?.reachDate).formattedTimeString}</strong>{ formatDateAndTime(flight.outBound[1]?.depDate).formattedDateString !== formatDateAndTime(flight.outBound[1]?.reachDate).formattedDateString ? <sup>+1</sup> : null } {formatDateAndTime(flight.outBound[1]?.reachDate).formattedDateString}</div>
                                        {
                                  formatDateAndTime(flight.outBound[1]?.depDate).formattedDateString !==
                                  formatDateAndTime(flight.outBound[1]?.reachDate).formattedDateString
                                    
                                    ?
										<p class="arrivnext">Arrivel next day</p>
                    : null }
                                    </div>
									
									
                                </div>
                                </div>
                                <div class="flight-detail-cabin ng-binding">
                                    
                                </div>
                            </div>
                            )}
                            {flight.outBound[1]?.layOverTime > 0 && (
							<div class="flight-detail-row">
                <div className="mobile-respons">
                                <div class="flight-detail-airline">
                                    <img src={`https://imgfolders.com/farehutz/${flight.outBound[2]?.airlineName}.png`} alt={flight.outBound[2]?.airlineName}/>
                                    <div class="airline-name-fno">
                                        <div class="airlineName">{airlinesName[flight.outBound[2]?.airlineName]}</div>
                                        <div class="flightno">Flight No. {flight.outBound[2]?.flightNo} | EQP-{flight.outBound[2]?.equipmentType} </div>
                                        {flight.outBound[2].orgAirline !== flight.outBound[2].opratingAirline ? (
                                        <div class="flightno">Operated by {airlinesName[flight.outBound[2].opratingAirline]} </div>
                                        ) : null}
										
                                    </div>
                                </div>
								</div>
                <div className='mobile-respons-next'>
                          <div class="flight-detail-from-to" style={{borderBottom: '1px solid #ddd'}}>
									
                          <div class="flight-detail-from" style={{ width: '160px'}}>
                         <div class="fdfrom-city"><strong>{flight.outBound[2]?.fromAirport}</strong> - {flight.outBound[2]?.fromAirport && airport.find(a => a.airportCode === flight.outBound[2]?.fromAirport)?.airportName}</div>
                         <div class="fdfrom-time"><strong>{formatDateAndTime(flight.outBound[2]?.depDate).formattedTimeString}</strong> {formatDateAndTime(flight.outBound[2]?.depDate).formattedDateString}</div>
                         {
  flight.outBound[2]?.fromAirport &&
  airport.find(a => a.airportCode === flight.outBound[2]?.fromAirport)?.airportName !==
    (flight.outBound[1]?.toAirport &&
      airport.find(a => a.airportCode === flight.outBound[1]?.toAirport)?.airportName) && (
        <p class="arrivnext">Airport Changes</p>
  )
}
                        </div>
									
                                    <div class="flight-ddinfo-durastop">
                                        <div class="flight-duration">{`${convertMinutesToHoursAndMinutes(flight.outBound[2]?.eft)}`} </div>
                                        <div class="flight-Detailsstop-graph"></div>
                                        {flight.outBound[2].layOverTime > 0 ? (
							            <div class="flight-stop">1<sup>st</sup> Layover (${convertMinutesToHoursAndMinutes(flight.outBound[2]?.layOverTime)}, ${flight.outBound[2]?.toAirport})</div>
                                        ) : null}
                                        </div>
									
                                    <div class="flight-detail-to" style={{width: '160px'}}>
                                        <div class="fdfrom-city"><strong>{flight.outBound[2]?.toAirport}</strong> - {flight.outBound[2]?.toAirport && airport.find(a => a.airportCode === flight.outBound[2]?.toAirport)?.airportName}</div>
                                        <div class="fdfrom-time"><strong>{formatDateAndTime(flight.outBound[2]?.reachDate).formattedTimeString}</strong>{ formatDateAndTime(flight.outBound[2]?.depDate).formattedDateString !== formatDateAndTime(flight.outBound[2]?.reachDate).formattedDateString ? <sup>+1</sup> : null } {formatDateAndTime(flight.outBound[2]?.reachDate).formattedDateString}</div>
                                        {
                                  formatDateAndTime(flight.outBound[2]?.depDate).formattedDateString !==
                                  formatDateAndTime(flight.outBound[2]?.reachDate).formattedDateString
                                    
                                    ?
										<p class="arrivnext">Arrivel next day</p>
                    : null }
                                    </div>
									
									
                                </div>
                                </div>
                                <div class="flight-detail-cabin ng-binding">
                                    
                                </div>
                            </div>
                            )}
							<div class="flight-detail-footer">								
								<div class="head-Baggage">
									<p>Total Trip Duration: {`${convertMinutesToHoursAndMinutes(flight.outBound[0].estimateTime)}`}</p>
								</div>
							</div>
							<div class="vertical-line-flights"></div>
                        </div>
							
                        {flight.inBound && flight.inBound[0] ? (    	
					<div class="flight-detail"> 
							
							<div class="flight-detail-head">
								<div class="head-depar">Return</div>
								<div class="head-Baggage">
								{/*<strong>Baggage:</strong> 2pc | */}<strong>Cabin</strong> {flight.cabinClass === 1 ? 'Economy' : flight.cabinClass === 2 ? 'Premium Economy' : flight.cabinClass === 3 ? 'Business' : flight.cabinClass === 4 ? 'First' : ''}
								{/* <p><strong>Brand Name:</strong> Basic Economy</p> */}
								</div>
							</div>
							
                            <div class="flight-detail-row">
                              <div className='mobile-respons'>
                                <div class="flight-detail-airline">
                                    <img src={`https://imgfolders.com/farehutz/${flight.inBound[0].airlineName}.png`} alt={flight.inBound[0].airlineName} />
                                    <div class="airline-name-fno">
                                        <div class="airlineName">{airlinesName[flight.inBound[0].airlineName]}</div>
                                        <div class="flightno">Flight No. {flight.inBound[0].flightNo} | EQP-{flight.inBound[0].equipmentType} </div>
                                        {flight.inBound[0].orgAirline !== flight.inBound[0].opratingAirline ? (
                                        <div class="flightno">Operated by {airlinesName[flight.inBound[0].opratingAirline]} </div>
                                        ) : null}
                                    </div>
                                </div>
                                </div>
                                <div className='mobile-respons-next'>
								
                          <div class="flight-detail-from-to" style={{borderBottom: '1px solid #ddd'}}>
									
                          <div class="flight-detail-from" style={{width: '160px'}}>
                         <div class="fdfrom-city"><strong>{flight.inBound[0].fromAirport}</strong> - {flight.inBound[0]?.fromAirport && airport.find(a => a.airportCode === flight.inBound[0]?.fromAirport)?.airportName}</div>
                         <div class="fdfrom-time"><strong>{formatDateAndTime(flight.inBound[0].depDate).formattedTimeString}</strong> {formatDateAndTime(flight.inBound[0]?.depDate).formattedDateString}</div>
							  
                        </div>
									
                                    <div class="flight-ddinfo-durastop">
                                        <div class="flight-duration">{`${convertMinutesToHoursAndMinutes(flight.inBound[0].eft)}`} </div>
                                        <div class="flight-Detailsstop-graph"></div>
                                        {flight.inBound[0].layOverTime > 0 ? (
							            <div class="flight-stop">1<sup>st</sup> Layover ({convertMinutesToHoursAndMinutes(flight.inBound[0]?.layOverTime)}, {flight.inBound[0]?.toAirport})</div>
                                        ): null}
                                        </div>
									
                                    <div class="flight-detail-to" style={{width: '160px'}}>
                                        <div class="fdfrom-city"><strong>{flight.inBound[0].toAirport}</strong> - {flight.inBound[0]?.toAirport && airport.find(a => a.airportCode === flight.inBound[0]?.toAirport)?.airportName}</div>
                                        <div class="fdfrom-time"><strong>{formatDateAndTime(flight.inBound[0].reachDate).formattedTimeString}</strong>{ formatDateAndTime(flight.inBound[0]?.depDate).formattedDateString !== formatDateAndTime(flight.inBound[0]?.reachDate).formattedDateString ? <div><sup>+1</sup></div> : null } {formatDateAndTime(flight.inBound[0].reachDate).formattedDateString}</div>
                                        {
                                  formatDateAndTime(flight.inBound[0]?.depDate).formattedDateString !==
                                  formatDateAndTime(flight.inBound[0]?.reachDate).formattedDateString
                                    
                                    ?
										<p class="arrivnext">Arrivel next day</p>
                    : null }
                                    </div>
									
									
                                </div>
                                </div>
                                <div class="flight-detail-cabin">                                    
                                </div>
                            </div>
                            {flight.inBound[0]?.layOverTime > 0 && (
							<div class="flight-detail-row">
                <div className='mobile-respons'>
                                <div class="flight-detail-airline">
                                    <img src={`https://imgfolders.com/farehutz/${flight.inBound[1]?.airlineName}.png`} alt={flight.inBound[1]?.airlineName}/>
                                    <div class="airline-name-fno">
                                        <div class="airlineName">{airlinesName[flight.inBound[1]?.airlineName]}</div>
                                        <div class="flightno">Flight No. {flight.inBound[1]?.flightNo} | EQP-{flight.inBound[1]?.equipmentType} </div>
                                        {flight.inBound[1].orgAirline !== flight.inBound[1].opratingAirline ? (
                                        <div class="flightno">Operated by {airlinesName[flight.inBound[1].opratingAirline]} </div>
                                        ) : null}
                                    </div>
                                </div>
								</div>
                <div className='mobile-respons-next'>
                          <div class="flight-detail-from-to" style={{borderBottom: '1px solid #ddd'}}>
									
                          <div class="flight-detail-from" style={{width: '160px'}}>
                         <div class="fdfrom-city"><strong>{flight.inBound[1]?.fromAirport}</strong> - {flight.inBound[1]?.fromAirport && airport.find(a => a.airportCode === flight.inBound[1]?.fromAirport)?.airportName}</div>
                         <div class="fdfrom-time"><strong>{formatDateAndTime(flight.inBound[1]?.depDate).formattedTimeString}</strong> {formatDateAndTime(flight.inBound[1]?.depDate).formattedDateString}</div>
                         {
  flight.outBound[1]?.fromAirport &&
  airport.find(a => a.airportCode === flight.outBound[1]?.fromAirport)?.airportName !==
    (flight.outBound[0]?.toAirport &&
      airport.find(a => a.airportCode === flight.outBound[0]?.toAirport)?.airportName) && (
        <p class="arrivnext">Airport Changes</p>
  )
}
                        </div>
									
                                    <div class="flight-ddinfo-durastop">
                                        <div class="flight-duration">{`${convertMinutesToHoursAndMinutes(flight.inBound[1]?.eft)}`} </div>
                                        <div class="flight-Detailsstop-graph"></div>
                                        {flight.inBound[1]?.layOverTime > 0 ? (
							            <div class="flight-stop">1<sup>st</sup> Layover ({convertMinutesToHoursAndMinutes(flight.inBound[1]?.layOverTime)}, {flight.inBound[1]?.toAirport})</div>
                                        ): null}
                                        </div>
									
                                    <div class="flight-detail-to" style={{width: '160px'}}>
                                        <div class="fdfrom-city"><strong>{flight.inBound[1]?.toAirport}</strong> - {flight.inBound[1]?.toAirport && airport.find(a => a.airportCode === flight.inBound[1]?.toAirport)?.airportName}</div>
                                        <div class="fdfrom-time"><strong>{formatDateAndTime(flight.inBound[1]?.reachDate).formattedTimeString}</strong>{ formatDateAndTime(flight.inBound[1]?.depDate).formattedDateString !== formatDateAndTime(flight.inBound[1]?.reachDate).formattedDateString ? <sup>+1</sup> : null } {formatDateAndTime(flight.inBound[1]?.reachDate).formattedDateString}</div>
                                        {
                                  formatDateAndTime(flight.inBound[1]?.depDate).formattedDateString !==
                                  formatDateAndTime(flight.inBound[1]?.reachDate).formattedDateString
                                    
                                    ?
										<p class="arrivnext">Arrivel next day</p>
                    : null }
                                    </div>
									
									
                                </div>
                                </div>
                                <div class="flight-detail-cabin">
                                    
                                </div>
                            </div>
                            )}
                            {flight.inBound[1]?.layOverTime > 0 && (
							<div class="flight-detail-row">
                <div className='mobile-respons'>
                                <div class="flight-detail-airline">
                                    <img src={`https://imgfolders.com/farehutz/${flight.inBound[2]?.airlineName}.png`} alt={flight.inBound[2]?.airlineName}/>
                                    <div class="airline-name-fno">
                                        <div class="airlineName">{airlinesName[flight.inBound[2]?.airlineName]}</div>
                                        <div class="flightno">Flight No. {flight.inBound[2]?.flightNo} | EQP-{flight.inBound[2]?.equipmentType} </div>
                                        {flight.inBound[2].orgAirline !== flight.inBound[2].opratingAirline ? (
                                        <div class="flightno">Operated by {airlinesName[flight.inBound[2].opratingAirline]} </div>
                                        ) : null}
                                    </div>
                                </div>
								</div>
                <div className='mobile-respons-next'>
                          <div class="flight-detail-from-to" style={{borderBottom: '1px solid #ddd'}}>
									
                          <div class="flight-detail-from" style={{width: '160px'}}>
                         <div class="fdfrom-city"><strong>{flight.inBound[2]?.fromAirport}</strong> - {flight.inBound[2]?.fromAirport && airport.find(a => a.airportCode === flight.inBound[2]?.fromAirport)?.airportName}</div>
                         <div class="fdfrom-time"><strong>{formatDateAndTime(flight.inBound[2]?.depDate).formattedTimeString}</strong> {formatDateAndTime(flight.inBound[2]?.depDate).formattedDateString}</div>
                         {
  flight.outBound[2]?.fromAirport &&
  airport.find(a => a.airportCode === flight.outBound[2]?.fromAirport)?.airportName !==
    (flight.outBound[1]?.toAirport &&
      airport.find(a => a.airportCode === flight.outBound[1]?.toAirport)?.airportName) && (
        <p class="arrivnext">Airport Changes</p>
  )
}
                        </div>
									
                                    <div class="flight-ddinfo-durastop">
                                        <div class="flight-duration">{`${convertMinutesToHoursAndMinutes(flight.inBound[2]?.eft)}`} </div>
                                        <div class="flight-Detailsstop-graph"></div>
                                        {flight.inBound[2]?.layOverTime > 0 ? (
							            <div class="flight-stop">1<sup>st</sup> Layover (${convertMinutesToHoursAndMinutes(flight.inBound[2]?.layOverTime)}, ${flight.inBound[2]?.toAirport})</div>
                                        ): null}
                                        </div>
									
                                    <div class="flight-detail-to" style={{width: '160px'}}>
                                        <div class="fdfrom-city"><strong>{flight.inBound[2]?.toAirport}</strong> - {flight.inBound[2]?.toAirport && airport.find(a => a.airportCode === flight.inBound[2]?.toAirport)?.airportName}</div>
                                        <div class="fdfrom-time"><strong>{formatDateAndTime(flight.inBound[2]?.reachDate).formattedTimeString}</strong> {formatDateAndTime(flight.inBound[2]?.reachDate).formattedDateString}</div>
                                        {
                                  formatDateAndTime(flight.inBound[2]?.depDate).formattedDateString !==
                                  formatDateAndTime(flight.inBound[2]?.reachDate).formattedDateString
                                    
                                    ?
										<p class="arrivnext">Arrivel next day</p>
                    : null }
                                    </div>
									
									
                                </div>
                                </div>
                                <div class="flight-detail-cabin">
                                    
                                </div>
                            </div>
                            )}
					    	{/* <div class="flight-detail-row">
                                <div class="flight-detail-airline">
                                    <img src={AA} alt='' />
                                    <div class="airline-name-fno">
                                        <div class="airlineName">American Airlines</div>
                                        <div class="flightno">Flight No. 2044 | EQP-542F </div>
                                    </div>
                                </div>
								
                          <div class="flight-detail-from-to" style={{borderBottom: '1px solid #ddd'}}>
									
                          <div class="flight-detail-from" style={{width: '160px'}}>
                         <div class="fdfrom-city"><strong>NYC</strong> - JKF International</div>
                         <div class="fdfrom-time"><strong>10:15pm</strong> Sun, july 23</div>
						
                        </div>
									
                                    <div class="flight-ddinfo-durastop">
                                        <div class="flight-duration">26h 59m </div>
                                        <div class="flight-Detailsstop-graph"></div>
							            <div class="flight-stop">1<sup>st</sup> Layover (20h 30m)</div>
                                        </div>
									
                                    <div class="flight-detail-to" style={{width: '160px'}}>
                                        <div class="fdfrom-city"><strong>LAX</strong> - Los Angeles</div>
                                        <div class="fdfrom-time"><strong>01:15pm</strong><sup>+1</sup> Mon, July 23</div>
										<p class="arrivnext">Arrivel next day</p>
                                    </div>
									
									
                                </div>
                                <div class="flight-detail-cabin ng-binding">
                                    
                                </div>
                            </div>
							
							*/}
							<div class="flight-detail-footer">								
								<div class="head-Baggage">
									<p>Total Trip Duration: {`${convertMinutesToHoursAndMinutes(flight.inBound[0].estimateTime)}`}</p>
								</div>
							</div> 
							
							
                        </div>	
                        ) : null}	
							
							
                    </div>
              </>
						)}
                        {/* {fareFamily && openResultID === flight.resultId && (
						<div id="collapseTwo" class="flight-detail-container">
						<div class="showmore-option">
                        <button class="btn btn-primary close-search-lg" style={{cursor: 'pointer'}} onClick={toggleClose}>Close X</button>
						<div class="row">
                        <div class="col-sm-3">
							<div class="show-cabin">
							<h5>Basic Economy</h5>                            
								
								<div class="show-cabininr"> 
								<div>Cabin</div>
								<div>Economy</div>
								</div>								
								<div class="show-cabininr"> 
								<div>Baggage</div>
								<div>2</div>
								</div>
								
								<div class="show-cabininr2"> 
								<div>Carry-on</div>
								<div>1</div>
								</div>
								
								<div class="show-cabininr2"> 
								<div>Cheacked bag</div>
								<div>1</div>
								</div>
								
								
								<div class="show-cabininr"> 
								<div>Price</div>
								<div>$750</div>
								</div>
								
								<div class="show-cabininr2"> 
								<div>Trip</div>
								<div>Round Trip</div>
								</div>
								
								<div class="show-cabininr2"> 
								<div>Traveler(s)</div>
								<div>3</div>
								</div>
								
								
								<button type="button">Select</button>
								</div>
                            
                        </div>
					    <div class="col-sm-3">
							<div class="show-cabin">
							<h5>Economy <span>(Fully Refundable)</span></h5>                            
								
								<div class="show-cabininr"> 
								<div>Cabin</div>
								<div class="fullyr">
									Economy<br/>
									<span style={{fontSize: '10px'}}>(Fully Refundable)</span>
									</div>
								</div>								
								<div class="show-cabininr"> 
								<div>Baggage</div>
								<div>2</div>
								</div>
								
								<div class="show-cabininr2"> 
								<div>Carry-on</div>
								<div>1</div>
								</div>
								
								<div class="show-cabininr2"> 
								<div>Cheacked bag</div>
								<div>1</div>
								</div>
								
								
								<div class="show-cabininr"> 
								<div>Price</div>
								<div>$750</div>
								</div>
								
								<div class="show-cabininr2"> 
								<div>Trip</div>
								<div>Round Trip</div>
								</div>
								
								<div class="show-cabininr2"> 
								<div>Traveler(s)</div>
								<div>3</div>
								</div>
								
								
								<button type="button">Select</button>
								</div>
                            
                        </div>
						<div class="col-sm-3">
							<div class="show-cabin">
							<h5>Economy Plus <span>(Extra Legroom)</span></h5>                            
								
								<div class="show-cabininr"> 
								<div>Cabin</div>
								<div>Economy</div>
								</div>								
								<div class="show-cabininr"> 
								<div>Baggage</div>
								<div>2</div>
								</div>
								
								<div class="show-cabininr2"> 
								<div>Carry-on</div>
								<div>1</div>
								</div>
								
								<div class="show-cabininr2"> 
								<div>Cheacked bag</div>
								<div>1</div>
								</div>
								
								
								<div class="show-cabininr"> 
								<div>Price</div>
								<div>$750</div>
								</div>
								
								<div class="show-cabininr2"> 
								<div>Trip</div>
								<div>Round Trip</div>
								</div>
								
								<div class="show-cabininr2"> 
								<div>Traveler(s)</div>
								<div>3</div>
								</div>
								
								
								<button type="button">Select</button>
								</div>
                            
                        </div>
					    <div class="col-sm-3">
							<div class="show-cabin">
							<h5>Business / Firs</h5>                            
								
								<div class="show-cabininr"> 
								<div>Cabin</div>
								<div>Business / Firs</div>
								</div>								
								<div class="show-cabininr"> 
								<div>Baggage</div>
								<div>2</div>
								</div>
								
								<div class="show-cabininr2"> 
								<div>Carry-on</div>
								<div>1</div>
								</div>
								
								<div class="show-cabininr2"> 
								<div>Cheacked bag</div>
								<div>1</div>
								</div>
								
								
								<div class="show-cabininr"> 
								<div>Price</div>
								<div>$750</div>
								</div>
								
								<div class="show-cabininr2"> 
								<div>Trip</div>
								<div>Round Trip</div>
								</div>
								
								<div class="show-cabininr2"> 
								<div>Traveler(s)</div>
								<div>3</div>
								</div>
								
								
								<button type="button">Select</button>
								</div>
                            
                        </div>
                        </div>
							
							
						</div>
                       </div>
                       )} */}
						
                    </div>
							
					</div>
                        </>
                          )} 
                          </>
                        ))}
					   {/* <!--next-section close-here-->
						<!--next-section-->						 */}
				    	{/* <div class="result-container">
					<div class="result-blocks">Next Flight Details</div>
					<div class="flight-detail-button">
                        <div class="flight-detail-show">Flight Details <i class="fa fa-angle-down rotate-reset"></i></div>
                    </div>	
					</div>						  */}
						{/* <!--next-section close-here-->
						
						<!--next-section-->	 */}
            {/* {flightResult.outBound[0]?.depDate !== flightResult.outBound[0]?.depDate && ( */}
						 
          {/* )} */}
					   {/* <!--next-section close-here--> */}
						
						
						
						
					</div>
				  
				  </div>
               
				  </div>  
                  <div>
                  <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
    <ArrowBack
        onClick={() => handleChange(currentPage - 1)}
        style={{ cursor: 'pointer' }}
    />
    <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(event, page) => handleChange(page)} // Use the second argument 'page'
        showFirstButton
        showLastButton
        renderItem={(item) => (
            <PaginationItem
                component="div"
                {...item}
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '50%',
                    padding: '0.5rem',
                    cursor: 'pointer',
                }}
            />
        )}
    />
    <ArrowForward
        onClick={() => handleChange(currentPage + 1)}
        style={{ cursor: 'pointer' }}
    />
</Stack>
                  {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <ArrowBack onClick={() => handleChange( currentPage - 1)} style={{ cursor: 'pointer' }} />
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handleChange}
                      showFirstButton
                      showLastButton
                      renderItem={(item) => (
                        <PaginationItem
                          component="div"
                          {...item}
                          style={{ border: '1px solid #ccc', borderRadius: '50%', padding: '0.5rem', cursor: 'pointer' }}
                        />
                      )}
                    />
                    <ArrowForward onClick={() => handleChange( currentPage + 1)} style={{ cursor: 'pointer' }} />
                  </Stack> */}
        {/* <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        <button
          disabled={filteredData.length < itemsPerPage}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button> */}
      </div>
		      </div>
				
			</div> 
			
			</div>
        {/* <!--container-content-->

        <!--container-footer--> */}
        
        {/* <!--container-footer-->
        
		
        <!--Header_Menu_&_togel--> */}
        <script src="resources/js/jquery.meanmenu.js"></script>
        <script src="resources/js/custom.js"></script>
        {/* <!--deals--> */}
        <script src="resources/jc/wow.min.js"></script>
        <script src="resources/jc/main.js"></script>
	    <script src="resources/js/slickmatrix.min.js"></script>
	

    </div>
     )}
    <Footer2 />
    </>
  )
}
